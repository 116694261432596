const Toast = ({message, onRefuse, onAccepte, closeToast }) => {
  const handleRefuse = () => {
    onRefuse();
    closeToast();
  };
  const handleAccept = () => {
    onAccepte();
    closeToast();
  };
  return (
    <div>
      <h3>
        <div className="toast">
          <span>{message}</span>
          <button onClick={handleRefuse}>Refuse</button>
          <button onClick={handleAccept}>Accept</button>
        </div>
      </h3>
    </div>
  );
};

export default Toast;