import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import BgPanel from '../components/BgPanel';
import Navbar from '../components/Navbar';
import BtnIcon from '../components/BtnIcon';
import ArrowBackSvg from '../assets/icons/arrow-back.svg';
import Input from '../components/Input';
import KeyboardSvg from '../assets/icons/keyboard.svg';
import BtnRegular from '../components/BtnRegular';
import LogotypeSvg from '../assets/icons/logotype-prejoin.svg';


import RegularVolumeSvg from '../assets/icons/regular-volume.svg';
import MediumVolumeSvg from '../assets/icons/medium-volume.svg';
import NoVolumeSvg from '../assets/icons/no-volume.svg';
import HighVolumeSvg from '../assets/icons/high-volume.svg';

import CheckGreenSvg from '../assets/icons/check-green.svg';
import CrossRedSvg from '../assets/icons/cross-red.svg';
import StaggeredAnimation from '../components/StaggeredAnimation.js';

import { config } from '../data/prejoinConfig';

import PrejoinCamera from '../components/PrejoinCamera';
import PreviewSettingsModal from '../components/PreviewSettingsModal';
import InputCheckbox from '../components/InputCheckbox';

import useAuthContext from '../hooks/useAuth';
import PrejoinContainer from '../components/Prejoin/PrejoinContainer';
import PrejoinWithRequest from '../components/Prejoin/withRequest'
import PrejoinWithPassword from '../components/Prejoin/withPassword';
import PrejoinWithIdentification from '../components/Prejoin/withIdentification';
import PrejoinWithWaitHost from '../components/Prejoin/withWaitHost';
import api from '../api';

import { useSelector, useDispatch } from 'react-redux';
import { getMeetName , getMeetUUID , setMeetName, setMeetUUID,setMeetlist, getMeetList } from '../redux/meetSlice';
import * as mlHelpers from '../helpers/meetlist';
import axios from 'axios';

import * as utils from '../utils';
import PrejoinWithOnlyJoinButton from '../components/Prejoin/withOnlyJoinButton';
import { defaultConfig } from './Home';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '../components/Toast';


export const RequestStatus = {
    init: 'initialized',
    waiting: 'waiting',
    accepted: 'accepted',
    reject: 'reject',
}


const Preview = () => {

    const { meetUUID: uuidParams } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	// const meetName = useSelector(getMeetName);
	// const meetUUID = useSelector(getMeetUUID);

   
    const defaultMeet = {
        name:'', uuid:'', users:{}, isStart:false
    }
    
    const { hasAllTokens, userEmail, userName, socket, userUUID } = useAuthContext();
    const [{ name, uuid, users, isStart, isHostJoin }, setCurrentMeet] = useState(defaultMeet);
    const [{ needPassword, password, needWaitHost, needRequest,
        host: { userEmail: hostEmail, userUUID: hostUUID  },
        animator: { userEmail: animatorEmail, userUUID: animatorUUID  },
        animator, defaultParticipant }, setMeetConfig] = useState(defaultConfig);

    const [isHost, setIsHost] = useState(false);
	const [isJoined, setIsJoined] = useState(false);
    const [hasCorrectPassword, setHasCorrectPassword] = useState(false);
    const [requestStatus, setRequestStatus] = useState(RequestStatus.init);
    const [nextRequestInterval, setNextRequestInterval] = useState(0);
    const [sendRequestTimesReste, setSendRequestTimesReste] = useState(3);

    const [joinAutomated, setJoinAutomated] = useState(false);

    const handleReceiveRequest = ({ meetUUID,senderUserEmail, senderUserName, senderSessionUUID, senderSocketId }) => {
        toast(
            <Toast
                message={`${senderUserName} want to join current meet`}
                onAccepte={() => socket.emit('host accept', { meetUUID, senderSessionUUID, senderSocketId, senderUserEmail, senderUserName })}
                onRefuse={() => socket.emit('host reject', { meetUUID, senderSessionUUID, senderSocketId, senderUserEmail, senderUserName })}
            />);
    }
    
    const handleMeetJoin = ({ meet }) => {
        const { uuid: updateMeet } = meet;
        if (updateMeet === uuidParams) {
            setCurrentMeet(meet);
        }
    }

    const handleMeetJoinSelf = () => {
        setIsJoined(true);
    }

    const handleReceveAcceptResponse = ({ meetUUID: requestMeetUUID }) => {
        if (requestMeetUUID === uuidParams) {
            setRequestStatus(RequestStatus.accepted);
        }
    }

    const handleReceveRejectResponse = ({ meetUUID: requestMeetUUID, interval, timesReste }) => {
        if (requestMeetUUID === uuidParams) {
            setRequestStatus(RequestStatus.reject);
            setNextRequestInterval(interval);
            setSendRequestTimesReste(timesReste);
        }
    }

    
    const handleHostJoin = ({ meetUUID: hostJoinMeetUUID }) => {
        if (hostJoinMeetUUID === uuidParams && joinAutomated && !isHost) {
            socket.emit('join', { uuid:uuidParams, isRefresh:false });
        }
    }

    const onJoinMeet = (e) => {
        e.stopPropagation();
        e.preventDefault();

        socket.emit('join', { uuid, isRefresh:false });
    }

    
    const handleBackToHome = () => {
        if (isJoined) {
            socket.emit('leave', { uuid: uuidParams });
        }
        navigate('/')
    }

    const handleMeetFromServeur = ({ meet }) => {
		setCurrentMeet(meet);
    }
	
    useEffect(() => {
        if (isHost && isHostJoin) {
            setIsJoined(true);
        }
    }, [isHostJoin, isHost]);
    
    useEffect(() => {
        if (hostEmail && userEmail && hostEmail === userEmail) {
            setIsHost(true);
        }
    },[hostEmail, userEmail])

    useEffect(() => {
        if (joinAutomated && isHostJoin && needWaitHost && userName ) {
            socket.emit('join', { uuid, isRefresh: false });
        }
    }, [joinAutomated, isHostJoin, needWaitHost, userName]);
    
    useEffect(() => {
        if (sendRequestTimesReste === 0) {
            console.log('3 failed, back to home')
            navigate('/')
        }
    }, [sendRequestTimesReste]);

    useEffect(() => {

        socket.on('join', handleMeetJoin);
        socket.on('join self', handleMeetJoinSelf);
		socket.on('receive request', handleReceiveRequest);
        socket.on('host accept', handleReceveAcceptResponse);
        socket.on('host reject', handleReceveRejectResponse);
        socket.on('host join', handleHostJoin);
        
		socket.on('leave', handleMeetFromServeur);
		socket.on('leave self', handleBackToHome);
		return () => {
			socket.off('join', handleMeetJoin);
            socket.off('join self', handleMeetJoinSelf);
			socket.off('receive request', handleReceiveRequest);
		    socket.off('host accept', handleReceveAcceptResponse);
			socket.off('host reject', handleReceveRejectResponse);
            socket.off('host join', handleHostJoin);
            
            socket.off('leave', handleMeetFromServeur);
            socket.off('leave self', handleBackToHome);
		};
    }, [socket]);
    
    useEffect(() => {

        const prejoinAnim = new StaggeredAnimation(
			'.prejoin__body__container > div',
			100,
			'negY',
			1.25,
			'ease',
			250
		);


		prejoinAnim.animate();

        // handleAnim();

        
        // fetch(`https://api-eu.dev137.scw.ringover.net/v4/meet/public/rooms/${localStorage.getItem('meet_name_auth')}`, {
		// 	method: 'GET',
		// 	headers: {
		// 		Accept: 'application/json',
		// 		'Content-Type': 'application/json',
		// 		'Authorization': 'Bearer ' + localStorage.getItem('id_token')
		// 	},
		// }).then((response) => {
		// 	response.json().then((responseJs) => {
        //         console.log(responseJs);
		// 	});
		// }).catch((error) => console.error(error));


        if (socket) {
			socket.open();
		}

		async function loadMeet() {
			const response = await api.get('/meet', {
				params: { user:userUUID, uuid: uuidParams  },
			});
			if (!response.data) {
				console.log('has no meet found back to home');
				navigate('/');
				return
			}
			const { meet } = response.data;
            console.log('meetname from serveur', meet);
            if (meet && Object.keys(meet).length !== 0) {
                setCurrentMeet(meet);
                if (meet.config && Object.keys(meet?.config).length !== 0) {
                    setMeetConfig(meet.config);
                }

				let isRefresh = false;
				const lastUrl = sessionStorage.getItem('lastURL');
				if (lastUrl === uuidParams) {
					const now = new Date().getTime();
					const leaveTime = parseInt(sessionStorage.getItem('lastTime'), 10);
					isRefresh = now - leaveTime <= 5000;
					if (isRefresh) {
						console.log('is refresh');
					}
                }
                if (isRefresh) {
                    socket.emit('join', { uuid:uuidParams, isRefresh });
                }
                
			} else {
				navigate('/');
			}
		}

		loadMeet();
		return (() => {
            console.log('--------preview desctroy');
            // only host leave, delete meet???
            if (isJoined) {
                socket.emit('leave', { uuid: uuidParams });
            }
		})
    }, []);
    
    const count = Object.keys(users).length;
    const countMessage = count > 0 ? `${count} participants are already in the meeting room` : 'No one is here yet'; 

    const greeting = <>
        <h2>welcome {userName && <span>{userName} !</span>}</h2>
        <p>{name}</p>
        <p className='legend'>{countMessage}</p>
    </>

    return (
		<div className='prejoin'>
			<div className='prejoin__header'>
				<BgPanel />
			</div>
			<div className='prejoin__body'>
                <Navbar />
                
                <div className='greetings-box__mobile'>
                    {greeting}
                </div>

				<div className='prejoin__body__container'>
                    <div className='prejoin__left-panel scroll-bar'>
                        <div className='prejoin__left-panel__header'>
                            <div className='btn-frame'>
                                <BtnIcon
                                    content='Back Home'
                                    icon={ArrowBackSvg}
                                    event={handleBackToHome}
                                />
                            </div>
                        </div>

                        <div className='prejoin__left-panel__body'>
                            <div className='greetings-box'>
                                {greeting}
                            </div>
                            
                            <PrejoinContainer
                                uuid={uuid}
                                hostEmail={hostEmail}
                                hostUUID={hostUUID}
                                isHost={isHost}
                                isHostJoin={isHostJoin}
                                isJoined={isJoined}
                                onJoinMeet={onJoinMeet}
                                needPassword={needPassword}
                                password={password}
                                hasCorrectPassword={hasCorrectPassword}
                                setHasCorrectPassword={setHasCorrectPassword}
                                needRequest={needRequest}
                                needWaitHost={needWaitHost}
                                requestStatus={requestStatus}
                                setRequestStatus={setRequestStatus}
                                joinAutomated={joinAutomated}
                                setJoinAutomated={setJoinAutomated}
                                nextRequestInterval={nextRequestInterval}
                                setNextRequestInterval={setNextRequestInterval}
                                sendRequestTimesReste={sendRequestTimesReste}
                                setSendRequestTimesReste={setSendRequestTimesReste}
                            />
                        </div>
                    </div>
                    <PrejoinCamera/>
				</div>
			</div>
            <PreviewSettingsModal />
            <ToastContainer closeOnClick={false} autoClose={false}/>
		</div>
    );
};

export default Preview;