import { createSlice, createEntityAdapter} from '@reduxjs/toolkit';

import PortraitPeter from '../assets/pictures/portrait-peter.jpg';
import PortraitSarah from '../assets/pictures/portrait.jpg';
import PortraitMichael from '../assets/pictures/portrait-michael.jpg';
import PortraitSofia from '../assets/pictures/portrait-sofia.jpg';
import PortraitMaximus from '../assets/pictures/portrait-maximus.jpg';

const participantAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.id - b.id,
})

const list = [
    {
        id: 1,
        userName: 'koussai',
        initials: 'km',
        colorTag: '#36CDCF',
        portait: PortraitPeter,
        networkStatus: 'success',
        isFirstMessage: true,
        isExternal: true,
        isMuted: true,
        isPinned: false,
        isTalking: false,
        isSharing:false,
        durationTalk: 1300
    },
    {
        id: 2,
        userName: 'jing',
        initials: 'jx',
        colorTag: '#FFCF4F',
        portait: PortraitSarah,
        email: 'jing@ringover.com',
        networkStatus: 'warning',
        isRaisingHand: false,
        isFirstMessage: true,
        isExternal: true,
        isMuted: true,
        isPinned: false,
        isTalking: false,
        durationTalk: 800
    },
    {
        id: 3,
        userName: 'tarik',
        initials: 'tl',
        colorTag: '#72BBF1',
        portait: PortraitMaximus,
        email: 'tarik@ringover.com',
        networkStatus: 'danger',
        isFirstMessage: true,
        isRaisingHand: false,
        isExternal: false,
        isMuted: true,
        isPinned: false,
        isTalking: false,
        durationTalk: 625
    },
    {
        id: 4,
        userName: 'jimmy',
        initials: 'jk',
        colorTag: '#F05F5F',
        portait: PortraitMichael,
        email: 'jimmy@ringover.com',
        networkStatus: 'success',
        isFirstMessage: true,
        isRaisingHand: true,
        isExternal: true,
        isMuted: true,
        isPinned: true,
        isTalking: false,
        durationTalk: 24
    },
    {
        id: 5,
        userName: 'amal',
        initials: 'aj',
        colorTag: '#36CDCF',
        portait: PortraitSarah,
        email: 'amal@ringover.com',
        networkStatus: 'success',
        isFirstMessage: true,
        isRaisingHand: true,
        isExternal: true,
        isMuted: true,
        isPinned: false,
        isTalking: false,
        durationTalk: 16
    },
    {
        id: 6,
        userName: 'mohamed',
        initials: 'me',
        colorTag: '#36CDCF',
        portait: PortraitMichael,
        email: 'mohamed@ringover.com',
        networkStatus: 'success',
        isFirstMessage: true,
        isRaisingHand: false,
        isExternal: true,
        isMuted: true,
        isPinned: false,
        isTalking: false,
        durationTalk: 4
    },
    {
        id: 7,
        userName: 'alexis',
        initials: 'at',
        colorTag: '#36CDCF',
        email: 'alexis@ringover.com',
        networkStatus: 'warning',
        isFirstMessage: true,
        isRaisingHand: true,
        isExternal: true,
        isMuted: true,
        isPinned: false,
        isTalking: false,
        durationTalk: 0
    },
    {
        id: 8,
        userName: 'camille',
        initials: 'ce',
        colorTag: '#F05F5F',
        portait: PortraitSofia,
        email: 'camille@ringover.com',
        networkStatus: 'success',
        isFirstMessage: true,
        isRaisingHand: false,
        isExternal: true,
        isMuted: true,
        isPinned: false,
        isTalking: false,
        durationTalk: 147
    },
];
const emptyInitialState = participantAdapter.getInitialState();
const filledState = participantAdapter.upsertMany(emptyInitialState, list);


export const participantSlice = createSlice({
    name: 'participant',
    initialState:filledState,
    reducers: {
        participantAdded: participantAdapter.addOne,
        paticipantsSetAll: participantAdapter.setAll,
        participantUpdated: participantAdapter.setOne,
        participantUpdatedMany: participantAdapter.setMany,
        participantDelete: participantAdapter.removeOne
    }
});

export const participantReducer = participantSlice.reducer;
export const { participantAdded, participantUpdated, participantDelete,paticipantsSetAll,participantUpdatedMany } = participantSlice.actions;
export const {
    selectAll: selectAllParticipants,
    selectById: selectParticipantById,
    selectIds: selectParticipantIds,
} = participantAdapter.getSelectors(state => state.participant);