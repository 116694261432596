import React from 'react';
import FilesSvg from '../assets/icons/files.svg';
import HubSvg from '../assets/icons/hub.svg';

const InputLink = (props) => {
    return (
        <div className='input-frame input-link'>
            <input className='input-frame__input' placeholder={props.placeholder} />
            <div className='icon-frame' data-interactive={true}>
                <img src={FilesSvg} alt="file" />
                <img src={HubSvg} alt="share" />
            </div>
        </div>
    );
};

export default InputLink;