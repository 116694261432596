
import CrossSvg from '../assets/icons/cross.svg'
import OneFileSvg from '../assets/icons/one-file.svg';
import OneMediaSvg from '../assets/icons/one-media.svg';

import React, { useEffect, useState } from 'react';
import ProgressBar from './ProgressBar';

const UploadProgress = (props) => {

    const [rate, setRate] = useState(props.rate);


    useEffect(() => {

      const interval = setInterval(() => {
        
        setRate((oldRate) => {
          const newRate = oldRate + 1;
          if(newRate === 100){
            clearInterval(interval);
          }
          return newRate;
        })

      },100)


    }, []);

  return (
    <div className='progress-upload'>

        <div className='progress-upload__picto'>
        {(() => {
            switch (props.type) {
            case 'media':
                return <img src={OneMediaSvg} alt='media' />;
            case 'fichier':
                return <img src={OneFileSvg} alt='file' />;
            default:
                return null;
            }
        })()}
        </div>



      <div className='progress-upload__content'>
        <div className='progress-upload__legend'>
            <span>{props.filename}</span>
            <span className='max-size'>(2MB)</span>
        </div>
        <div className='progress-upload__bar'>
            <ProgressBar max={100} value={rate}/>
        </div>
      </div>

      <div className='progress-upload__cross' data-interactive={true} data-size='x-small'>
        <img src={CrossSvg} alt='close' onClick={props.remove} />
      </div>
    </div>
  );
};

export default UploadProgress;