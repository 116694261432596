import React from 'react';

const SquareBtn = (props) => {

    const btnClass = `btn-square ${props.isActive ? 'active' : ''}`;


    return (
        <button id={props.id} className={btnClass} onClick={props.event} data-size={props.size} data-interactive={props.interactive} >
            <img src={props.icon} alt={props.alt}/>
            {props.content && <span>{props.content}</span>}
        </button>
    );
};

export default SquareBtn;