import React, { useState, useEffect, useRef } from 'react';
import arrowDown from '../assets/icons/arrow-down.svg';
import arrowUp from '../assets/icons/arrow-up.svg';

const InputSelect = ({ options, defaultOption, showOptions, handleValClick, type }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        showOptions && handleValClick();
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const listItems = options.map((o) =>
        <li key={o} onClick={() => handleOptionClick(o)}>{o}</li>
    );

    return (
        <div className={type === 'grid' ? 'input-box grid-box' : 'input-box'} ref={ref}>
            <div tabIndex={0} className='input-box__value scroll-bar invisible' onClick={() => setIsOpen(!isOpen)}>
                <span>{selectedOption ? selectedOption : defaultOption }</span>
                <span>{isOpen ? <img src={arrowUp} alt="arrow up"/> : <img src={arrowDown} alt="arrow down"/>}</span>
            </div>
            {isOpen && (
            <div className='input-box__options'>
                <ul className='scroll-bar invisible'>{listItems}</ul>
            </div>
            )}
        </div>
    )
};

export default InputSelect;
