import React, { useContext, useEffect, useRef, useState } from 'react';

import SettingsSvg from '../assets/icons/settings-icon.svg';
import CrossSvg from '../assets/icons/cross.svg';
import MosaicSvg from '../assets/icons/mosaic.svg';
import CrownSvg from '../assets/icons/crown-icon.svg';
import ThreeStarsSvg from '../assets/icons/three-stars.svg';
import RocketSvg from '../assets/icons/rocket.svg';
import DeviceSvg from '../assets/icons/device.svg';
import UserProfileSvg from '../assets/icons/user-profile.svg';
import BtnIcon from './BtnIcon';
import MoreSvg from '../assets/icons/plus-icon.svg';
import EnterRoomSvg from '../assets/icons/enter-room.svg';
import LockerSvg from '../assets/icons/locker.svg';
import KeySvg from '../assets/icons/key.svg';
import BtnRegular from './BtnRegular';

import VideoQualityRange from './VideoQualityRange';


import VisualEffectPanel from './VisualEffectPanel';
import DevicePanel from './DevicePanel';
import ProfileSettings from './ProfileSettings';

import InputSelect from './InputSelect';
import InputCheckbox from './InputCheckbox';
import ScreenLayout from './ScreenLayout';
import useAuthContext from '../hooks/useAuth';



const AllSettingsModal = (props) => {

    // etats du panneau 

    const [state,setState] = useState(1);
    const [showSelect, setShowSelect] = useState(false);
    const [showSelect1, setShowSelect1] = useState(false);

    const { isDevMode, setIsDevMode } = useAuthContext();



    const handleSelectClick = () => {
		setShowSelect(!showSelect);
	}

    const handleSelectClick1 = () => {
		setShowSelect1(!showSelect1);
	}

    const isWindows = /Win/.test(navigator.userAgent);

    // const playSound = () => {
    //     audioRef.current.currentTime = 0; // Réinitialise la lecture du son
    //     audioRef.current.play();
    // }

    // const playOkSound = () => {
    //     okAudioRef.current.currentTime = 0; // Réinitialise la lecture du son
    //     okAudioRef.current.play();
    // }

    // a refacto (en classe) gestion des boutons actifs 

    const initActive = () => {
        let initActiveBtn = document.querySelector(".duo-tab .btn-frame button:nth-child(1)");
        if (initActiveBtn) {
            initActiveBtn.classList.add('active');
        }
    }

    const handleActive = (event) => {
        // recuperer les bouton
        let allBtns = document.querySelectorAll('.duo-tab .btn-frame .btn-icon ');
        allBtns.forEach((e) => {
            if(e.classList.contains('active')){
                e.classList.remove('active');
            }
        })
        let tagName = event.tagName.toLowerCase();
        if(tagName === 'span' || tagName === 'img' ){
            event = event.parentElement;
            console.log(event);
        }
        event.classList.add('active');
    }


    const handleBtnPanel = (e,etat) => {
        // setState(1);
        console.log(etat);
        switch (etat) {
            case 'admin':
                setState(1);
                break;
            case 'screen layout':
                console.log('screen layout');
                setState(2);
                break;
            case 'visual effects':
                console.log('visual effects');
                setState(3);
                break;
            case 'devices':
                console.log('devices');
                setState(4);
                break;
            case 'performance':
                console.log('perf');
                setState(5);
                break;
            case 'profile':
                console.log('profile');
                setState(6);
                break;
            case 'more options':
                console.log('more options');
                setState(7);
                break;
            default:
              console.log(`aucun état`);
        }
        // playSound();
        let domItem = e.target;
        handleActive(domItem);
    }

    const [formValue, setFormValue] = useState({
        selectedOption: ''
      });
 
    const onValueChange = (e) => {
        setFormValue({
            selectedOption: e.target.value
        });
        // playSound();
    };
    
    const formSubmit = (e) => {
        e.preventDefault();
        console.log(formValue.selectedOption);
    };




    const handleSave = () => {
        // playOkSound();
    }

    const handleDevMode = () => {
        setIsDevMode(!isDevMode);
    }


    useEffect(() => {
        initActive();
    },[])

    return (
        <div className='all-settings'>

            <div className={isWindows ? 'all-settings__frame --ms-settings' : 'all-settings__frame'}>
                <div className='all-settings__header'>
                    <div className='all-settings__heading' data-size="large">
                        <img src={SettingsSvg} alt='settings'/>
                        <h2>Settings</h2>
                    </div>      
                    <div className='all-settings__close' data-interactive={true} data-size="large">
                        <img src={CrossSvg} alt="fermer" onClick={props.event}/>
                    </div>
                </div>
                {/* <audio ref={audioRef}>
                    <source src={clickSound} type="audio/wav" />
                </audio>
                <audio ref={okAudioRef}>
                    <source src={okSound} type="audio/wav" />
                </audio> */}
                <div className='all-settings__body duo-tab'>
                    <div className='duo-tab__option'>
                        <div className='btn-frame'>
                            <BtnIcon
                                content='Admin Settings'
                                icon={CrownSvg}
                                event={(e) => handleBtnPanel(e,'admin')}
                                />
                            <BtnIcon
                                content='Screen Layout'
                                icon={MosaicSvg}
                                event={(e) => handleBtnPanel(e,'screen layout')}
                                />
                            <BtnIcon
                                content='Visual effects'
                                icon={ThreeStarsSvg}
                                event={(e) => handleBtnPanel(e,'visual effects')}
                                />
                            <BtnIcon
                                content='Devices'
                                icon={DeviceSvg}
                                event={(e) => handleBtnPanel(e,'devices')}
                                />
                            <BtnIcon
                                content='Performance'
                                icon={RocketSvg}
                                event={(e) => handleBtnPanel(e,'performance')}
                                />
                            <BtnIcon
                                content='Profile'
                                icon={UserProfileSvg}
                                event={(e) => handleBtnPanel(e,'profile')}
                                />
                            <BtnIcon
                                content='More'
                                icon={MoreSvg}
                                event={(e) => handleBtnPanel(e,'more options')}
                            />
                        </div>
                    </div>
                    <div className='duo-tab__content tab-content'>
                        {state === 1 && (
                            <div className='tab-content__block --admin'>
                                <form onSubmit={formSubmit}>
                                    <label className='option-frame'>
                                        <div className='option-frame__left'>
                                            <div className='option-frame__picto'>
                                            <img src={EnterRoomSvg} alt="anyone can enter room" />
                                            </div>
                                            <div className='option-frame__text'>
                                            <div className='option-frame__label'>
                                                <span>Anyone with link can enter</span>
                                            </div>
                                            <div className='option-frame__legend'>
                                                <span>Anyone with the meeting link can join.</span>
                                            </div>

                                            </div>
                                        </div>
                                        <div className='option-frame__right'>
                                            <input
                                                type="radio"
                                                id="anyone"
                                                value="anyone"
                                                checked={formValue.selectedOption === "anyone"}
                                                onChange={onValueChange}
                                            />
                                        </div>
                                    </label>

                                    <label className='option-frame'>
                                        <div className='option-frame__left'>
                                            <div className='option-frame__picto'>
                                            <img src={LockerSvg} alt="knock to enter" />
                                            </div>
                                            <div className='option-frame__text'>
                                            <div className='option-frame__label'>
                                                <span>Knock to enter</span>
                                            </div>
                                            <div className='option-frame__legend'>
                                                <span>Only allow people to enter the meeting after approval from the host.</span>
                                            </div>
                                            </div>
                                        </div>
                                        <div className='option-frame__right'>
                                        <input
                                                type="radio"
                                                id="knock"
                                                value="knock"
                                                checked={formValue.selectedOption === "knock"}
                                                onChange={onValueChange}
                                                />
                                        </div>
                                    </label>

                                    <label className='option-frame'>
                                        <div className='option-frame__left'>
                                            <div className='option-frame__picto'>
                                            <img src={KeySvg} alt="require password to enter" />
                                            </div>
                                            <div className='option-frame__text'>
                                            <div className='option-frame__label'>
                                                <span>Require password to enter</span>
                                            </div>
                                            <div className='option-frame__legend'>
                                                <span>Participants must enter a password to join the meeting.</span>
                                            </div>
                                            </div>
                                        </div>
                                        <div className='option-frame__right'>
                                            <input
                                                type="radio"
                                                id="require"
                                                value="require"
                                                checked={formValue.selectedOption === "require"}
                                                onChange={onValueChange}
                                            />
                                        </div>
                                    </label>

                                    <div>
                                        Selected option is: {formValue.selectedOption}
                                    </div>
                                </form>
                            </div>
                        )}
                        {state === 2 && (
                            <ScreenLayout/>
                        )}
                        {state === 3 && (
                            <VisualEffectPanel/>
                        )}
                        {state === 4 && (
                            <DevicePanel/>
                        )}
                        {state === 5 && (
                            <div className='tab-content__block --perf'>
                                <h4>Adjust for:</h4>
                                <div className='progress-bar'>
                                    <div className='progress-bar__legend'>
                                        <div className='progress-bar__legend__left'>
                                            <h4>Best performance</h4>
                                            <p>Ideal if you have a weak wifi connection</p>
                                        </div>
                                        <div className='progress-bar__legend__right'>
                                            <h4>Highest quality</h4>
                                            <p>Ideal if you have a strong wifi connection</p>
                                        </div>
                                    </div>
                                    <div className="progress-bar__bar">
                                        <div className='progress-bar__grid'>
                                            <div>0</div>
                                            <div>1</div>
                                            <div>2</div>
                                            <div>3</div>
                                        </div>
                                        <VideoQualityRange/>
                                    </div>
                                </div>
                            </div>
                        )}
                        {state === 6 && (
                            <ProfileSettings/>
                        )}
                        {state === 7 && (
                            <div className='tab-content --more-options'>
                                <div className='tab-content__legend'>
                                    <span>These settings apply to your device only and won't affect other participants settings.</span>
                                </div>
                                <div className='tab-content__body'>
                                    <div className='input-frame'>
                                        <div className='input-frame__single'>
                                            <label htmlFor='language'>Language</label>
                                            <InputSelect
                                                options={['english','français','español']}
                                                defaultOption='english'
                                                showOptions={showSelect}
                                                handleValClick={handleSelectClick}
                                            />
                                        </div>
                                        <div className='input-frame__single'>
                                            <label htmlFor='frame-rate'>Desktop sharing frame rates</label>
                                            <InputSelect
                                                options={['30 frames per second','60 frames per second']}
                                                defaultOption='30 frames per second'
                                                showOptions={showSelect1}
                                                handleValClick={handleSelectClick1}
                                            />
                                        </div>
                                    </div>
                                    <div className='check-frame'>
                                        <div className='check-frame__option'>
                                            <InputCheckbox content='enable keyboard shortcuts' htmlFor='enable keyboard shortcuts' name="enable keyboard shortcuts" id="enable keyboard shortcuts"/>
                                            <InputCheckbox content='turn off chat popups' htmlFor='turn off chat popups' name="turn off chat popups" id="turn off chat popups"/>
                                        </div>
                                        <div className='check-frame__option'>
                                            <button id="open-shortcuts">open shortcuts</button>
                                        </div>

                                    </div>
                                    <div className='check-frame'>
                                        <div className='check-frame__option'>
                                        <InputCheckbox content={!isDevMode ? 'enable dev mode' : 'disable dev mode' } htmlFor='enable-devmode' name="enable-devmode" id="enable-devmode" checked={isDevMode} handleChange={handleDevMode}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='all-settings__footer'>
                    <div className='btn-duo-frame'>
                        <BtnRegular
							content='Cancel'
							id='cancel_settings'
                            event={props.event}
							// event={handleClick}
                            />
						<BtnRegular
							content='Save'
							id='save_settings'
							event={handleSave}
						/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllSettingsModal;