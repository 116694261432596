import React, { useEffect, useRef, useState } from 'react';
import CrossSvg from '../assets/icons/cross.svg';
import CheckSvg from '../assets/icons/check.svg';
import WheelSvg from '../assets/icons/wheel-params.svg';
import ThreeStarsSvg from '../assets/icons/three-stars.svg';
import AddSvg from '../assets/icons/plus-icon.svg';

import BtnIcon from './BtnIcon';
import InputCheckbox from './InputCheckbox';
import InputSelect from './InputSelect';
import BtnRegular from './BtnRegular';

import ForbiddenSvg from '../assets/icons/forbidden.svg';
import { closeModalOnClickOutside } from '../utils/closeModalOnClickOutside';

import VisualEffectPanel from './VisualEffectPanel';
import DevicePanel from './DevicePanel';
import ProfileSettings from './ProfileSettings';


const PreviewSettingsModal = () => {

    const [state,setState] = useState(1);


    const handleClick = (event) => {
        // event.preventDefault();
        event.stopPropagation();
        let modal = document.querySelector('.preview-settings-modal');
        modal.classList.remove('show');
    };

    const initActive = () => {
        let initActiveBtn = document.querySelector('.preview-settings-modal__body__left .btn-frame .btn-icon:nth-child(1)');
        initActiveBtn.classList.add('active');
    }

    const handleActive = (event) => {
        // recuperer les bouton
        let allBtns = document.querySelectorAll('.preview-settings-modal__body__left .btn-frame .btn-icon ');

        // si un bouton contient la classe active, on lui enleve la classe active 
        allBtns.forEach((e) => {
            if(e.classList.contains('active')){
                e.classList.remove('active');
            }
        })
        let tagName = event.tagName.toLowerCase();
        if(tagName === 'span' || tagName === 'img' ){
            event = event.parentElement;
            console.log(event);
        }
        event.classList.add('active');

    }

    const handleAudioSettings = (e) => {
        setState(1);
        let domItem = e.target;
        handleActive(domItem);
    }
    
    const handleVisualEffects = (e) => {
        setState(2)
        let domItem = e.target;
        handleActive(domItem);
    }
    
    const handleMoreOptions = (e) => {
        setState(3)
        let domItem = e.target;
        handleActive(domItem);
    }


    useEffect(() => {
        initActive();
        closeModalOnClickOutside('.preview-settings-modal','.preview-settings-modal__container');
    },[])

    return (
        <div className="large-modal preview-settings-modal">
            <div className="preview-settings-modal__container large-modal__container scroll-bar">
                <div className='preview-settings-modal__heading'>
                    <div className="heading-frame">
                        <img src={CheckSvg} alt="check"/>
                        <h3 id='text_your_meet_has_been_created'>Preview your video and audio</h3>
                    </div>
                    <div className='exit-frame' data-size="medium" data-interactive={true}>
                        <img
                            src={CrossSvg}
                            alt='close'
                            onClick={handleClick}
                        />
                    </div>
                </div>
                <div className='preview-settings-modal__body'>
                    <div className='preview-settings-modal__body__left'>
                        <div className='btn-frame'>
							<BtnIcon
                                content='Audio and video'
                                icon={WheelSvg}
                                event={handleAudioSettings}
							/>
							<BtnIcon
                                content='Visual effects'
                                icon={ThreeStarsSvg}
                                event={handleVisualEffects}
							/>
							<BtnIcon
                                content='More'
                                icon={AddSvg}
                                event={handleMoreOptions}
							/>
						</div>
                    </div>
                    <div className='preview-settings-modal__body__right'>
                        {state === 1 && (
                            <DevicePanel/>
                        )}
                        {state === 2 && (
                            <VisualEffectPanel/>
                        )}
                        {state === 3 && (
                            <ProfileSettings/>
                        )}
                    </div>
                </div>
                <div className='preview-settings-modal__footer'>
                    <div className='btn-frame button-frame btn-duo-frame'>
                        <BtnRegular content='Cancel' event={handleClick}/>
                        <BtnRegular content='Save'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreviewSettingsModal;