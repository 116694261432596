import React, { useEffect, useState } from 'react';
// import { LiveKitRoom, useToken, VideoConference } from '@livekit/components-react';
// import '@livekit/components-styles';
// import '@livekit/components-styles/prefabs';

import BgPanel from './components/BgPanel';
import Loading from './pages/Loading';
import translate from './data/translate';
import useAuthContext from './hooks/useAuth';
import './app.css';
import { useDispatch } from 'react-redux';
import { setMeetlist } from './redux/meetSlice';
import useMediaQuery from './hooks/useMediaQuery';
import api from './api';

const App = ({ children }) => {
	const dispatch = useDispatch();
	const [onLine, setIsOnLine] = useState(navigator.onLine);
	const [loading, setLoading] = useState(true);
	const { userLang, userUUID} = useAuthContext();

	const setOnline = () => setIsOnLine(true);
	const setOffline = () => setIsOnLine(false);	
	
	useEffect(() => {
		// async function loadMeetlist() {
		// 	const response = await api.get('/meetlist', { params: { userUUID } });
		// 	dispatch(setMeetlist(response.data?.list ?? []));
		// }

		// loadMeetlist();
	}, []);


	useEffect(() => {
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		const timer = setTimeout(() => {
			setLoading(false);
		}, 1000);

		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
			clearTimeout(timer);
		};
	}, []);

	const errorMessage =
		translate['Route Error Message']?.['Lost Connection']?.[userLang];

	if (onLine && loading) {
		return <Loading />;
	}
	if (onLine && !loading) {
		return <>{children}</>;
	}
	return (
		<div className='room-not-found'>
			<div className='room-not-found__header'>
				<BgPanel />
			</div>
			<div className='room-not-found__body'>
				<div className='room-not-found__body__container'>
					<div className='room-not-found__body__header'>
						<h2 id='text_the_room_is_unfindable'>{errorMessage}</h2>
					</div>
				</div>
			</div>
		</div>
	);
};

export default App;

//   const roomName = 'test-room';
//   const userIdentity = 'test-identity';
//   const token = useToken('/getToken', roomName, {
//     userInfo: {
//       identity: userIdentity,
//       name: userIdentity,
//     },
//   });