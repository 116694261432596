import { json } from 'react-router-dom';
import * as utils from '../utils';

export const RouteErrorTypes = {
	RoomNotFound: 404,
	BadConnect: 400,
};

export const RouteErrorMessage = {
	RoomNotFound: 'Room Not Found',
	BadConnect: 'Bad Connection',
};

const pageLoader = async ({ request }) => {
	
	// check connection speed
	const hasLowConnection = utils.checkHasLowConnection();
	if (hasLowConnection) {
		throw new Response(RouteErrorMessage.BadConnect, {
			status: RouteErrorTypes.BadConnect,
		});
	}

	const url = new URL(request.url);
	const pathname = url.pathname.substring(1);
	if (pathname.length > 0) {
		const hasNoSpecial = utils.checkHasSpecialStr(decodeURI(pathname));
		if (!hasNoSpecial) {
			throw new Response(RouteErrorMessage.RoomNotFound, {
				status: RouteErrorTypes.RoomNotFound,
			});
		}
	}

	return json({ abc: 'abc', edf: 'edf' }, { status: 200 });
};

export default pageLoader;
