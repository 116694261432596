import React from 'react';
import UserProfileSvg from '../assets/icons/user-profile.svg';

const BtnCheck = (props) => {

  const checkButton = () => {
    props.onButtonClick(props.content);
  }

  return (
    <button data-interactive={true} className={`btn-check ${props.isActive ? 'active' : ''}`} onClick={checkButton}>
      <div className='btn-check__left' >
        <img src={props.icon ? props.icon : UserProfileSvg} alt={props.alt ? props.alt : 'default icon'}/>
        <span>{props.content}</span>
      </div>
      <div className='btn-check__right'>
        <div className={`input-checkbox --alt ${props.isActive ? 'active' : ''}`}>
          <input
            type='checkbox'
            checked={props.isChecked}
            onChange={() => {}}
          />
        </div>
      </div>
    </button>
  );
};

export default BtnCheck;