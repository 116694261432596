import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		profile: null,
		email: null,
		fullName: null,
	},
	reducers: {
		setUser: (state, action) => {
			return {
				...state,
				profile: action.payload.profile,
				email: action.payload.email,
				fullName: action.payload.email,
			};
		},
	},
});
export const userReducer = userSlice.reducer;
export const { setUser } = userSlice.actions;
export const getUser = (state) => state.user;
