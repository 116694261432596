import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRoomName } from '../redux/meetSlice';
import translate from '../data/translate.json';
import CrossSvg from '../assets/icons/cross.svg'
import ShareLinkSvg from '../assets/icons/share-link.svg'
import CopySvg from '../assets/icons/files.svg';
import GmailSvg from '../assets/icons/gmail.svg';
import OutlookSvg from '../assets/icons/outlook.svg';
import WhatsappSvg from '../assets/icons/whatsapp.svg';
import MessengerSvg from '../assets/icons/messenger.svg';
import InstaSvg from '../assets/icons/insta.svg';
import TwitterSvg from '../assets/icons/twitter.svg';
import SmsSvg from '../assets/icons/sms.svg';
import RingoverChatSvg from '../assets/icons/ringover-chat.svg';
import useAuthContext from '../hooks/useAuth';
import DraggableModal from './DraggableModal';


const LinkIcon = ({ linkTo, icon, label, enabled = true, infoText = '', ...rest }) => {
	const [show, setShow] = useState(false);
	return (
		<div className={`link-icon-wrapper`} onMouseEnter={()=>setShow(true)} onMouseLeave={()=>setShow(false)}>
			<div className={`link-icon ${enabled ? '': 'is-disabled'}`} onClick={linkTo} data-size="small" {...rest}>
				<img src={icon} alt=''/>
				<span>{label}</span>
			</div>
			{show && !enabled && <span className={`link-icon-info`}>{infoText}</span>}
		</div>
		
	);
}
const ShareModal = (props) => {

	const { userLang, meetName } = useAuthContext();
	const meetURL = `https://meet.ringover.io/${meetName}`;

	let draggableModal = new DraggableModal('.share-modal__container', '.share-modal__separator', 3);

	const subject = translate['Social Share']['Email Subject'][userLang];
	const body = `${translate['Social Share']['Email Body'][userLang]}: ${meetURL}`;

	const smsUrl = `sms:?&body=${body}`;
	const whatsappUrl = `whatsapp://send?text=${body}`;
	const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(body)}`;
	const outlookWebUrl = `https://outlook.live.com/owa/?path=/mail/action/compose&subject=${subject}&body=${body}`;
	const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

	const ringoverChatUrl = 'https://app.ringover.com/sms';
	const instaUrl = 'https://www.instagram.com/direct/new';
	const messengerUrl = 'https://www.facebook.com/messages/new';

	const [clipboard,setClipboard] = useState(false);
	const isAuthenticated =
			localStorage.getItem("id_token") &&
			localStorage.getItem("access_token") &&
			localStorage.getItem("refresh_token");

	const handleClick = (event) => {
        event.stopPropagation();
        let modal = document.querySelector('.share-modal');
        modal.classList.remove('show');
    };


	const copyToClipboard = () => {
		setClipboard(!clipboard);

		navigator.clipboard.writeText(meetURL).then(
			() => console.log('successfully'),
			() => console.log('failed')
			);
		setTimeout(() => {
			setClipboard(false);
		}, 3000);
	}
	
	const clickShareButton = (url) => {
		setClipboard(true);
		setTimeout(function () {
			navigator.clipboard.writeText(body)
			.then(() => {
				window.open(url)
			})
		setClipboard(false);
		}, 1500);
	};
	return (
		<>		
			<div className='share-modal large-modal'>
				{clipboard && 
					<div className='share-modal__clipped'>
						<div className='pill'>
							<p>Link copied to clipboard !</p>
						</div>
					</div>
				}
				<div className='share-modal__container large-modal__container'>
					<div className='share-modal__heading'>
						<h3 id='text_share_your_meet'>Share your Meet link</h3>
						<div className='exit-frame' data-size="medium" data-interactive={true}>
							<img
								src={CrossSvg}
								alt='close'
								onClick={handleClick}
							/>
						</div>
					</div>
					<div className='share-modal__separator'><hr/></div>
					<div className='share-modal__link'>
						<img
							className='share-modal__link__img'
							src={ShareLinkSvg}
							alt='share-link'
						/>
						<div className='share-modal__link__content'>
							<div className='share-modal__link__content__link'>
								<p>
									<span id='text_video_meeting_link'>
										Video meeting link shared with you:
									</span>
								</p>
								<p>{meetURL}</p>
							</div>
							<div className='share-modal__link__content__icon' data-size="large" data-interactive={true}>
								<img src={CopySvg} alt='copy'
									onClick={copyToClipboard}
								/>
							</div>
						</div>
					</div>
					<div className='share-modal__icon-list'>
						<LinkIcon
							label='Gmail'
							icon={GmailSvg}
							linkTo={() => {
								window.open(gmailUrl);
							}}
						/>
						<LinkIcon
							icon={OutlookSvg}
							label='Outlook'
							linkTo={() => {
								window.open(outlookWebUrl);
							}}
						/>
						<LinkIcon
							icon={WhatsappSvg}
							label='Whatsapp'
							linkTo={() => window.open(whatsappUrl)}
						/>
						<LinkIcon
							icon={MessengerSvg}
							label='Messenger'
							linkTo={() => clickShareButton(messengerUrl)}
						/>
						<LinkIcon
							icon={InstaSvg}
							label='Instagram'
							linkTo={() => clickShareButton(instaUrl)}
						/>
						<LinkIcon
							icon={TwitterSvg}
							label='Twitter'
							linkTo={() => window.open(twitterUrl)}
						/>
						<LinkIcon
							icon={SmsSvg}
							label='Message'
							linkTo={() => window.open(smsUrl)}
						/>
						<LinkIcon
							icon={RingoverChatSvg}
							enabled={isAuthenticated}
							label='Ringover Chat'
							linkTo={() => {
								if (isAuthenticated) clickShareButton(ringoverChatUrl);
							}}
							infoText='Please sign in with ringover'
							/>
					</div>
				</div>
			</div>
		</>
	);
}
export default ShareModal