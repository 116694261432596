import React from 'react';
import TrashSvg from '../assets/icons/trash.svg';
import ReturnSmall from '../assets/icons/return-small.svg';
import StarSvg from '../assets/icons/star-white.svg';
import AboutSvg from '../assets/icons/about-blue.svg';
import PortraitSarah from '../assets/pictures/portrait.jpg';
import OptionRadio from './OptionRadio';

const MessageBox = (props) => {
    let now = new Date().toLocaleTimeString();
    const { isEmetteur, isFirst, isLast, className, isStart, isSondage, questionContent } = props;

    // Vérifier si les flags isRight et isLeft sont tous les deux définis

    // Construire la liste de classes CSS   



    let rowClassName = "message__row";
    if (isEmetteur) {
        rowClassName += " --right";
    } else {    
        rowClassName += " --left";
    }

    if(isSondage){
        rowClassName += " --sondage";
    }

    if(isLast){
        rowClassName += " --last";
    } else if(isStart){
        rowClassName += " --first";
    } else {
        rowClassName += " --following"; 
    }


    // Ajouter la classe personnalisée si elle est fournie
    if (className) {
        rowClassName += ` ${className}`;
    }

    return (
        <div className={rowClassName}>
            <div className={props.isSondage ? 'message__box --sondage':'message__box'}>
                {(props.isFirst || props.isSondage) && (
                    <>
                        {props.isSondage && (
                            <div className='message__box__heading'>
                                <img src={AboutSvg} alt="about"/>
                                {props.asking && <span>vote live</span> }
                                {props.response && <span>vote ended</span> }
                            </div>
                        )}
                        <div className='message__box__author'>
                            <div className='round-pill avatar'>
                                {props.portrait ? 
                                <img src={props.portrait} alt={props.alt}/>
                                :                                         
                                <span className='macaroon' style={{ backgroundColor: props.colorTag }}>{props.initials}</span>
                                }
                                
                            </div>
                            <span>{props.userName ? props.userName : 'Sarah'}</span>
                        </div>
                    </>
                )}
                {!props.isSondage ? (
                    <>
                    {props.hasMedia && (
                        <div className='message__box__media'>media</div>
                    )}
                    <div className='message__box__content'>{props.messageContent}</div>
                    </>
                ) : (
                    <div className='message__box__body'>
                        <div className='message__box__question'>
                            <p>{props.questionContent}</p>
                        </div>
                        {props.asking && (
                            <>
                                <div className='message__box__options'>
                                    <OptionRadio fieldName="sondageT" id="yes" value="Yes"/>
                                    <OptionRadio fieldName="sondageT" id="no" value="No"/>
                                    <OptionRadio fieldName="sondageT" id="idk" value="I don't know"/>
                                </div>
                                <div className='message__box__footer'>
                                    <span>Vote to see the result</span>
                                </div>
                            </>
                        )}
                        {props.response && (
                            <>
                                <div className='message__box__options'>
                                    <div className='pill-stats'>
                                        <div className="pill-stats__content" style={{ width: props.percentYes + '%' }}>
                                            <span>Yes</span>
                                            <span>{props.percentYes}%</span>
                                        </div>
                                    </div>
                                    <div className='pill-stats'>
                                        <div className="pill-stats__content" style={{ width: props.percentNo + '%' }}>
                                            <span>No</span>
                                            <span>{props.percentNo}%</span>
                                        </div>
                                    </div>
                                    <div className="pill-stats">
                                        <div className="pill-stats__content" style={{ width: props.percentIdk + '%' }}>
                                            <span>Idk</span>
                                            <span>{props.percentIdk}%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='message__box__footer'>
                                    <span>{props.nbVotes} votes</span>
                                </div>
                            </>
                        )}
                    </div>
                )}


            </div>

            {/* message options  */}

            <div className='message__info overlay'>
                <div className='options' data-size="x-small" data-interactive={true}>
                    <img src={TrashSvg} alt="trash" />
                    {/* <img src={ReturnSmall} alt="reply" /> */}
                    <img src={StarSvg} alt="favorite" />
                </div>
                <div className='sending-time'>
                    <span>{now}</span>
                </div>
            </div>
        </div>
    );
};

export default MessageBox;
