import React from 'react';

const OptionRadio = (props) => {
    return (
        <div className='option-radio'>
            <label htmlFor={props.id}>{props.value}</label>
            <input type="radio" id={props.id} name={props.fieldName} value={props.value} checked={props.checked}  />
        </div>
    );
};

export default OptionRadio;
