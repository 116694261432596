import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import BgPanel from '../components/BgPanel';
import PortraitPeter from '../assets/pictures/portrait-peter.jpg';
import formatTime from '../utils/formatTime';

import {useSelector,useDispatch} from 'react-redux';
import { participantAdded, participantDelete, participantUpdated, participantUpdatedMany, paticipantsSetAll, selectAllParticipants } from '../redux/participantSlice';

import BtnIcon from '../components/BtnIcon';
import RaiseHand from '../assets/icons/raise-hand.svg';
import RoomBottomPanel from '../components/RoomBottomPanel';
import MicroSvg from '../assets/icons/micro.svg';
import MicroMuteSvg from '../assets/icons/micro-mute.svg';
import MicroBlueSvg from '../assets/icons/micro-blue.svg';
import ThreeDotsSvg from '../assets/icons/three-dots.svg';
import NetworkStatusDangerSvg from '../assets/icons/network-status-danger.svg';
import NetworkStatusWarningSvg from '../assets/icons/network-status-warning.svg';
import NetworkStatusStableSvg from '../assets/icons/network-status-stable.svg';

// chatpanel component 

import CrossSvg from '../assets/icons/cross.svg'
import SearchSvg from '../assets/icons/search.svg';
import FilterSvg from '../assets/icons/filter-icon.svg';
import PinSvg from '../assets/icons/pin.svg';
import CrownSvg from '../assets/icons/crown-icon.svg';
import PinTwoSvg from '../assets/icons/pin-two.svg';


import ShareScreenActiveSvg from '../assets/icons/share-screen-active.svg';

import AddSmileySvg from '../assets/icons/add-smiley.svg';
import SendArrowSvg from '../assets/icons/send-arrow.svg';


import StarSvg from '../assets/icons/star-white.svg';
import TrashSvg from '../assets/icons/trash.svg';
import UploadSvg from '../assets/icons/upload-icon.svg';
import AddUserSvg from '../assets/icons/add-user-icon.svg';


import Input from '../components/Input';
import SquareBtn from '../components/SquareBtn';
import UploadProgress from '../components/UploadProgress';
import MessageBox from '../components/MessageBox';

import arrowDown from '../assets/icons/arrow-down.svg';
import arrowUp from '../assets/icons/arrow-up.svg';

import Emoji from '../components/Emoji';
import TopBarMobile from '../components/TopBarMobile.js';
import Collapsible from 'react-collapsible';

import useMediaQuery from '../hooks/useMediaQuery';
import ScreenLayout from '../components/ScreenLayout';
import VisualEffectPanel from '../components/VisualEffectPanel';

import useAuthContext from '../hooks/useAuth';
import DraggableModal from '../components/DraggableModal';



const DevRoom = ({props}) => {


    const [files, setFiles] = useState([
        { 
            id: 1, 
            filename:'filename1.pdf', 
            rate:40, 
            type:'media', 
        },
        { 
            id: 2, 
            filename:'filename2.pdf', 
            rate:60, 
            type:'fichier', 
        },
        { 
            id: 3, 
            filename:'filename3.pdf', 
            rate:16, 
            type:'media', 
        },
        // ... autres fichiers
    ]);


    const emojis = [
        { symbol: '😃', label: 'grinning-face', shortcut:':gf:' },
        { symbol: '😆', label: 'squinting-face', shortcut:':sf:' },
        { symbol: '😅', label: 'grinning-face-sweat' },
        { symbol: '🤣', label: 'rolling-on-the-floor' },
        { symbol: '😂', label: 'face-with-tears-of-joy' },
        { symbol: '🙃', label: 'upside-down-face' },
        { symbol: '😉', label: 'winking-face' },
        { symbol: '😊', label: 'smiling-face-smiling-eyes' },
        { symbol: '🥰', label: 'smiling-face-with-heart' },
        { symbol: '😍', label: 'smiling-face-with-heart-eyes' },
        { symbol: '🤗', label: 'smiling-face-with-open-hands' },
        { symbol: '🤭', label: 'face-with-hand-over-mouth' },
        { symbol: '😘', label: 'face-blowing-kiss' },
        { symbol: '🤩', label: 'star-truck' },
        { symbol: '😛', label: 'face-with-tongue' },
        { symbol: '🤑', label: 'money-mouth-face' },
        { symbol: '😋', label: 'face-savouring-food' },
        { symbol: '😗', label: 'kissing-face' },
        { symbol: '🤫', label: 'shushing-face' },
        { symbol: '🫣', label: 'face-with-peeking-eye' },
        { symbol: '🤔', label: 'thinking-face' },
        { symbol: '🤐', label: 'zipper-mouth-face' },
        { symbol: '🤨', label: 'face-with-raised-eyebrow' },
        { symbol: '😐', label: 'neutral-face' },
        { symbol: '😑', label: 'expressionless-face' },
        { symbol: '😶', label: 'face-without-mouth' },
        { symbol: '😏', label: 'smirking-face' },
        { symbol: '🙄', label: 'face-with-rolling-eyes' },
        { symbol: '🤥', label: 'lying-face' },
        { symbol: '😔', label: 'pensive-face' },
        { symbol: '😴', label: 'sleeping-face' },
        { symbol: '😧', label: 'anguished-face' },
        { symbol: '😷', label: 'face-with-medical-mask' },
        { symbol: '🤒', label: 'face-with-thermometer' },
        { symbol: '🤕', label: 'face-with-head-bandage' },
        { symbol: '🤢', label: 'nauseated-face' },
        { symbol: '🤮', label: 'face-vomiting' },
        { symbol: '🥵', label: 'hot-face' },
        { symbol: '🥶', label: 'cold-face' },
        { symbol: '🥴', label: 'woozy-face' },
        { symbol: '😵', label: 'face-with-crossed-eye-face' },
        { symbol: '😵‍💫', label: 'face-with-spiral-face' },
        { symbol: '🥳', label: 'partying-face' },
        { symbol: '😎', label: 'smiling-face-with-sunglasses' },
        { symbol: '🤓', label: 'nerd-face' },
        { symbol: '😱', label: 'screaming-in-fear' },
        { symbol: '😞', label: 'disappointed-face' },
        { symbol: '🥱', label: 'yawning-face' },
        { symbol: '😡', label: 'enraged-face' },
        { symbol: '😠', label: 'angry-face' },
        { symbol: '👿', label: 'angry-face-with-horns' },
        { symbol: '🤬', label: 'face-with-symbols-on-mouth' },
        { symbol: '😺', label: 'grinning-cat' },
        { symbol: '😻', label: 'smiling-cat' },
        { symbol: '👋', label: 'waving-hand' },
        { symbol: '👌', label: 'ok-hand' },
        { symbol: '🤞', label: 'crossed-finger' },
        { symbol: '👍', label: 'thumbs-up' },
        { symbol: '👎', label: 'thumbs-down' },
        { symbol: '🙌', label: 'raising-hands' },
        { symbol: '🫶', label: 'heart-hands' },
        { symbol: '🙏', label: 'folded-hands' },
        { symbol: '🧠', label: 'brain' },
        { symbol: '👀', label: 'eyes' },
        { symbol: '👶', label: 'baby' },
        { symbol: '👦', label: 'boy' },
        { symbol: '👧', label: 'girl' },
        { symbol: '👨', label: 'man' },
        { symbol: '👩', label: 'woman' },
        { symbol: '👴', label: 'old-man' },
        { symbol: '👵', label: 'old-woman' },
        { symbol: '🙋‍♂️', label: 'man-raising-hand' },
        { symbol: '🙋‍♀️', label: 'woman-raising-hand' },
        { symbol: '🧑‍🔧', label: 'mechanic' },
        { symbol: '👪', label: 'family' },
        { symbol: '💼', label: 'briefcase' },
    ];


    const dispatch = useDispatch();
    
    const [lastUsedEmojis,setLastUsedEmojis] = useState([])


    const requestingParticipants = [
        {
            id:'1a',
            username:'koussai mb',
            isChecked:false
        },
        {
            id:'2b',
            username:'mister tk',
            isChecked:false
        },
        {
            id:'3c',
            username:'miss Jing',
            isChecked:false
        },
    ]

    const participants = useSelector(selectAllParticipants);
    const screenRefs = useRef([]);

    const [mode,setMode] = useState(0);
    const [sidePanel,setSidePanel] = useState('chat');
    const [allChatOptions,setAllChatOptions] = useState(false);
    const [displayUploadFrame,setDisplayUploadFrame] = useState(false);
    const [sendingFile,setSendingFile] = useState(false);
    const [showFilters,setShowFilters] = useState(false);

    const [msgE,setMsgE] = useState('');
    const [messagesList, setMessagesList] = useState([]);

    const [participantOptionTab,setParticipantOptionTab] = useState(false);
    const [moreOptionsIndex, setMoreOptionsIndex] = useState(null);
    const [externalDialogIndex, setExternalDialogIndex] = useState(null);

    // gestion des classes sur boutons 

    const [activeChatBtn,setActiveChatBtn] = useState(false);
    const [activeUploadBtn,setActiveUploadBtn] = useState(false);
    const [activeFilterbtn,setActiveFilterBtn] = useState(false);
    const [activeMediaFilterBtn,setActiveMediaFilterBtn] = useState(false);
    const [activeTaggedMessagesBtn,setActiveTaggedMessagesBtn] = useState(false);

    // gestion de la box participant request
    
    const [participantRequest,setParticipantRequest] = useState(true);
    const [participantRequestContent,setParticipantRequestContent] = useState(false);

    const [checkedParticipant,setCheckedParticipant] = useState([]);
    const [checkedState, setCheckedState] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    const [isRequestResponse,setIsRequestResponse] = useState(false);
    const [mobileOptions, setMobileOptions] = useState(false);

    const [chatBtnActive, setChatBtnActive] = useState(false);
    const { isDevMode, setIsDevMode } = useAuthContext();

    // gestion du responsive

    const isSmallScreen = useMediaQuery('(max-width: 800px)');
    

    // gestion de la box options 

    const [participantOptionBox,setParticipantOptionBox] = useState(false);
    const [participantOptionIndex,setParticipantOptionIndex] = useState(null);


    const dialogFrameRef = useRef(null);
    const textareaRef2 = useRef([]);
    const [activeParticipants, setActiveParticipants] = useState([]);
    const [modaleEmoji,setModaleEmoji] = useState(false);
    const [raisingHandBox,setRaisingHandBox] = useState(false);

    const DIALOG_FRAME_SELECTOR = '.chat-panel__dialog-frame';

    // gestion des fichiers 

    const [myPdf,setMyPdf] = useState(true);

    const updateParticipants = (newParticipants) => {
        // Mettre à jour le tableau de participants dans l'état du composant parent
        console.log(newParticipants);
        // setParticipants(newParticipants);
    };


    const addParticipant = () => {
        const colors = ['#36CDCF', '#FFCF4F', '#72BBF1', '#F05F5F', '#E885F0', '#6370E7', '#AEB6BF','#702963'];
        const initials = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      
        let lastId = participants.length > 0 ? participants[participants.length - 1].id : 0;
      
        // Générer un nombre aléatoire entre 0 et 2
        const randomIndex = Math.floor(Math.random() * 3);
      
        // Affecter une valeur aléatoire à networkStatus en fonction de la valeur de randomIndex
        let networkStatus;
        if (randomIndex === 0) {
          networkStatus = 'success';
        } else if (randomIndex === 1) {
          networkStatus = 'warning';
        } else {
          networkStatus = 'danger';
        }
      
        lastId++;
        const newParticipant = {
          id: lastId,
          userName: initials.charAt(Math.floor(Math.random() * initials.length)) + initials.charAt(Math.floor(Math.random() * initials.length)),
          initials: '',
          colorTag: colors[Math.floor(Math.random() * colors.length)],
          portrait: PortraitPeter,
          networkStatus,
          isFirstMessage:true,
          isExternal: true,
          isRaisingHand: false,
          isPinned:false,
          isMuted:true,
          isTalking:false,
          durationTalk:0
        };
      
        // Maintenant tu peux assigner la valeur de userName à initials
        newParticipant.initials = newParticipant.userName;
      
        // Générer l'adresse e-mail en utilisant les initiales et le domaine ringover.com
        const email = `${newParticipant.userName.toLowerCase()}@ringover.com`;
        newParticipant.email = email;
      
        // setParticipants([...participants, newParticipant]);
        dispatch(participantAdded(newParticipant));
      
        // Fait défiler le dernier élément ajouté dans la vue
        setTimeout(() => {
            const mosaicEl = document.querySelector('.devroom__mosaic');
            const lastScreenEl = mosaicEl.lastElementChild;
            if(!isSmallScreen){
                lastScreenEl.scrollIntoView({ behavior: 'smooth' });
            } else {
                lastScreenEl.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
            }
        }, 50);
    };


    const removeParticipant = () => {
        const allExternal = participants.filter(item => item.isExternal);
        var randomIndex = Math.floor(Math.random() * allExternal.length);
        const participantToDelete = allExternal[randomIndex];
        dispatch(participantDelete(participantToDelete.id));
    };


    const handleFullScreen = (event) => (participant) => {

        // Retirer "isTalking" pour tous les participants, sauf le participant actuel
        const updatedParticipants = participants.map((p) =>
            p.id === participant.id ? { ...p, isTalking: true } : { ...p, isTalking: false }
        );

        // Mettre à jour le store Redux avec la liste de participants mise à jour
        dispatch(paticipantsSetAll(updatedParticipants));

        // Mettre à jour le participant actuel avec "isTalking" à true
        dispatch(participantUpdated({ ...participant, isTalking: true }));

        // si target est de type image on return 
        let tagType = event.target.tagName.toLowerCase();
        if(event.target.className === 'external-box' || event.target.className === 'btn-regular' || event.target.className === 'btn-close' || event.target.className === 'test-cross'){
            // alert('bad item selected');
            return;
        }
        if(tagType === "img" || tagType === 'span' || tagType === 'textarea' || tagType === 'button'){
            // alert('bad item selected');
            return;
        }
        console.log(event.target.classList);
        let overlay = event.target.classList.contains('container');
        if(overlay){
            return;
        }

        // masquer/afficher les boutons de test 
        let testBtn = document.querySelector('.devroom #test');
        (!mode) ?  testBtn.classList.add('hidden') :  testBtn.classList.remove('hidden')
         
        console.log(event.target);
        let target = event.target.parentElement;
        // Inverse la valeur de mode entre 0 et 1

        // Récupère l'élément featuredFrame et lui ajoute ou enlève la classe 'flex'
        const featuredFrame = document.querySelector('.featured-frame');
        // featuredFrame.classList.add('flex');
      
        
        // Récupère tous les éléments avec la classe 'devroom__mosaic__screen'
        const allScreens = document.querySelectorAll('.devroom__mosaic__screen');
        const randomIndex = Math.floor(Math.random() * allScreens.length);
        const randomScreen = allScreens[randomIndex];

        // si la featuredFrame est vide 
        // -> on ajoute la target dedans
        // on passe en mode flex -> setmode a 1
        if(featuredFrame.children.length == 0){
            featuredFrame.classList.add('flex');
            // setMode(1)
            featuredFrame.appendChild(target);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            setMode(true);
        } else if (featuredFrame.children.length > 0){
            // la featured frame contient deja 1 element
            // on enleve la target de la featuredFrame
            // on vérifie si l'element clique est l'element de la featuredframe 
            // si le parent de target contient la class featuredframe 
            let featuredItem = document.querySelector('.featured-frame .devroom__mosaic__screen');
            if(target.parentElement.classList.contains('featured-frame')){
                // alert('pour l\'instant rien');
                featuredFrame.removeChild(featuredItem);
                var last = allScreens[allScreens.length - 1];
                last.parentElement.insertBefore(featuredItem,last);
                // on passe setMode a false  
                featuredFrame.classList.remove('flex');
                setMode(false);

            } else {
                featuredFrame.removeChild(featuredItem);
                target.parentElement.appendChild(featuredItem);
                target.parentElement.removeChild(target);
                featuredFrame.appendChild(target);
                testBtn.classList.add('hidden')
            }
        }

    };

    const handlePanelState = () => {
        removeActive();
        setSidePanel(null);
        let panel = document.querySelector('.devroom__body__side-panel');
        panel.style.display = "none";
    }


    const handleDisplayOptions = () => {
        setAllChatOptions(!allChatOptions);
    }

    const handleUpload = () => {
        if(modaleEmoji == true){
            setModaleEmoji(false);
            setActiveChatBtn(false);
        }
        setShowFilters(false);
        setActiveUploadBtn(!activeUploadBtn);
        setDisplayUploadFrame(!displayUploadFrame);
    }


    const handleSendingBox = () => {
        setSendingFile(!sendingFile);

    }

    const [lastClickTime, setLastClickTime] = useState(0);


    const handleMoreOptions = (index) => {
        setMoreOptionsIndex((prevIndex) => (prevIndex === index ? null : index));
    };


    const renderMoreOptions = (index) => {
        if (moreOptionsIndex === index) {
          return (
            <ul className='participant-list__more-options' onDoubleClick={(e) => e.stopPropagation()}>
              {/* <li>Send a private message</li> */}
              <li>Pin</li>
              {/* <li className='disabled'>Name animator</li> */}
              <li>Make host</li>
              <li>Ask to mute mic</li>
              <li className='disabled'>Mute mic</li>
              {/* <li className='disabled'>Link to CRM</li> */}
            </ul>
          );
        }
        return null;
    };


    const handleExternalBox = (index) => {
        if(sidePanel === 'chat'){
            setExternalDialogIndex((prevIndex) => (prevIndex === index ? null : index));
        } else {
            alert('ouvrir panel chat first');
        }
    };
      
    const renderExternalBox = (index, participant) => {
        if (externalDialogIndex === index) {
            return (
            <div className='external-box'>
                <BtnIcon className='btn-close' event={() => handleExternalBox(index)} icon={CrossSvg}/>
                <textarea
                    ref={el => textareaRef2.current[index] = el} 
                    className='external-box__textarea'
                    placeholder='message externe'
                    value={msgE}
                    onChange={handleInputChangeE}
                    onKeyDown={(e) => handleExternalInputChange(e, participant, index)}
                ></textarea>
                <button className='btn-regular' onClick={() => handleMessageParticipant(participant, index)}>Submit</button>
            </div>
            );
        }
        return null;
    };

    const updateMobileOptionsState = (newState) => {
        setMobileOptions(newState);
    };


    let draggableModal = new DraggableModal('.participant-fixed-option-box','.participant-fixed-option-box .action-frame__mobile__separator',32,true,updateMobileOptionsState);
    
    const renderOptionBox = (index,participant) => {
        if (participantOptionIndex === index) {
            if(isSmallScreen){
                return <div className='participant-fixed-option-box' onDoubleClick={(e) => e.stopPropagation()}>
                    <div className='action-frame__mobile__separator'><hr/></div>

                    <ul className='action-frame__mobile__options'>
                        <li>
                            <div className='round-pill avatar'><img src={participant.portait} alt="avatar"/></div>
                            <span>{participant.userName}</span>
                        </li>
                        <li>
                            <img src={PinTwoSvg} alt='épingler'/>
                            <span>Pin</span>
                        </li>
                        <li>
                            <img src={CrownSvg} alt='make host'/>
                            <span>Make host</span>
                        </li>
                        <li>
                            <img src={MicroMuteSvg} alt="Ask to mute mic"/>
                            <span>Ask to mute mic</span>
                        </li>
                        <li>
                        <img src={MicroMuteSvg} alt="Ask to mute mic"/>
                            <span>Mute mic</span>
                        </li>
                    </ul>
                </div>
            } else {
                return <div className='participant-option-box' onDoubleClick={(e) => e.stopPropagation()}>
                    <div className='participant-option-box__body'>
                        <ul>
                            <li>Pin</li>
                            <li>Make host</li>
                            <li>Ask to mute mic</li>
                            <li>Mute mic</li>
                        </ul>
                    </div>
                </div>
            }
        }
    }

    const handleScreenOptions = (index,participant,event) => {

        // Réinitialisation des écrans
        // screenRefs.current.forEach((screen) => {
        // });

        // Modification de l'écran spécifique
        const screen = screenRefs.current[index];
        if (screen) {
            screen.style.overflow = 'visible';
        }
        setParticipantOptionIndex((prevIndex) => (prevIndex === index ? null : index));
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
          const emoji = emojis.find((emoji) => emoji.shortcut === event.key);
          if (emoji) {
            handleEmojiClick(emoji.symbol);
          }
        };
      
        document.addEventListener('keydown', handleKeyDown);
        setSidePanel(null);
        let panel = document.querySelector('.devroom__body__side-panel');
        panel.style.display = "none";
      
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setParticipantRequest(false);
        }, 100000);
    },[]);


    const handleInputChangeE = (event) => {
        setMsgE(event.target.value);
        adjustTextareaHeight(event);
    };


    const handleExternalInputChange = (e, participant, index) => {
        let inputValue = e.target.value;
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          handleMessageParticipant(participant, index);
        } else {
            setMsgE(inputValue);
        }
      };

    const handleParticipantOptionTab = () => {
        setParticipantOptionTab(!participantOptionTab);
    }

    const removeActive = () => {
        let allBtns = document.querySelectorAll('.devroom__option-panel__right .action-frame button');
        allBtns.forEach((btn) => {
          if (btn.classList.contains('active')) {
            btn.classList.remove('active');
          }
        });
    };

    const handleDisplayFilters = () => {
        setActiveUploadBtn(false);
        setActiveChatBtn(false);
        setModaleEmoji(false);
        setDisplayUploadFrame(false);
        setShowFilters(!showFilters)
    }
      

    const sendParticipantMessage = (p) => {

        let dialogFrame = document.querySelector(DIALOG_FRAME_SELECTOR);
        // console.log('send participant message');
        const addBreakRow = (parent) => {
            let breakrow = document.createElement('br');
            parent.append(breakrow);
        }

        // si 0 participant , ajoute au tableau le current 
        if(activeParticipants.length === 0){
            activeParticipants.push(p);
        } else {
            // si le tableau est pas vide (il y a deja au moins un participant actif )
            const lastParticipant = activeParticipants[activeParticipants.length - 1];
            if(lastParticipant){
                if(lastParticipant.id === p.id){
                    // on fait rien 
                } else {
                    activeParticipants.push(p);
                    if(sidePanel){
                        addBreakRow(dialogFrame);
                    } else {
                        alert('ouvrir panneau side d abord')
                    }
                    // on recupere le dernier message envoyé dans la frame
                    let lastChild = dialogFrame.lastElementChild.previousElementSibling?.children[0];
                    if(lastChild){
                        lastChild.classList.add('--last');  
                        console.log(lastChild);

                    }

                    // pour tous les participants actifs on remet a true la propriete isFirst
                    const lastParticipants = [...activeParticipants];
                     const newList = lastParticipants.map(e=>{return {...e, "isFirstMessage":true}})
                
                    setActiveParticipants([...newList]);
                }
            }


        }

        // si le message est null 
        if(msgE === null || msgE === ''){
            alert('message vide');
            return;
        } else {
            const messageBoxComponent = (
                <MessageBox
                    isFirst={p.isFirstMessage}
                    isStart={p.isFirstMessage}
                    isLast={false}
                    userName={p.userName}
                    portrait={p.portait}
                    colorTag={p.colorTag}
                    initials={p.initials}
                    isEmetteur={p.isExternal ? false : true}
                    messageContent={msgE}
                />
            );
            setMessagesList([...messagesList, messageBoxComponent]);
            
            if(isSmallScreen){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
            scrollToBottom();
            // mettre a jour isFirstMessage
            // p.isFirstMessage = false;
                dispatch(participantUpdated({...p, 'isFirstMessage':false}));
            // reset message 
            setMsgE('');
            if(modaleEmoji === true){
                setModaleEmoji(false);
                setActiveChatBtn(false);
            }
            if(displayUploadFrame === true){
                setActiveUploadBtn(false);
                setDisplayUploadFrame(false);
            }

        }


        if(sidePanel ==='chat'){
        } else {
            console.log('chatpanel doit etre ouvert');
        }

        console.log(activeParticipants);


    }


    // Scroller vers le bas
    const scrollToBottom = () => {
        setTimeout(() => {
            const chatPanelDialogFrame = document.querySelector(DIALOG_FRAME_SELECTOR);
            chatPanelDialogFrame.scrollTop = chatPanelDialogFrame.scrollHeight;
        }, 0);
    };


    const checkEmptyMessage = (message, messageBoxComponent,textareaRef) => {
        if (message.trim() !== '') {
            setMessagesList([...messagesList, messageBoxComponent]);
            setMsgE('');
            textareaRef.current.focus(); // Déplacer le curseur sur le textarea
            scrollToBottom();
        } else {
            return;
        }
    };

    function handleMessageParticipant(participant,index){
        let panel = document.querySelector('.devroom__body__side-panel');
        // console.log(participant);

        // ouverture du panneau 

        if(sidePanel === 'chat'){
            console.log('panneau chat deja ouvert')
        } else {
            panel.style.display = 'flex';
            setSidePanel('chat');
            console.log('panneau ouvert');
        }

        // envoi d'un message test 
        sendParticipantMessage(participant);
        let openedBox = document.querySelector('.external-box');
        // openedBox.style.display = "none";

    }


    const removeThisParticipant = (index) => {
        const item = participants[index];
        dispatch(participantDelete(item.id));
    }


    const handleEmojiClick = (emoji,p) => {
        console.log(emoji);

          // Recherche l'emoji cliqué dans le tableau emojis
        const clickedEmoji = emojis.find((e) => e.symbol === emoji);

        if (clickedEmoji) {
            // Vérifie si l'emoji est déjà présent dans lastUsedEmojis
            const existingIndex = lastUsedEmojis.findIndex(
            (e) => e.symbol === clickedEmoji.symbol
            );

            // Si l'emoji est déjà présent, le déplace en tête du tableau
            if (existingIndex !== -1) {
            const updatedEmojis = [...lastUsedEmojis];
            updatedEmojis.splice(existingIndex, 1);
            updatedEmojis.unshift(clickedEmoji);
            setLastUsedEmojis(updatedEmojis);
            } else {
            // Si l'emoji n'est pas présent, l'ajoute en tête du tableau
            const updatedEmojis = [clickedEmoji, ...lastUsedEmojis.slice(0, 8)];
            setLastUsedEmojis(updatedEmojis);
            }
        }

        if (textareaRef2.current) {
          const textarea = textareaRef2.current;
          const { selectionStart, selectionEnd } = textarea;
          const newText = `${msgE.slice(0, selectionStart)}${emoji}${msgE.slice(selectionEnd)}`;
      
          setMsgE(newText);
      
          // Met à jour la position du curseur après l'insertion de l'emoji
          const newCursorPosition = selectionStart + emoji.length;
          textarea.setSelectionRange(newCursorPosition, newCursorPosition);
          textarea.focus();
        }
    };
      

    const handleEmojiModale = () => {

        // unsetActiveButtons();

        if(displayUploadFrame == true){
            setDisplayUploadFrame(false);
        }
        setActiveChatBtn(true);
        setActiveUploadBtn(false);
        setModaleEmoji(!modaleEmoji);
        setShowFilters(false);
    }
    

    useEffect(() => {
        console.log(activeParticipants);
    }, [activeParticipants]);



    const handleFilterMedia = () => {
        // faire apparaitre uniquement les messages media 
        setActiveTaggedMessagesBtn(false);
        setActiveMediaFilterBtn(!activeMediaFilterBtn);
        
    }
    
    const handleFilterTags = () => {
        // faire apparaitre uniquement les messages taggés
        setActiveMediaFilterBtn(false);
        setActiveTaggedMessagesBtn(!activeTaggedMessagesBtn);
    }

    const [countRaising,setCountRaising] = useState(0)

    useEffect(() => {
        // sur le panneau de participant si un participant leve sa main 
        const participantsRaisingHand = participants.filter(p => p.isRaisingHand == true);
        // si aucun participant leve la main 
        if(participantsRaisingHand.length == 0){
            setRaisingHandBox(false);
        } else {
            setRaisingHandBox(true);
            setCountRaising(participantsRaisingHand.length);
        }
    })

    const handleParticipantRequestBox = (value) => {
        setParticipantRequest(false);
        setIsRequestResponse(value);
        console.log(isRequestResponse)
    }

    const handleParticipantRequestContent = () => {
        setParticipantRequestContent(!participantRequestContent);
    }

    const checkParticipant = (rp, event) => {
        setSelectAll(false);
        setCheckedParticipant([...checkedParticipant, rp]);
    
        const checkbox = event.target.parentElement.children[0];
        const participantId = rp.id;
    
        setCheckedState((prevState) => ({
          ...prevState,
          [participantId]: !prevState[participantId],
        }));
      };

    const handleSelectAll = () => {
        const newCheckedState = {};

        if (!selectAll) {
            requestingParticipants.forEach((rp) => {
            newCheckedState[rp.id] = true;
            });
        }

        setCheckedState(newCheckedState);
        setSelectAll(!selectAll);
    };


    if(participants && participants.length === 0){
        alert('no participants');
        console.log('no particpant')
        return null;
    }

    
    function adjustTextareaHeight(e) {
        var textarea = e.currentTarget;
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
    }

    function removeFile(id) {
        setFiles(prevFiles => prevFiles.filter(file => file.id !== id));
    }

    return (
        <>

            <TopBarMobile/>
            <div className='devroom'>
                <div className='devroom__header'>
                    <BgPanel />
                </div>
                <div className='devroom__body'>

                    <div className='top-info-box'>
                        {raisingHandBox && (
                            <div className='someone-raising-hand'>
                                <img src={RaiseHand} alt="user raising hand"/>
                                <span>{countRaising}</span>
                            </div>
                        )}

                        {participantRequest && (
                            <div className='participant-request'>
                                <div className='participant-request__header' data-interactive={true} data-size="small">
                                    <div className='participant-request__header --left'>
                                        <img src={AddUserSvg} alt="add user" id="participant-add-user-icon"/>
                                        <h4>Participant requests</h4>
                                    </div>
                                    <img src={CrossSvg} alt="fermer" onClick={() => {handleParticipantRequestBox(false)}} />
                                </div>
                                <div className="participant-request__body">
                                    <div className='myselect'>
                                        <div className='myselect__legend'>
                                            <span>{requestingParticipants.length} guests would like to join the meeting.</span>
                                        </div>
                                        <div className='myselect__bar' onClick={handleParticipantRequestContent}>
                                            <div className='myselect__bar__header'>
                                                <div className='myselect__bar__content'>

                                                    <div className='myselect__bar__checkbox input-checkbox'>
                                                        <input 
                                                            type='checkbox'               
                                                            checked={selectAll}
                                                            onClick={handleSelectAll}
                                                        />
                                                    </div>
                                                    <div className='myselect__bar__title'>Select all</div>
                                                </div>
                                                <div className='myselect__bar__toggle-arrow' data-interactive={true} data-size="small">
                                                    <img src={arrowDown} alt="arrow down" className={participantRequestContent ? 'top' : 'down'}/>
                                                </div>
                                            </div>
                                            {participantRequestContent && (
                                                <div className='myselect__body' onClick={e => e.stopPropagation()}>
                                                    <ul>
                                                    {requestingParticipants.map((rp) => (
                                                        <li
                                                        key={rp.id}
                                                        className='myselect__body__option input-checkbox'
                                                        onClick={(event) => checkParticipant(rp, event)}
                                                        >
                                                        <input
                                                            type='checkbox'
                                                            checked={checkedState[rp.id] || false}
                                                            // Autre option pour définir l'état de la case à cocher
                                                            // checked={checkedState[rp.id] === undefined ? false : checkedState[rp.id]}
                                                        />
                                                        <span>{rp.username}</span>
                                                        </li>
                                                    ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='participant-request__footer'>
                                    <div className='btn-duo-frame'>
                                        <button className='btn-regular' onClick={() => {handleParticipantRequestBox(false)}}>Decline</button>
                                        <button className='btn-regular' onClick={() => {handleParticipantRequestBox(true)}}>Accept</button>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>


                    <div className={`devroom__body__main ${mode == 1 ? '--flex-layout' : '--grid-layout'}`}>
                    
                        <div className='featured-frame'>

                        </div>

                        <div className={`devroom__mosaic ${participants.length >= 24 ? '--twenty-four-participants' : ''} ${participants.length >= 20 ? '--twenty-participants' : ''} ${participants.length >= 12 ? '--twelve-participants' : ''} ${participants.length >= 9 ? '--nine-participants' : ''} ${participants.length <= 6 ? '--six-participants' : ''} ${participants.length === 4 ? '--four-participants' : ''} ${participants.length === 3 ? '--three-participants' : ''} ${participants.length === 2 ? '--two-participants' : ''} ${participants.length === 1 ? '--single-participant' : ''}`}>


                            {participants && participants.map((participant, index) => (
                                
                                    <div className={`devroom__mosaic__screen --medium ${participant.isSharing ? '--sharing' : ''} ${!participant.isMuted ? '--talking' : ''}`} key={index} onDoubleClick={(event) => handleFullScreen(event)(participant)} ref={(ref) => (screenRefs.current[index] = ref)}>
                                        {participant.portait ?
                                            <figure className='portrait'>
                                                <img src={participant.portait} alt='portrait' />
                                            </figure>
                                            :
                                            <div className='macaroon-box'>
                                                <div className='macaroon-box__frame' style={{ backgroundColor: participant.colorTag }}>
                                                    <span className='macaroon'>{participant.initials}</span>
                                                </div>
                                            </div>
                                        }
                                        <div className='devroom__mosaic__screen__overlay' data-size="large">
                                            {participant.isMuted ? (
                                                <img src={MicroMuteSvg} alt="micro"/>
                                            ) : (
                                                <img src={MicroBlueSvg} alt="micro"/>
                                            )}
                                        </div>
                                        <div className='devroom__mosaic__screen__overlay --bottom'>
                                            <div className='container'>
                                                <div className=''>
                                                    <div data-interactive={true}>
                                                        <img src={ThreeDotsSvg} alt="dots settings" onClick={(event) => handleScreenOptions(index, participant, event)} />
                                                        {renderOptionBox(index,participant)}
                                                    </div>
                                                    {participant.isPinned === true && (
                                                        <img src={PinTwoSvg} alt='user is pinned'/>
                                                    )}
                                                    {participant.isRaisingHand === true && (
                                                        <img src={RaiseHand} alt='raising hand'/>
                                                    )}
                                                    <span style={{cursor:'pointer'}} onClick={() => handleExternalBox(index)}>{participant.userName}</span>
                                                    
                                                </div>
                                                {participant.networkStatus === 'success' ? (
                                                    <img src={NetworkStatusDangerSvg} alt="network danger" />
                                                    ) : participant.networkStatus === 'warning' ? (
                                                    <img src={NetworkStatusWarningSvg} alt="network warning" />
                                                    ) : (
                                                    // Si aucune des conditions précédentes n'est remplie, afficher une image par défaut
                                                    <img src={NetworkStatusStableSvg} alt="network stable" />
                                                )}
                                            </div>

                                        </div>

                                        {participant.isExternal === true && mode == 0 && (
                                            <div className='test-cross' style={!isDevMode ? { visibility: 'hidden' } : {}}>
                                                <button onClick={() => removeThisParticipant(index)} data-interactive={true}>
                                                    <img src={TrashSvg} alt="delete"/>
                                                </button>
                                            </div>
                                        )}
                                        {renderExternalBox(index,participant)}

                                    </div>
                                
                            ))}
                        </div>

                    </div>

                    <div className='devroom__body__side-panel'>
                        
                        {sidePanel === "chat" ? (
                            <div className='chat-panel'>
                                <div className='chat-panel__heading'>
                                    <h2>Chat</h2>
                                    <div className='exit-frame' data-size='medium' data-interactive={true}>
                                        <img src={CrossSvg} alt='close' onClick={handlePanelState} />
                                    </div>
                                </div>
                                <div className='chat-panel__body'>
                                    <div className='chat-panel__searchbar'>
                                        <div className='chat-panel__searchbar__frame'>
                                        <Input
                                            className='input-frame input-searchbar'
                                            icon={SearchSvg}
                                            id='text_search_a_message'
                                            placeholder={'Search a message'}
                                        />
                                        <button className={`btn-square --filters ${showFilters ? 'active' : ''}`} data-size="large" data-interactive={true} onClick={handleDisplayFilters}>
                                            <img src={FilterSvg} alt='filters' />
                                        </button>
                                        </div>
                                        {showFilters && (
                                            <div className='chat-panel__option-panel'>
                                                <BtnIcon customClassName={`${(activeMediaFilterBtn == true) ? 'active' : ''}`} id="btn-spin" icon={PinSvg} data-size="medium" interactive={true} event={handleFilterMedia}/>
                                                <BtnIcon customClassName={`${(activeTaggedMessagesBtn == true) ? 'active' : ''}`} id="btn-spin" icon={StarSvg} data-size="medium" interactive={true} event={handleFilterTags}/>
                                            </div>
                                        )}
                                    </div>

                                    <div className='chat-panel__dialog-frame' ref={dialogFrameRef}>

                                        {messagesList.map((message, index) => (
                                            <div key={index}>{message}</div>
                                        ))}

                                        {/* <MessageBox 
                                            isSondage={true}
                                            portrait= {users[1].portrait}
                                            userName= {users[1].userName}
                                            asking={true}
                                            questionContent='Do you think we should change the process ?'
                                        />
                                        <MessageBox 
                                            isSondage={true}
                                            portrait= {users[1].portrait}
                                            userName= {users[1].userName}
                                            response={true}
                                            percentYes={54}
                                            percentNo={30}
                                            percentIdk={16}
                                            nbVotes={8}
                                            questionContent='Do you think we should change the process ?'
                                        /> */}



                                    </div>

                                    

                                    <div className='chat-panel__dialog-action'>
                                        <div className='input-frame'>
                                            <textarea
                                                ref={textareaRef2}
                                                className='external-box__textarea'
                                                placeholder='message externe'
                                                value={msgE}
                                                onChange={handleInputChangeE}
                                                onKeyDown={(e) => handleExternalInputChange(e, participants.find((e) => !e.isExternal), participants.find((e) => !e.isExternal).id)}
                                            ></textarea>
                                            <div className='overlay' data-interactive={true} data-size="small">

                                                {/* <img src={PinSvg} alt='add file' className={activeUploadBtn == true ? 'active' : ''} onClick={handleUpload}/> */}
                                                <img id="smiley" src={AddSmileySvg} onClick={handleEmojiModale} className={activeChatBtn == true ? 'active' : ''} alt='add smiley'/ >

                                                
                                                { modaleEmoji && (

                                                    <div className="emoji-library">
                                                        {/* new emoji library */}
                                                        <div className='emoji-library__grid'>
                                                        {emojis.map((emoji, index) => (
                                                            <Emoji
                                                            key={index}
                                                            symbol={emoji.symbol}
                                                            label={emoji.label}
                                                            onClick={() => handleEmojiClick(emoji.symbol)}
                                                            />
                                                        ))}
                                                        </div>
                                                        <div className='emoji-library__last-used'>
                                                            <span className='heading'>last used</span>
                                                            <div className='frame'>
                                                            {lastUsedEmojis.map((e,index) => (
                                                                <Emoji
                                                                    key={index}
                                                                    symbol={e.symbol}
                                                                    label={e.label}
                                                                    onClick={() => handleEmojiClick(e.symbol)}
                                                                />
                                                            ))}           
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                        {/* onClick={() => handleMessageParticipant(participant, index)} */}
                                        <button data-interactive={false} className='square-btn btn-square' onClick={() => handleMessageParticipant(participants[2], 2)}>
                                            <img src={SendArrowSvg} alt="send arrow"/>
                                        </button>

                                        {displayUploadFrame && (
                                            <div className='chat-panel__upload-frame'>
                                                <div className='chat-panel__upload-frame__body'>
                                                    <div className='upload-frame__header' data-interactive={true}>
                                                        <h4>Send a file</h4>
                                                        <img src={CrossSvg} alt="fermer" onClick={handleUpload}/>
                                                    </div>
                                                    <div className='upload-frame__content'>
                                                        <div className='upload-frame__content__top' data-size="x-large">
                                                            <img src={UploadSvg} alt="upload icon"/>
                                                            <h4>Drag and drop files</h4>
                                                        </div>
                                                        <div className='upload-frame__separator separator'><hr/></div>
                                                        <div className='upload-frame__content__bottom'>
                                                            <label htmlFor="file" className="label-file">Browse</label>
                                                            <input id="file" className="input-file" type="file" onClick={handleSendingBox}/>
                                                            <span>Maximum file size is 100MB</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {sendingFile && (
                                            <div className='chat-panel__sending-file'>
                                                <div className='chat-panel__sending-file__header' data-interactive={true}>
                                                    <h4>Send a file</h4>
                                                    <img src={CrossSvg} alt='close' onClick={handleSendingBox} />
                                                </div>
                                                <div className='chat-panel__sending-file__body'>
                                                    {files.map(file => (
                                                        <UploadProgress
                                                        key={file.id}
                                                        rate={file.rate}
                                                        filename={file.filename}
                                                        type={file.type}
                                                        remove={() => removeFile(file.id)}
                                                        />
                                                    ))}
                                                </div>

                                            </div>
                                        )}


                                    </div>

                                </div>
                            </div>
                        ) : sidePanel === "participants" ? (
                            <div className='participant-panel'>
                                <div className='participant-panel__heading'>
                                    <h2>Participants</h2>
                                    <div className='exit-frame' data-size='medium' data-interactive={true}>
                                        <img src={CrossSvg} alt='close' onClick={handlePanelState} />
                                    </div>
                                </div>
                                <div className='participant-panel__body'>

                                    <div className='search-frame searchframe'>
                                        <Input
                                            className='input-frame input-searchbar'
                                            icon={SearchSvg}
                                            id='text_search_a_participant'
                                            placeholder={'Search a participant'}
                                            // onValueChanged={searchUser}
                                        />
                                        <button className={participantOptionTab ? 'btn-square active' : 'btn-square'} data-size="square" data-interactive={true} onClick={handleParticipantOptionTab}>
                                            <img src={ThreeDotsSvg} alt='dot-settings'/> 
                                        </button>
                                    </div>

                                    {participantOptionTab && (
                                        <div className='participant-panel__frame'>
                                            <ul>
                                                <li>Download the participant list</li>
                                                <li>Add a participant</li>
                                                <li>Share the meeting link</li>
                                                <li>Mute everyone</li>
                                            </ul>
                                        </div>
                                    )}


                                    <ul className='participant-list'>
                                        {participants.map((participant, index) => (
                                            <li className={`participant-list__item ${!participant.isMuted ? ' --talking' : ''} ${participant.isSharing ? '--sharing' : ''}`} key={index}>
                                                <div className='participant-list__item__content'>
                                                    <div className='participant-list__item__avatar round-pill avatar'>
                                                        {participant.portait ? (
                                                        <img src={participant.portait} alt='portrait' className='portrait'/>
                                                        ) : <div className='macaroon' style={{ backgroundColor: participant.colorTag }}></div>}
                                                    </div>
                                                    <div className='participant-list__item__label'>
                                                        <p className='username'>{participant.userName}</p>
                                                    </div>
                                                </div>
                                                <div className='participant-list__item__options'>
                                                    <div className='option-frame'  data-interactive={true} data-size="small">
                                                        {participant.isSharing && (
                                                            <img src={ShareScreenActiveSvg} alt="share screen" className='opaque'/>
                                                        )}
                                                        {participant.isRaisingHand && (
                                                            <img src={RaiseHand} alt="raise hand" className='opaque'/>
                                                        )}
                                                        {participant.isPinned && (
                                                            <img src={PinTwoSvg} alt="pin" id="pinned"/>
                                                        )}
                                                        {participant.isMuted ? (
                                                            <img src={MicroMuteSvg} alt="micro"/>
                                                        ) : (
                                                            <img src={MicroBlueSvg} alt="micro" className='opaque'/>
                                                        )}

                                                        <img src={ThreeDotsSvg} alt="three dots" onClick={() => handleMoreOptions(index)}/>
                                                    </div>
                                                </div>
                                                {renderMoreOptions(index)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : sidePanel === 'statistics' ? (
                        <div className='stats-panel'>
                                <div className='stats-panel__heading'>
                                    <h2>Analytics</h2>
                                    <div className='exit-frame' data-size='medium' data-interactive={true}>
                                        <img src={CrossSvg} alt='close' onClick={handlePanelState} />
                                    </div>
                                </div>
                                <div className='stats-panel__body'>
                                    <Collapsible
                                        trigger='Speaking time breakdown'
                                        triggerTagName={'h4'}
                                        transitionTime={250}
                                        triggerStyle={{ cursor: 'pointer' }}
                                        open={true}
                                    >
                                        <div className='searchframe'>
                                            <Input
                                                className='input-frame input-searchbar'
                                                icon={SearchSvg}
                                                id='text_search_a_participant'
                                                placeholder={'Search participants'}
                                                // onValueChanged={searchUser}
                                            />
                                        </div>

                                        <div className='stats-panel__participant-list'>
                                            {/* participant list table */}
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <td>participants</td>
                                                        <td>speaking time</td>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    {participants && participants.map((participant, index) => (
                                                        <tr>
                                                            <td>{participant.userName}</td>
                                                            <td>{formatTime(participant.durationTalk)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>

                                    </Collapsible>
                                </div>
                            </div> 
                        ) : sidePanel === 'screen-layout' ? (
                            <div className='screen-layout-panel'>
                                <div className='screen-layout-panel__heading'>
                                    <h2>Screen Layout</h2>
                                    <div className='exit-frame' data-size='medium' data-interactive={true}>
                                        <img src={CrossSvg} alt='close' onClick={handlePanelState} />
                                    </div>
                                </div>
                                <div className='screen-layout-panel__body'>
                                    <ScreenLayout/>
                                </div>
                            </div>
                        ) : sidePanel === 'visual-effects' ? (
                            <div className='visual-effects-panel'>
                                <div className='visual-effects-panel__heading'>
                                    <h2>Visual effects</h2>
                                    <div className='exit-frame' data-size='medium' data-interactive={true}>
                                        <img src={CrossSvg} alt='close' onClick={handlePanelState} />
                                    </div>
                                </div>
                                <div className='visual-effects-panel__body'>
                                    <VisualEffectPanel/>
                                </div>
                            </div>
                        ) : null 
                        }


                    </div>

                    <RoomBottomPanel
                        addEvent={addParticipant}
                        removeEvent={removeParticipant}
                        participants={participants.length}
                        setSidePanel={setSidePanel}
                        allParticipants={participants}
                        updateParticipants={updateParticipants}
                        removeActive={removeActive}
                        updateMobileOptionsState={updateMobileOptionsState}
                        setMobileOptions={setMobileOptions}
                    />
                    


                </div>
            </div>
        </>
    );
};

export default DevRoom;



