import React, { useEffect, useState } from 'react';
import BtnIcon from './BtnIcon';
import InputLink from './InputLink';

import LinkSvg from '../assets/icons/link-icon.svg';
import KeyboardSvg from '../assets/icons/keyboard.svg';
import FilesSvg from '../assets/icons/files.svg';
import HubSvg from '../assets/icons/hub.svg';
import CrossSvg from '../assets/icons/cross.svg'

const MeetingInfoModale = ({ meetingModale, toggleMeetingModale }) => {


    const shortcuts = [
        {
            id:0,
            label:'Envoyer des réactions approuver',
            shortcode:'Alt + T',
        },
        {
            id:1,
            label:'Envoyer des réactions applaudir',
            shortcode:'Alt + C',
        },
        {
            id:2,
            label:'Envoyer des réactions rire',
            shortcode:'Alt + L',
        },
        {
            id:3,
            label:'Envoyer des réactions j\'aime!',
            shortcode:'Alt + O',
        },
        {
            id:4,
            label:'Envoyer des réactions huer',
            shortcode:'Alt + B',
        },
        {
            id:5,
            label:'Ouvrir le panneau de participant',
            shortcode:'Alt + P',
        },
        {
            id:6,
            label:'Ouvrir le panneau de chat',
            shortcode:'Alt + U',
        },
        {
            id:7,
            label:'Ouvrir le panneau de statistiques',
            shortcode:'Alt + S',
        },
        {
            id:8,
            label:'Afficher/Masquer la caméra',
            shortcode:'Alt + K',
        },
        {
            id:9,
            label:'Ouvrir/fermer le micro',
            shortcode:'Alt + M',
        },
        {
            id:10,
            label:'Fermer le volet droit',
            shortcode:'Alt + X',
        },
    ]



    // callback 

    const toggleModale = () => {
        toggleMeetingModale();
    };

    const [state,setState] = useState(1);

    const initActive = () => {
        let initActiveBtn = document.querySelector(".meeting-info-modale__option-tab .btn-frame button:nth-child(1)");
        if (initActiveBtn) {
            initActiveBtn.classList.add('active');
        }
    }

    const handleActive = (event) => {
        // recuperer les bouton
        let allBtns = document.querySelectorAll('.meeting-info-modale .btn-frame .btn-icon ');
        allBtns.forEach((e) => {
            if(e.classList.contains('active')){
                e.classList.remove('active');
            }
        })
        let tagName = event.tagName.toLowerCase();
        if(tagName === 'span' || tagName === 'img' ){
            event = event.parentElement;
            console.log(event);
        }
        event.classList.add('active');
    }


    const handleMeetingDetails = (e) => {
        setState(1);
        let domItem = e.target;
        handleActive(domItem);
    }
    
    const handleShortCuts = (e) => {
        setState(2)
        let domItem = e.target;
        handleActive(domItem);
    }



    const handleShorcut = (code) => {
        // keydown  ou  keypress
        // verifier qu'on est pas dans un input
    }


    useEffect(() => {
        initActive();
    },[meetingModale])

    return (
        <div className='meeting-info-modale'>
            <div className='meeting-info-modale__frame'>
                <div className='meeting-info-modale__header'>
                    <div className='meeting-info-modale__heading'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2 11.75C2 6.365 6.365 2 11.75 2C17.135 2 21.5 6.365 21.5 11.75C21.5 17.135 17.135 21.5 11.75 21.5C6.365 21.5 2 17.135 2 11.75ZM10.706 10.308C11.852 9.735 13.143 10.771 12.832 12.014L12.123 14.85L12.165 14.83C12.3412 14.7525 12.5405 14.7458 12.7215 14.8112C12.9026 14.8765 13.0516 15.009 13.1377 15.1812C13.2237 15.3533 13.2403 15.552 13.184 15.7361C13.1277 15.9202 13.0027 16.0755 12.835 16.17L12.795 16.192C11.648 16.765 10.357 15.729 10.668 14.486L11.378 11.65L11.336 11.67C11.2475 11.7192 11.15 11.7499 11.0494 11.7604C10.9487 11.7709 10.847 11.7609 10.7503 11.731C10.6536 11.7011 10.5639 11.652 10.4867 11.5866C10.4095 11.5211 10.3464 11.4407 10.301 11.3502C10.2557 11.2598 10.2292 11.161 10.2231 11.06C10.2169 10.959 10.2313 10.8578 10.2653 10.7624C10.2993 10.6671 10.3522 10.5797 10.4209 10.5053C10.4896 10.431 10.5726 10.3714 10.665 10.33L10.706 10.308V10.308ZM11.75 8.75C11.9489 8.75 12.1397 8.67098 12.2803 8.53033C12.421 8.38968 12.5 8.19891 12.5 8C12.5 7.80109 12.421 7.61032 12.2803 7.46967C12.1397 7.32902 11.9489 7.25 11.75 7.25C11.5511 7.25 11.3603 7.32902 11.2197 7.46967C11.079 7.61032 11 7.80109 11 8C11 8.19891 11.079 8.38968 11.2197 8.53033C11.3603 8.67098 11.5511 8.75 11.75 8.75Z" fill="white" fillOpacity="1"/>
                        </svg>
                        <h2>Meeting info</h2>
                    </div>
                    <div data-interactive={true} className='meeting-info-modale__close'>
                        <img src={CrossSvg} alt='fermer' onClick={toggleModale}/>
                    </div>
                </div>
                <div className='meeting-info-modale__body duo-tab'>
                    <div className='meeting-info-modale__option-tab duo-tab__option'>
                        <div className='btn-frame' data-size={'large'}>
                            <BtnIcon
                                content='Meeting details'
                                icon={LinkSvg}
                                event={handleMeetingDetails}
                            />
                            <BtnIcon
                                content='Shortcuts'
                                icon={KeyboardSvg}
                                event={handleShortCuts}
                            />
                        </div>
                    </div>
                    <div className='meeting-info-modale__content-tab duo-tab__content'>
                        {state === 1 && (
                            <div className='meeting-details-box'>
                                <div className='meeting-details-box__frame'>
                                    <div className='meeting-details-box__container'>
                                        <div className='meeting-details-box__container__heading'>
                                            <h4>Your meeting room link</h4>
                                        </div>
                                        <div className='meeting-details-box__container__content'>
                                            <InputLink placeholder='https://meet.ringover.io/uvWCw55642'/>
                                        </div>
                                    </div>
                                    <div className='meeting-details-box__container'>
                                        <div className='meeting-details-box__container__heading'>
                                            <h4>Embed the meet code</h4>
                                        </div>
                                        <div className='meeting-details-box__container__content'>
                                            <div className='input-frame input-link input-textarea'>
                                                <textarea readOnly className='input-frame__textarea' rows={5}>
                                                iframe allow="camera; microphone; fullscreen; display-capture; autoplay" src="https://meet.ringover.io/lmKHg89189" style="height: 100%; width: 100%; border: 0px;"
                                                </textarea>
                                                <div className='icon-frame' data-interactive={true}>
                                                    <img src={FilesSvg} alt="file" />
                                                    <img src={HubSvg} alt="share" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}  
                        {state === 2 && (
                            <div className='shortcuts-box'>
                                <div className='shortcuts-box__frame'>
                                    <ul className='shortcuts-box__list'>

                                    {shortcuts.map((e) => (
                                        <li className='shortcuts-box__item'>
                                            <span>{e.label}</span>
                                            <span>{e.shortcode}</span>
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                            </div>
                        )}  
                    </div>
                </div>
                <div className='meeting-info-modale__footer'>
                    <div className='btn-frame'>
                        <button className='btn-regular' onClick={toggleModale}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MeetingInfoModale;