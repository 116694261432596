import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const CameraType={
  padj:"test",
}

// export const fetchMeetList = createAsyncThunk('/fetchMeetlist', async () => {
// 	const { status, data } = await apiCall.get(`${API_URL}/tasks`);
// 	switch (status) {
// 		case 200:
// 			return { message: 'Fetch TasksList success', data };
// 		case 204:
// 			return { message: 'Fetch TasksList empty', data: [] };
// 		default:
// 			return { message: 'Fetch TasksList failed: Something went wrong' };
// 	}
// });

export const meetSlice = createSlice({
	name: 'meet',
	initialState: {
		name: '',
		uuid: '',
		list: [],
		config: {
			camera: CameraType.padj,
		},
	},
	reducers: {
		setMeetName: (state, action) => {
			return {
				...state,
				name: action.payload 
			};
		},
		setMeetUUID: (state, action) => {
			return {
				...state,
				uuid: action.payload
			};
		},
		setCamera: (state, action) => {
			return {
				...state,
				config: {...state.config, camera: action.payload}
			}
		},
		setMeetlist: (state, action) => {
			return {
				...state,
				list: [...action.payload],
			};
		},
		addMeetToList: (state, action) => {
			const list = state.list;
			return {
				...state,
				list: [...list, action.payload],
			};
		},
		removeMeetFromList: (state, action) => {
			const list = state.list.filter((item) => item.uuid !== action.payload);
			return {
				...state,
				list: [...list],
			};
		},
	},
});
export const meetReducer = meetSlice.reducer;
export const {
	setMeetName,
	setMeetUUID,
	setMeetlist,
	setCamera,
	addMeetToList,
	removeMeetFromList,
} = meetSlice.actions;
export const getMeetName = (state) => state.meet.name ?? '';
export const getMeetUUID = (state) => state.meet.uuid ?? '';
export const getMeetList = (state) => state.meet.list ?? [];
export const getCamera = (state) => state.meet.config.camera ?? CameraType.padj;
