// hideModalOnWindowMouseDown.js

export const closeModalOnClickOutside = (selector, container) => {
  window.addEventListener('mousedown', (event) => {
    if (event.target.closest(container) !== null) {
    return;
    } else {

      const modal = document.querySelector(selector);
      if (modal) {
        modal.classList.remove('show');
      }
    
    }
  });
}