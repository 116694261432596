import React, { useEffect, useRef, useState } from 'react';
import BgPanel from '../components/BgPanel';
import Navbar from '../components/Navbar';
import BtnIcon from '../components/BtnIcon';
import ArrowBackSvg from '../assets/icons/arrow-back.svg';
import Input from '../components/Input';
import KeyboardSvg from '../assets/icons/keyboard.svg';
import BtnRegular from '../components/BtnRegular';
import LogotypeSvg from '../assets/icons/logotype-prejoin.svg';


import RegularVolumeSvg from '../assets/icons/regular-volume.svg';
import MediumVolumeSvg from '../assets/icons/medium-volume.svg';
import NoVolumeSvg from '../assets/icons/no-volume.svg';
import HighVolumeSvg from '../assets/icons/high-volume.svg';

import CheckGreenSvg from '../assets/icons/check-green.svg';
import CrossRedSvg from '../assets/icons/cross-red.svg';
import StaggeredAnimation from '../components/StaggeredAnimation.js';

import { config } from '../data/prejoinConfig';
import { useNavigate } from 'react-router-dom';

import PrejoinCamera from '../components/PrejoinCamera';
import PreviewSettingsModal from '../components/PreviewSettingsModal';
import InputCheckbox from '../components/InputCheckbox';



const PreJoin = () => {

    // algo entrée dans la room 

    // handleclick 
    // quand on clique
    // display user sur right panel
    // display le compteur
    // display le loader 
    // au bout de 10 secondes -> fonction entercode  


    // fonction entercode 
    // affiche demande approuvée 
    // loader disparait 
    // message compteur vide 
    // affiche codeframe 

    // handlechange input code (handlesuccess)
    // afficher message succes - redirection imminente 


    const inputRefs = [useRef(), useRef(), useRef(), useRef()];
    const navigate = useNavigate();

    const [{ username, greetingMessage, message, notification, checkMessage }, setConfig] = useState(config[0]);
    const [formFrame, setFormFrame] = useState(true);
    const [typeCodeFrame, setTypeCodeFrame] = useState(false);
    const [correctPassword, setCorrectPassword] = useState(null);
    const [setUserVisibility] = useState(true);

    const [logotype, setLogotype] = useState(null);

    const handleChange = (event, inputIndex) => {
        const value = event.target.value;
        if (value !== "") {
          if (inputIndex < inputRefs.length - 1) {
            inputRefs[inputIndex + 1].current.focus();
          } else {
            setCorrectPassword(true);
            setLogotype(true);
            setConfig(config[3]);
          }
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        setUserVisibility(false);
        setConfig(config[1]);
        setLogotype(true);
        setFormFrame(false);
        setTimeout(() => {
            handleRandomMacaroonColor();
        }, 100);
        setTimeout(() => {
            handleApproval();
        },5000)

    }


    const handleApproval = () => {
        setConfig(config[2]);
        setLogotype(false);
        setTypeCodeFrame(true);
    }

    const handleAnim = () => {
        const arrayImage = [NoVolumeSvg, RegularVolumeSvg, MediumVolumeSvg, HighVolumeSvg];
        const img = document.querySelector('.option-layer img');
    
        let index = 0;
        
        const imageClasses = [
            { index: 0, classList: ['no'] },
            { index: 1, classList: ['regular'] },
            { index: 2, classList: ['medium'] },
            { index: 3, classList: ['high'] },
        ];
    
        const handleAnim = () => {
            if(img){
                img.src = arrayImage[index];
                index = (index + 1) % arrayImage.length;
            
                // Parcourir le tableau d'objets pour trouver les classes correspondantes
                const matchingClasses = imageClasses.find((item) => item.index === index);
            
                // Ajouter les classes CSS correspondantes et supprimer les autres classes
                if (matchingClasses) {
                    img.parentElement.classList.add(...matchingClasses.classList);
                    img.parentElement.classList.remove(...imageClasses.filter((item) => item.index !== index).flatMap((item) => item.classList));
                }
            }
            
        };
      
        setInterval(handleAnim, 500);
    };

    const handleRandomMacaroonColor = () => {
        let colorArray = ['tomato', 'goldenrod', 'royalblue', 'olive','silver','violet'];
        let macaroon = document.querySelector('.macaroon-box__frame');
        let randomIndex = Math.floor(Math.random() * colorArray.length);
        let randomColor = colorArray[randomIndex];
        macaroon.style.backgroundColor = randomColor;
        
        // generate two random letters from the alphabet
        let letters = 'abcdefghijklmnopqrstuvwxyz';
        let randomLetter1 = letters[Math.floor(Math.random() * letters.length)];
        let randomLetter2 = letters[Math.floor(Math.random() * letters.length)];
      
        // set the text content of the <span> element to the random letters
        
        let spanElement = macaroon.querySelector('.macaroon-box__frame span');
        let spanElementTwo = macaroon.querySelector('.macaroon-box__frame span:nth-child(2)');
        if(spanElement && spanElementTwo){
            spanElement.textContent = randomLetter1;
            spanElementTwo.textContent = randomLetter2;
            console.log(`Color: ${randomColor}, Letters: ${randomLetter1}${randomLetter2}`);
        }
      
    }


    useEffect(() => {

        const prejoinAnim = new StaggeredAnimation(
			'.prejoin__body__container > div',
			100,
			'negY',
			1.25,
			'ease',
			250
		);


		prejoinAnim.animate();

        handleAnim();

        
        fetch(`https://api-eu.dev137.scw.ringover.net/v4/meet/public/rooms/${localStorage.getItem('meet_name_auth')}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + localStorage.getItem('id_token')
			},
		}).then((response) => {
			response.json().then((responseJs) => {
                console.log(responseJs);
			});
		}).catch((error) => console.error(error));


    }, []);

    
    const handleNavigate = () => {
		navigate('/');
	}



    return (
		<div className='prejoin'>
			<div className='prejoin__header'>
				<BgPanel />
			</div>
			<div className='prejoin__body'>
				<Navbar />
                <div className='greetings-box__mobile'>
                    <h2>welcome <span>{username}</span> !</h2>
                    {/* greetings notification  */}
                    <p>{greetingMessage}</p>
                </div>
				<div className='prejoin__body__container'>
                    <div className='prejoin__left-panel scroll-bar'>
                        <div className='prejoin__left-panel__header'>
                            <div className='btn-frame'>
                                <BtnIcon
                                    content='Back Home'
                                    icon={ArrowBackSvg}
                                    event={handleNavigate}
                                />
                            </div>
                        </div>
                        <div className='prejoin__left-panel__body'>
                            {/* greetings  */}
                            <div className='greetings-box'>
                                <h2>welcome <span>{username}</span> !</h2>
                                {/* greetings notification  */}
                                <p>{greetingMessage}</p>
                            </div>

                            {/* logotype box  */}
                            {logotype && (
                                <div className='logo-box'>
                                    <img src={LogotypeSvg} alt="logotype prejoin"/>
                                </div>
                            )}

                            {/* notification box  */}
                            <div className='notif-box'>
                                <div className='notif-box__header'>
                                    {/* notification heading  */}
                                    <p>{message}</p>
                                    {/* notification description (legend ) */}
                                    <p className='legend'>{notification}</p>
                                </div>
                                <div className='notif-box__body'>
                                    {/* form  */}
                                    <div className='form-frame'>
                                        <form className='form'>
                                            <div className='form__header'>
                                                <InputCheckbox content={checkMessage} htmlFor='join-auto' name="join-auto" id="join-auto"/>
                                            </div>
                                            {formFrame && (
                                                <div className='form__body'>
                                                    <Input
                                                        className='input-frame'
                                                        icon={KeyboardSvg}
                                                        id='text_meeting_name'
                                                        // defaultValue='meetname'
                                                        placeholder='Type a name'
                                                        // onValueChanged={handleInputValueChange}
                                                    />
                                                    <BtnRegular className="btn-regular" content='send a request' event={handleClick}/>
                                                </div>
                                            )
                                            }

                                            <div className='form__footer'>
                                                {typeCodeFrame ? (
                                                    <div className='typecode-frame'>
                                                        <div className='typecode-frame__body'>
                                                            <div className='box'>
                                                                <input type='number' min="0" max="9" maxlength="1" onChange={(event) => handleChange(event, 0)} ref={inputRefs[0]} />
                                                                <input type='number' min="0" max="9" maxlength="1" onChange={(event) => handleChange(event, 1)} ref={inputRefs[1]} />
                                                                <input type='number' min="0" max="9" maxlength="1" onChange={(event) => handleChange(event, 2)} ref={inputRefs[2]} />
                                                                <input type='number' min="0" max="9" maxlength="1" onChange={(event) => handleChange(event, 3)} ref={inputRefs[3]} />
                                                            </div>
                                                        </div>
                                                        <div className='typecode-frame__footer'>
                                                            {correctPassword ? (
                                                                <div className='notif-frame success'>
                                                                    <img src={CheckGreenSvg} alt="check success"/>
                                                                    <p>Correct password</p>
                                                                </div>
                                                            ) :    
                                                                <div className='notif-frame danger'>
                                                                    <img src={CrossRedSvg} alt="cross danger"/>
                                                                    <p>Incorrect password</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                ) : (<></>)}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PrejoinCamera/>
				</div>
			</div>
            <PreviewSettingsModal/>
		</div>
    );
};

export default PreJoin;