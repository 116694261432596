import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setMeetName, setMeetUUID, getMeetName, getMeetUUID as getMeetUUIDFromRedux, getMeetUUID } from '../redux/meetSlice';

// import * as mlHelpers from '../helpers/meetlist';
import SquareBtn from './SquareBtn';
import CrossSvg from '../assets/icons/cross.svg';
import Collapsible from 'react-collapsible';
import LinkSvg from '../assets/icons/link-icon.svg';
import FilesSvg from '../assets/icons/files.svg';
import HubSvg from '../assets/icons/hub.svg';
import OneFileSvg from '../assets/icons/one-file.svg';
import CalendarSvg from '../assets/icons/calendar.svg';
import AddUserSvg from '../assets/icons/add-user-icon.svg';
import UserBook from '../assets/icons/user-book.svg';
import LockerSvg from '../assets/icons/locker.svg';
import KeySvg from '../assets/icons/key.svg';
import TimeZoneSvg from '../assets/icons/time-zone.svg';
import BtnRegular from "./BtnRegular";
import Input from "./Input";
import TableRowParticipant from "./TableRowParticipant";
import useMediaQuery from '../hooks/useMediaQuery';
import ContactsModal from "./ContactsModal";
import MeetCreatedModal from "./MeetCreatedModal";
import InputSelect from "./InputSelect";
import ShareModal from "./ShareModal";
import useAuthContext from '../hooks/useAuth';
import MyDatePicker from "./DatePicker";
import { closeModalOnClickOutside } from "../utils/closeModalOnClickOutside";

import * as utils from '../utils';

const ModaleSetup = (props) => {

	const isDesktopOrLaptop = useMediaQuery('(min-width: 800px)');
	const [isContactsModalOpen, setIsContactsModalOpen] = useState(false);
	const [linkFromStorage, setLinkFromStorage] = useState(localStorage.getItem('meet_name_auth'));
	const [meetLink, setMeetLink] = useState('');
	const [clipboard, setClipboard] = useState(false);
	const [participants, setParticipants] = useState([]);
	const [guestName, setGuestName] = useState('');
	const [userAvatarLogo, setUserAvatarLogo] = useState(null);
	const { userAvatarPath } = useAuthContext();

	const meetName = useSelector(getMeetName);
	const meetUUID = useSelector(getMeetUUID);

	const dispatch = useDispatch();

	const {userEmail, setMeetNameAuth, meetNameAuth, socket, userUUID, userName } = useAuthContext();

	const baseURL = 'https://meet.ringover.io/';

	const [isAuth, setAuth] = useState(false);
	const childComponentRef = useRef(null);
	const [needPassword, setNeedPassword] = useState(false);
	const [needWaitHost, setNeedWaitHost] = useState(false);
	const [needRequest, setNeedRequest] = useState(false);



	useEffect(() => {
		const isAuthenticated =
			localStorage.getItem('id_token') &&
			localStorage.getItem('access_token') &&
			localStorage.getItem('refresh_token');

		setAuth(isAuthenticated);

		caches.match(userAvatarPath).then(response => {
			if (response) {
				return response.blob();
			} else {
				throw new Error('Image d\'avatar introuvable dans le cache');
			}
		})
			.then(blob => {
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					const base64data = reader.result;	
					setUserAvatarLogo(base64data);
					if (userEmail != null) {
						const defaultParticipant = {
							userName: userEmail,
							role: 'host',
							type: 'internal',
							picture: base64data ? base64data : null
						};
						setParticipants([...participants, defaultParticipant]);
					}
				};
			}).catch(error => console.log(error))

		// let modale = document.querySelector('.modale-setup');
		// if (modale) {
		// 	closeModalOnClickOutside('.meet-created-modal', '.meet-created-modal__container');
		// 	closeModalOnClickOutside('.share-modal', '.share-modal__container');
		// }
			console.log('show sssss')
	}, []);

	useEffect(() => {
		// const linkFromStorage = localStorage.getItem("meet_name_auth"); === meetNameAuth
		if (isAuth) {
			if (meetNameAuth) {
				setMeetLink(`${baseURL}${meetNameAuth}`);
			} else {
				const randomLink = utils.generateMeetName();
				setMeetLink(`${baseURL}${randomLink}`);
				dispatch(setMeetName(randomLink));
				dispatch(setMeetUUID(utils.generateMeetUUID()));
			}
		} else {
			const randomLink = utils.generateMeetName();
			setMeetLink(`${baseURL}${randomLink}`);

			dispatch(setMeetName(randomLink));
			dispatch(setMeetUUID(utils.generateMeetUUID()));
		}
	}, []);

	const handleInputChange = (event) => {
		const newLink = event.target.value;
		if (newLink.startsWith(baseURL)) {
			setMeetLink(newLink);
		}
		
		const newName = meetLink.replace(baseURL, '');
		dispatch(setMeetName(newName));
		if (isAuth) {
			setMeetNameAuth(newName);
		}

		const meetUUID = utils.generateMeetUUID();
		dispatch(setMeetUUID(meetUUID));

	};

	const handleInputBlur = () => {
		let newLink = '';
		if (meetLink === baseURL) {
			if (isAuth) {
				newLink =`${baseURL}${meetNameAuth}`;
			} else {
				
				if (!meetName && !meetUUID) {
					const newMeetName = utils.generateMeetName();

					dispatch(setMeetName(newMeetName));
					dispatch(setMeetUUID(utils.generateMeetUUID()));
					newLink = `${baseURL}${newMeetName}`;
				} else {
					newLink = `${baseURL}${meetName}`;
				}
				
			}
			setMeetLink(newLink);	
		} 
	};


	const [showSelect1, setShowSelect1] = useState(false);
	const [showSelect2, setShowSelect2] = useState(false);

	const handleSelect1Click = () => {
		setShowSelect1(!showSelect1);
		setShowSelect2(false);
	}

	const handleSelect2Click = () => {
		setShowSelect2(!showSelect2);
		setShowSelect1(false);
	}

	const toggleNeedWaitHost = () => {
		setNeedWaitHost(!needWaitHost);
	}
	const toggleNeedPassword = () => {
		setNeedPassword(!needPassword);
	}
	const toggleNeedRequest = () => {
		setNeedRequest(!needRequest);
	}
	const handleCross = (e) => {
		const modalElement = document.querySelector('.modale-setup');
		modalElement.classList.toggle('show');
	}

	function openContactsModal(e) {
		e.preventDefault();
		setIsContactsModalOpen(true);
	}

	function closeContactsModal() {
		setIsContactsModalOpen(false);
	}

	const handleSubmitMeet = () => {
		let meetCreatedModal = document.querySelector('.meet-created-modal');
		if (meetCreatedModal) {
			meetCreatedModal.classList.add('show');
		}
		
		// const user = { userEmail, userName };
		const config = {
			needPassword,
			password: '1234', //par default password
			needWaitHost,
			needRequest,
			host: {userEmail, userUUID},// update host
			animator: {userEmail, userUUID},//update animator
			defaultParticipant: {}
		}
		
		if (!meetUUID && !meetName) {
			const newMeetName = Math.random().toString(36).substr(2, 8);
			const newMeetUUID = utils.generateMeetUUID();
			dispatch(setMeetName(newMeetName));
			dispatch(setMeetUUID(newMeetUUID));
			if (socket) {
				socket.open();
				socket.emit('create', {
					uuid: newMeetUUID,
					name: newMeetName,
					config
				});
			}
		}
		else {
			if (socket) {
				socket.open();
				socket.emit('create', {
					uuid: meetUUID,
					name: meetName,
					config
				});
			}
		}
		
	}

	const handleShareModal = () => {
		let shareModal = document.querySelector('.share-modal');
		let container = document.querySelector('.share-modal__container');
		let stroke = document.querySelector('.share-modal__separator hr');
		if (shareModal) {
			shareModal.classList.add('show');
			container.style.transform = 'translateY(0%)';
			stroke.classList.remove('bright-stroke');
		}
	}

	const handleCopyLinkModal = () => {
		const copyText = document.getElementById("text_meet_link");
		copyText.select();
		document.execCommand("copy");
	}

	const handleAddGuest = () => {
		const emailRegExp = /^\S+@\S+\.\S+$/;
		if (!emailRegExp.test(guestName)) {
			console.log('Guest email address is not valid.');
			return;
		}
		const newParticipant = {
			userName: guestName,
			role: 'guest',
			type: 'external'
		};
		setParticipants([...participants, newParticipant]);
		setGuestName('');
	};

	const handleInputValueChange = (e) => {
		setGuestName(e.target.value);
	};

	const addParticipants = (newParticipants) => {
		const updatedParticipants = newParticipants.map((participant) => {
			const newParticipant = {
				id: participant.id,
				userName: participant.givenName + ' ' + participant.familyName,
				initial: participant.initial,
				role: 'guest',
				type: 'external'
			};
			return newParticipant;
		});
		setParticipants([...participants, ...updatedParticipants]);
	};


	const handleSelectedDate = () => {
		let selectedDate = childComponentRef.current.children[0].date;
		let span = document.querySelector('.input-box:nth-child(2) .input-box__value span span');
		let selectedHour = span.getAttribute('value');
		if (selectedDate) {
			console.log(selectedDate);
			if (selectedHour) {
				console.log(selectedHour);
			} else {
				console.log('pas d\'heure selectionnée');
			}
		} else {
			console.log('pas de date selectionnée');
		}
	}


	const options = Array.from({ length: 24 }, (_, i) => {
		const hour = i.toString().padStart(2, '0');
		return <span key={hour} value={hour}>{hour}:00</span>;
	});


	const durations = Array.from({ length: 16 }, (_, i) => {
		const duration = (i + 1) * 15;
		return (<span key={duration} value={duration}>{duration} min</span>
		);
	});



	return (
		<>
			{isContactsModalOpen && (
				<ContactsModal
					isModalOpen={isContactsModalOpen}
					closeModal={closeContactsModal}
					addParticipants={addParticipants}
				/>
			)}



			<div className='modale-setup'>
				<div className='modale-setup__body'>

					<div className="modale-setup__body__main-frame">

						<div className='modale-setup__body__heading'>
							<h2 id='text_set_up_meet'>Schedule your meeting</h2>

							<div className='exit-frame' onClick={props.hiddenModal} data-size='medium' data-interactive={true}>
								{isDesktopOrLaptop ? (
									<img src={CrossSvg} alt='close' />
								) : (
									<button className='btn-regular'>Close</button>
								)}
							</div>
						</div>

						<div className="modale-setup__accordeon__frame">

							<div className='modale-setup__body__frame'>
								<div className='frame'>
									<div className='frame__heading'>
										<Collapsible
											trigger='General Settings'
											triggerTagName={'h3'}
											transitionTime={250}
											triggerStyle={{ cursor: 'pointer' }}>
											<div className='basic-settings'>

												{/* user authenticated form  */}
												{isAuth ? (

													<div className='form'>
														<div className='form__row'>
															<div
																className='form__row__icon'
																data-size='medium'>
																<img
																	src={LinkSvg}
																	alt='linkSvg'
																/>
															</div>

															<div className='input-frame'>
																<input
																	id="text_meet_link"
																	className="input-frame__input"
																	placeholder={meetLink}
																	value={meetLink}
																	onChange={handleInputChange}
																	onBlur={handleInputBlur}
																/>
																<div
																	className='icon-frame'
																	data-size='small'
																	data-interactive={true}>

																	<img
																		src={FilesSvg}
																		alt='FilesSvg'
																		onClick={handleCopyLinkModal}
																	/>
																	<img
																		src={HubSvg}
																		alt='HubSvg'
																		onClick={handleShareModal}
																	/>
																</div>
															</div>
														</div>

														<div className='form__row'>
															<div
																className='form__row__icon'
																data-size='medium'>
																<img
																	src={OneFileSvg}
																	alt=''
																/>
															</div>
															<div className='input-frame'>
																<textarea id="text_add_a_description" rows={6} placeholder="Add a description"></textarea>
															</div>
														</div>

														<div className='form__row'>
															<div
																className='form__row__icon'
																data-size='medium'>
																<img
																	src={CalendarSvg}
																	alt=''
																/>
															</div>

															<div className='grid'>
																<div className='grid__row'>
																	<div className='form__row__block'>
																		<MyDatePicker ref={childComponentRef} />
																		<InputSelect
																			options={options}
																			defaultOption='Select hour'
																			showOptions={showSelect1}
																			handleValClick={handleSelect1Click}
																		/>
																		<SquareBtn
																			icon={TimeZoneSvg}
																			event={handleSelectedDate}
																		/>
																		<p>Duration</p>
																		<InputSelect
																			options={durations}
																			defaultOption="15"
																			showOptions={showSelect2}
																			handleValClick={handleSelect2Click}
																		/>
																	</div>
																</div>
															</div>
														</div>

														<div className='form__row'>
															<div
																className='form__row__icon'
																data-size='medium'>
																<img
																	src={AddUserSvg}
																	alt=''
																/>
															</div>
															<div className='input-frame'>
																<Input
																	className=''
																	placeholder='Add guest (email)'
																	data-size='medium'
																	defaultValue={guestName}
																	onValueChanged={handleInputValueChange}
																	onKeyPress={(e) => {
																		if (e.key === 'Enter') {
																			handleAddGuest();
																		}
																	}}
																/>
																<SquareBtn
																	icon={UserBook}
																	event={openContactsModal}
																/>
															</div>
														</div>
													</div>
												) : (

													<div className="form">
														<div className='form__row'>
															<div
																className='form__row__icon'
																data-size='medium'>
																<img
																	src={LinkSvg}
																	alt=''
																/>
															</div>

															<div className='input-frame'>
																<input
																	id="text_meet_link"
																	className="input-frame__input"
																	placeholder={meetLink}
																	value={meetLink}
																	onChange={handleInputChange}
																	onBlur={handleInputBlur}
																/>
																<div
																	className='icon-frame'
																	data-size='small'
																	data-interactive={true}>
																	<img
																		src={FilesSvg}
																		alt=''
																	/>
																	<img
																		src={HubSvg}
																		alt=''
																		onClick={handleShareModal}
																	/>
																</div>
															</div>
														</div>
														<div className='form__row form__row__gap'>
															<div
																className='form__row__icon'
																data-size='medium'>
																<img
																	src={AddUserSvg}
																	alt=''
																/>
															</div>
															<div className='input-frame'>
																<Input
																	className=''
																	placeholder='Add guest'
																	data-size='medium'
																/>
																<SquareBtn
																	icon={UserBook}
																	event={openContactsModal}
																/>
															</div>
														</div>
													</div>

												)
												}
												{isAuth ? (
													<>
														<table>
															<thead>
																<tr>
																	<th></th>
																	<th>Host</th>
																	<th>Animator</th>
																</tr>
															</thead>
															<tbody>
																{participants && participants.length > 0 && participants.map((participant, index) => (
																	<TableRowParticipant
																		key={index}
																		authenticated
																		portraitPicture={participant.picture}
																		userName={participant.userName}
																		participantRole={participant.role}
																		type={participant.type}
																	/>
																))}
															</tbody>
														</table>
														<div className='form'>
															<div className='form__row' id="guest-row">
																<div className='toggle-box'>
																	<input
																		type='checkbox'
																		id='darkmodeToggle'
																		onChange={toggleNeedWaitHost}
																	/>
																	<label htmlFor='darkmodeToggle'></label>
																</div>
																<div>
																	<p id="text_guests_will_wait">Guest(s) will wait in a waiting room until the administrator arrives</p>
																</div>
															</div>
														</div>
													</>
												) : (
													<>
														<table>
															<tbody>
																<TableRowParticipant
																// portraitPicture={Portrait}
																// userName='Sophie Marshall'
																// isHost
																// isAnimator
																/>
															</tbody>
														</table>
													</>
												)
												}
											</div>
										</Collapsible>
									</div>
								</div>
							</div>

							{isAuth ? (
								<div className='modale-setup__body__frame'>
									<Collapsible
										trigger='Advanced Settings'
										triggerTagName={'h3'}
										transitionTime={250}
										triggerStyle={{ cursor: 'pointer' }}>
										<div className='advanced-settings'>
											<div className='form'>
												<div className='form__row'>
													<div
														className='form__row__icon'
														data-size='medium'>
														<img
															src={LockerSvg}
															alt=''
														/>
													</div>
													<div className='input__frame'>
														<div className='content'>
															<h4>Add a password</h4>
															<p>
																Participants will need to provide a password to join
																the meeting.
															</p>
														</div>
														<div className='option'>
															<div className='toggle-box'>
																<input
																	type='checkbox'
																	id='addPassword'
																	onChange={toggleNeedPassword}
																/>
																<label htmlFor='addPassword'></label>
															</div>
														</div>
													</div>
												</div>
												<div className='form__row'>
													<div
														className='form__row__icon'
														data-size='medium'>
														<img
															src={KeySvg}
															alt=''
														/>
													</div>
													<div className='input__frame'>
														<div className='content'>
															<h4>Closed Mode</h4>
															<p>
																Only allow people to enter after a formal approval
																by a moderator.
															</p>
														</div>
														<div className='option'>
															<div className='toggle-box'>
																<input
																	type='checkbox'
																	id='closedMode'
																	onChange={toggleNeedRequest}
																/>
																<label htmlFor='closedMode'></label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Collapsible>
								</div>
							) : (
								<></>
							)}

						</div>


					</div>

					<div className='modale-setup__footer__frame'>
						<div className='frame__heading'>
							<div className='btn-frame'>
								{/* <BtnRegular content='Create a meet for later' /> */}
								<BtnRegular
									className="btn-regular create-meeting"
									content='Create meeting'
									event={handleSubmitMeet}
								/>
							</div>
						</div>
					</div>

				</div>
			</div>

			<MeetCreatedModal event={handleSubmitMeet} />
			<ShareModal event={handleShareModal} />
		</>
	);
};

export default ModaleSetup;
