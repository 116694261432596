import React from 'react';
import useAuthContext from '../hooks/useAuth';
import BtnRegular from './BtnRegular';

const UserModal = (props) => {
    const { userAvatarLogo,closeModal, disconnect } = props;
    const { userEmail, userName, jobTitle } = useAuthContext();
    return (
        <>
            <div className="overlay-user-modal" onClick={closeModal} />
            
            <div className='user-modal'>
                <div className="user-modal__avatar" >
                    <img src={userAvatarLogo} alt="portrait" />
                </div>
                <div className='user-modal__info'>
                    <h4>{userName}</h4>
                    <p>{jobTitle}</p>
                    <span>Connected with {userEmail}</span>
                </div>
                <BtnRegular content='Disconnect' event={disconnect} />
                <span>Need help on Ringover ? Call +44 20 3808 5555</span>
            </div>
        </> 
	);
}
export default UserModal

