import React, { useRef, useState } from 'react';
import BtnCheck from './BtnCheck';
import ScreenLayoutRange from './ScreenLayoutRange';

import MosaicNineSvg from '../assets/icons/mosaic-nine.svg';
import MosaicSpotlightSvg from '../assets/icons/mosaic-spotlight.svg';
import MosaicAuto from '../assets/icons/mosaic-auto.svg';
import MosaicFocus from '../assets/icons/mosaic-focus.svg';
import MosaicSixSvg from '../assets/icons/mosaic-six.svg';
import MosaicSixteenSvg from '../assets/icons/mosaic-sixteen.svg';

const ScreenLayout = () => {

    // panneau screen layout 
    const [activeButton, setActiveButton] = useState('');

    const handleButtonClick = (content) => {
        setActiveButton(content);
    };

    

    return (
        <div className='--screen-layout'>
            <div className='btn-grid'>
                <BtnCheck
                    content='auto'
                    icon={MosaicAuto}
                    onButtonClick={handleButtonClick}
                    isActive={activeButton === 'auto'}
                    isChecked={activeButton === 'auto'}
                    />
                <BtnCheck
                    content='spotlight'
                    icon={MosaicSpotlightSvg}
                    onButtonClick={handleButtonClick}
                    isActive={activeButton === 'spotlight'}
                    isChecked={activeButton === 'spotlight'}
                    />
                <BtnCheck
                    content='mosaic'
                    icon={MosaicNineSvg}
                    onButtonClick={handleButtonClick}
                    isActive={activeButton === 'mosaic'}
                    isChecked={activeButton === 'mosaic'}
                    />
                <BtnCheck
                    content='focus'
                    icon={MosaicFocus}
                    onButtonClick={handleButtonClick}
                    isActive={activeButton === 'focus'}
                    isChecked={activeButton === 'focus'}
                />
            </div>
            {/* grille btn icon   */}
            <div className='tab-content__block'>
                <div className='tab-content__frame'>
                    <div className='tab-content__frame --top'>
                        <p>Tiles</p>
                        <p>Select the number of tiles you want to display</p>
                    </div>
                    <div className='tab-content__frame --bottom'>
                        <div className='grid-range'>
                            <div>
                                <img src={MosaicSixSvg} alt="mosaic six"/>
                            </div>
                            <div className="progress-bar__bar">
                                <div className='progress-bar__grid'>
                                    <div>6</div>
                                    <div>13</div>
                                    <div>20</div>
                                    <div>27</div>
                                    <div>34</div>
                                    <div>41</div>
                                    <div>48</div>
                                </div>
                                <ScreenLayoutRange/>
                            </div>
                            <div>
                                <img src={MosaicSixteenSvg} alt="mosaic sixteen"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScreenLayout;