import React, { useState } from 'react';
import CrownEmptySvg from '../assets/icons/crown-empty.svg';
import CrownFullSvg from '../assets/icons/crown-full.svg';
import StarFullSvg from '../assets/icons/star-full.svg';
import StarEmptySvg from '../assets/icons/star-empty.svg';
import { generateRandomLetterAndColor } from '../utils/function';

const textColor = "#FFFFFF";

const TableRowParticipant = ({ key, authenticated, portraitPicture, userName, participantRole, type}) => {

  let crownImg, starImg;
  switch (participantRole) {
    case 'host':
      crownImg = <img src={CrownFullSvg} alt="crown full" />;
      starImg = <img src={StarEmptySvg} alt="star empty" />;
      break;
    case 'animator':
      crownImg = <img src={CrownEmptySvg} alt="crown empty" />;
      starImg = <img src={StarFullSvg} alt="star full" />;
      break;
    default:
      crownImg = <img src={CrownEmptySvg} alt="crown empty" />;
      starImg = <img src={StarEmptySvg} alt="star empty" />;
      break;
  }

  const [color, setColor] = useState(generateRandomLetterAndColor());

  let avatar;

  if (type === 'external') {
    const initial = userName.charAt(0).toUpperCase();
    avatar = (
      <div className="options__avatar">
        <div style={{ backgroundColor: color, justifyContent: "center", alignItems: "center" }} className="round-pill contact">
          <p style={{ marginTop: "10px", color: textColor }}>{initial ? initial : '?'}</p>
        </div>
      </div>
    );
  } else {
    avatar = (
      <div className="round-pill avatar">
        {authenticated ? (
          <img src={portraitPicture} alt="portrait avatar" />
        ) : (
          <div style={{ backgroundColor: color }} className="round-pill">K</div>
        )}
      </div>
    );
  }

  return (
    <tr>
      <td>
        {avatar}
        {authenticated ? (
          <span>{userName}</span>
        ) : (
          <span>paul@disneyland-paris.com</span>
        )}
      </td>
      {authenticated && (
        <>
          <td data-size="small">{crownImg}</td>
          <td data-size="small">{starImg}</td>
        </>
      )}
    </tr>
  );
};

export default TableRowParticipant;
