function generateMeetName() {
	return Math.random().toString(36).substr(2, 8);
}

function generateMeetUUID() {
	return randomString(32);
}

function generateUserUUID() {
	return randomString(10);
}
function randomString(length) {
	let result = '';
	const characters =
		'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export { generateMeetName, generateMeetUUID, generateUserUUID };
