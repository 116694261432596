import React, { useEffect } from 'react';

const RoLauncher = () => {

    useEffect(() => {
        
        const roLauncher = new window.RoLauncher({
            handler: '.logotype svg',
            iconMode: true,
            hasDarkMode: false,
            hasIntegrations: false,
            forceDarkMode: false,
            modeDev: false,
        });
        roLauncher.setBox(96,2.5);
        roLauncher.setApp('meet');
        roLauncher.setApp('webapp');
        roLauncher.setApp('dashboard');
        roLauncher.setApp('empower');
        roLauncher.setActive('meet');

        // Retourner une fonction de nettoyage pour supprimer l'instance RoLauncher
        return () => {
         // roLauncher.destroyBox();
        }
    }, []); // Ajouter une dépendance vide pour que useEffect() ne se réexécute qu'une fois

    return (
        <div></div>
    );
};

export default RoLauncher;