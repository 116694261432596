import React, { useEffect, useState } from 'react';
import CrossSvg from '../assets/icons/cross.svg'
import useMediaQuery from '../hooks/useMediaQuery';
import InputLink from './InputLink';
import LogotypeSvg from '../assets/icons/logotype-ringover-circle.svg';
import Tooltip from './Tooltip';

const MeetingInfoBox = (props) => {

    const [meetingInfoBox,setMeetingInfoBox] = useState(true);
    const isSmallScreen = useMediaQuery('(max-width: 800px)');
    const isTabletOrLaptop = useMediaQuery('(max-width: 1540px)');

    const handleMeetingBox = (e) => {
        let container = e.target.parentElement.parentElement;
        setMeetingInfoBox(!meetingInfoBox);
      
        let timeout;
      
        const handleMouseLeave = () => {
          timeout = setTimeout(() => {
            setMeetingInfoBox(false);
          }, 300000);
        };
      
        const handleMouseEnter = () => {
          clearTimeout(timeout);
        };
      
        container.addEventListener('mouseleave', handleMouseLeave);
        container.addEventListener('mouseenter', handleMouseEnter);
    };

    useEffect(() => {
        setTimeout(() => {
            setMeetingInfoBox(false);
        }, 10000);
    },[])



    return (
        <div className='info-box'>
            {isTabletOrLaptop && !isSmallScreen && (
                <div data-size="x-large" className='info-box__heading'>
                    <img src={LogotypeSvg} alt='logotype ringover'/>
                    <hr/>
                </div>
            )}
            {!isSmallScreen && !isTabletOrLaptop && (
                <span>Meeting Title</span>
            )}

            <div id="meet-info" data-interactive={true}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.handleRoomInfoModale}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M2 11.75C2 6.365 6.365 2 11.75 2C17.135 2 21.5 6.365 21.5 11.75C21.5 17.135 17.135 21.5 11.75 21.5C6.365 21.5 2 17.135 2 11.75ZM10.706 10.308C11.852 9.735 13.143 10.771 12.832 12.014L12.123 14.85L12.165 14.83C12.3412 14.7525 12.5405 14.7458 12.7215 14.8112C12.9026 14.8765 13.0516 15.009 13.1377 15.1812C13.2237 15.3533 13.2403 15.552 13.184 15.7361C13.1277 15.9202 13.0027 16.0755 12.835 16.17L12.795 16.192C11.648 16.765 10.357 15.729 10.668 14.486L11.378 11.65L11.336 11.67C11.2475 11.7192 11.15 11.7499 11.0494 11.7604C10.9487 11.7709 10.847 11.7609 10.7503 11.731C10.6536 11.7011 10.5639 11.652 10.4867 11.5866C10.4095 11.5211 10.3464 11.4407 10.301 11.3502C10.2557 11.2598 10.2292 11.161 10.2231 11.06C10.2169 10.959 10.2313 10.8578 10.2653 10.7624C10.2993 10.6671 10.3522 10.5797 10.4209 10.5053C10.4896 10.431 10.5726 10.3714 10.665 10.33L10.706 10.308V10.308ZM11.75 8.75C11.9489 8.75 12.1397 8.67098 12.2803 8.53033C12.421 8.38968 12.5 8.19891 12.5 8C12.5 7.80109 12.421 7.61032 12.2803 7.46967C12.1397 7.32902 11.9489 7.25 11.75 7.25C11.5511 7.25 11.3603 7.32902 11.2197 7.46967C11.079 7.61032 11 7.80109 11 8C11 8.19891 11.079 8.38968 11.2197 8.53033C11.3603 8.67098 11.5511 8.75 11.75 8.75Z" fill="white" fillOpacity="1"/>
                </svg>
                {props.showTips && (
                    <Tooltip content="meeting info" topOffset="-48px" leftOffset="-50%"/>
                )}
                <div className={meetingInfoBox ? 'meeting-room-info --show' : 'meeting-room-info'}>
                    <div className='meeting-room-info__header' data-interactive={true}>
                        <h4 id="text-meeting-room-link">Your meeting room link</h4>
                        <img src={CrossSvg} alt="fermer" onClick={handleMeetingBox} />
                    </div>
                    <div className='meeting-room-info__body'>
                        <InputLink placeholder='https://meet.ringover.io/uvWCw55642'/>
                    </div>
                    <div className='meeting-room-info__triangle'>
                        <div></div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default MeetingInfoBox;