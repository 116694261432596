import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
	setMeetName,
	setMeetUUID,
	getMeetName,
	getMeetUUID,
	setMeetlist,
	getMeetList,
	addMeetToList,
} from '../redux/meetSlice';
// import * as mlHelpers from '../helpers/meetlist';
import * as utils from '../utils';
import BgPanel from '../components/BgPanel';
import BtnIcon from '../components/BtnIcon';
import WheelSvg from '../assets/icons/wheel-params.svg';
import CameraSvg from '../assets/icons/camera.svg';
import Navbar from '../components/Navbar';
import StaggeredAnimation from '../components/StaggeredAnimation';
import useAuthContext from '../hooks/useAuth';
import ModaleSetup from '../components/ModaleSetup';
import api from '../api';


export const defaultConfig = {
    needPassword: false,
    password: '1234',
    needWaitHost: false,
    needRequest: false,
    host: { userEmail: '', userUUID: '' },
    animator: { userEmail: '', userUUID: '' },
    defaultParticipant: []
};

const Home = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [modal, setModale] = useState(false);


	const { hasAllTokens, userEmail, socket, userUUID } = useAuthContext();

	const meetName = useSelector(getMeetName);
	const meetUUID = useSelector(getMeetUUID);

	const [showsetupModal, setShowSetupModal] = useState(false);
	// const handleModale = (e) => {
	// 	e.preventDefault();
	// 	const modalElement = document.querySelector('.modale-setup');
	// 	if (modalElement) {
	// 		modalElement.classList.toggle('show');
	// 		setModale(!modal);
	// 	}
	// };
	const handleMeetCreate = ({ meetlist = [], meet, isSuccessful, list }) => {
		console.log('---handleMeetCreate---',list,'meet',meet)
		dispatch(setMeetlist(meetlist));
		if (meet && Object.keys(meet).length !== 0) {
			const { name, uuid } = meet;
			dispatch(setMeetName(name));
			dispatch(setMeetUUID(uuid));
			navigate(`/${uuid}`);
		}
	};

	const handleShowSetupModal = (e)=>{
		e.stopPropagation();
		e.preventDefault();
		console.log(e);
		setShowSetupModal(true);
	}
	const handleHiddenSetupModal = (e)=>{
		e.stopPropagation();
		e.preventDefault();
		setShowSetupModal(false);
	}
	const handleInstantMeeting = (e) => {
		e.preventDefault();
		e.stopPropagation();

		//generer uuid
		const newMeetUUID = utils.generateMeetUUID();
		const newMeetName = utils.generateMeetName();

		dispatch(setMeetUUID(newMeetUUID));
		dispatch(setMeetName(newMeetName));

		//save meet to serveur
		if (socket) {
			socket.open();
			socket.emit('create', {
				uuid: newMeetUUID, name: newMeetName,
				config: {
					...defaultConfig,
					host: { userEmail, userUUID },
				}
			});
		}
	};

	useEffect(() => {
		const lastMeetUUID = meetUUID;
		if (lastMeetUUID) {
			if (socket) {
				socket.open();
				socket.emit('disconnect from client', { uuid: lastMeetUUID });
			}
		}

	}, []);

	useEffect(() => {
		async function loadMeetlist() {
			const response = await api.get('/meetlist', { params: { userUUID } });
			dispatch(setMeetlist(response.data?.list ?? []));
		}

		loadMeetlist();
	}, []);

	useEffect(() => {
		const homeAnim = new StaggeredAnimation(
			'.homepage__body__container > div',
			50,
			'posY',
			1.5,
			'ease',
			250
		);
		homeAnim.animate();
	}, []);

	useEffect(() => {
		socket.on('create', handleMeetCreate);
		return () => {
			socket.off('create', handleMeetCreate);
		};
	}, [socket]);
	return (
		<>
			
			<div className='homepage'>
				<div className='homepage__header'>
					<BgPanel />
				</div>
				<div className='homepage__body'>
					<Navbar />
					<div className='homepage__body__container'>
						<div className='frame'>
							<h2 id='text_meet_by'>
								Meet by <span>Ringover</span>
							</h2>
							{/* <h5><span>Sign in</span> for more options</h5>	 */}
						</div>
						<div className='heading-frame'>
							<div className='heading-frame__main'>
								<h1 id='text_video_call_screen'>
									Free and secure
									<br />
									<span id='text_free_and_secured'>
										Video calls & screen share
									</span>
								</h1>
							</div>
							<div className='heading-frame__legend'>
								<h4 id='text_open_your_virtual'>
									Open your virtual room in a click, without downloading
									<br /> and without personal data enquiry
								</h4>
							</div>
						</div>
						<div className='form-frame'>
							<form className='form'>
								<div className='form__header'>
									<h4 id='text_create_a_meet'>Create a Meet</h4>
								</div>
								<div className='form__body'>
									<div className='button-frame btn-duo-frame'>
										<BtnIcon
											alt='Instant Meeting'
											icon={CameraSvg}
											content='Instant Meeting'
											size='medium'
											id='text_instant_meeting'
											event={handleInstantMeeting}
										/>
										<BtnIcon
											alt='setup'
											icon={WheelSvg}
											content='Schedule'
											size='small'
											id='text_setup'
											event={handleShowSetupModal}
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{showsetupModal && <ModaleSetup  hiddenModal={handleHiddenSetupModal}/>}
		</>
	);
};

export default Home;
