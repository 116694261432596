import React, { useRef, useState } from 'react';
import InputCheckbox from './InputCheckbox';
import InputSelect from './InputSelect';
import BtnRegular from './BtnRegular';
import abc from '../assets/sounds/elevate.mp3';

const DevicePanel = () => {

    const fileRef = useRef(null);


    const [isPlaying,setIsPlaying] = useState(false) 

    const [showSelect1, setShowSelect1] = useState(false);
	const [showSelect2, setShowSelect2] = useState(false);
	const [showSelect3, setShowSelect3] = useState(false);


	const handleSelect1Click = () => {
		setShowSelect1(!showSelect1);
		setShowSelect2(false);
		setShowSelect3(false);
	}
    
	const handleSelect2Click = () => {
        setShowSelect2(!showSelect2);
        setShowSelect3(false);
		setShowSelect1(false);
	}
    
    const handleSelect3Click = () => {
        setShowSelect3(!showSelect3);
		setShowSelect2(false);
		setShowSelect1(false);
	}



    const playAudio = () => {
        let audio = document.getElementById('my-audio');
        if (isPlaying) {
          audio.currentTime = 0;
          audio.pause();
          setIsPlaying(!isPlaying);
        } else {
          audio.play();
          setIsPlaying(true);
        }
    };

    return (
        <div className='audio-video-box'>
            <div className='screen-frame'>
                <div className='screen-frame__left'>
                    <div className='video-box'>
                        <input
                            type="file"
                            id="file"
                            accept="video/*"
                            ref={fileRef}
                            // onChange={(event) => handleVideoUpload(event.target.files[0])}
                        />
                    </div>
                </div>
                <div className='screen-frame__right'>
                    <div className='video-box'>
                        <input
                            type="file"
                            id="file"
                            accept="video/*"
                            ref={fileRef}
                            // onChange={(event) => handleVideoUpload(event.target.files[0])}
                        />
                    </div>
                </div>
            </div>
            <div className='input-frame'>
                <div className='input-frame__single'>
                    <label htmlFor='camera-type'>Camera</label>
                    <InputSelect
                        options={['Par défault - camera mac 3000','opt 2']}
                        defaultOption='Par défault - camera mac 3000'
                        showOptions={showSelect1}
                        handleValClick={handleSelect1Click}
                    />
                </div>
                <div className='input-frame__double'>
                    <div className='input-frame__double__row'>
                        <label htmlFor='audio-output'>Audio output</label>
                    </div>
                    <div className='input-frame__double__row'>
                        <InputSelect
                            options={['Par défault - Mac-Sound-System','Other option']}
                            defaultOption='Par défault - Mac-Sound-System'
                            showOptions={showSelect2}
                            handleValClick={handleSelect2Click}
                        />
                        <BtnRegular content={isPlaying ? 'Stop playing' : 'Play test music'} event={playAudio} />
                            <audio autoPlay={false} id="my-audio">
                            <source type="audio/mp3" src={abc} />
                        </audio>
                    </div>
                </div>
                <div className='input-frame__double'>
                    <div className='input-frame__double__row'>
                        <label htmlFor='microphone'>Microphone</label>
                    </div>
                    <div className='input-frame__double__row'>
                        <InputSelect
                            options={['Par défault - Micro Micmac 1234','Mac-Sound-System XV-6I-XXX']}
                            defaultOption='Par défault - Micro Micmac 1234'
                            showOptions={showSelect3}
                            handleValClick={handleSelect3Click}
                        />
                        <BtnRegular content='Record a Micro test'/>

                    </div>

                </div>
            </div>
            <div className='option-frame'>
                <InputCheckbox content='AI noise cancellation' htmlFor='ai-noise-cancellation' name="ai-noise-cancellation" id="ai-noise-cancellation"/>
                <InputCheckbox content='AI echo cancellation' htmlFor='ai-echo-cancellation' name="ai-echo-cancellation" id="ai-echo-cancellation"/>
            </div>
        </div>
    );
};

export default DevicePanel;