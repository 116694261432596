import React, { useRef } from 'react';
import PrejoinActionFrame from './PrejoinActionFrame';

// import NetworkStatusStableSvg from '../assets/icons/network-status-stable.svg';
// import NetworkStatusWarningSvg from '../assets/icons/network-status-warning.svg';
// import NetworkStatusDangerSvg from '../assets/icons/network-status-danger.svg';

import NoVolumeSvg from '../assets/icons/no-volume.svg';

const PrejoinCamera = ({userVisibility,signal}) => {

    const fileRef = useRef(null);


    return (
        <div className='prejoin__right-panel'>
            <div className='prejoin__right-panel__video-frame'>
                {true ? (
                    <div className='video-box'>
                        <input
                            type="file"
                            id="file"
                            accept="video/*"
                            ref={fileRef}
                            // onChange={(event) => handleVideoUpload(event.target.files[0])}
                        />
                    </div>
                ) : 
                    <div className='macaroon-box'>
                        <div className='macaroon-box__frame'>
                            <span>T</span>
                            <span>J</span>
                        </div>
                    </div>
                } 
                {/* <div className='option-layer'>
                    <span><img src={NoVolumeSvg} alt='volume'/></span>
                </div> */}
                <div className='option-layer'>
                    {/* <span>
                        {(() => {
                        switch (signal) {
                            case "good":
                            return <img src={NetworkStatusStableSvg} alt='network status'/>;
                            case "warning":
                            return <img src={NetworkStatusWarningSvg} alt='network status'/>;
                            case "danger":
                            return <img src={NetworkStatusDangerSvg} alt='network status'/>;
                            default:
                            return null;
                        }
                        })()}
                    </span> */}
                </div>
            </div>
            <PrejoinActionFrame/>
        </div>
    );
};

export default PrejoinCamera;