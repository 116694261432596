import React from 'react';

const InputCheckbox = ({content,htmlFor,id,name, handleChange,checked}) => {
    return (
        <div className='input-checkbox'>
            <input type="checkbox" id={id} name={name} onChange={handleChange} checked={checked}/>
            <label htmlFor={htmlFor}>{content}</label>
        </div>
    );
};

export default InputCheckbox;