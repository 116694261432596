export const config = [
    {
      username: null,
      signal: "warning",
      greetingsMessage: "4 participants are already in the meeting room",
      message: "Identification and request are required !",
      notification:
        "Enter your name or sign in to your Ringover account to send a request to join the meeting.",
      checkMessage: "Join automatically once your request is approved",
    },
    {
      username: "Sarah",
      signal: "warning",
      greetingsMessage: "No one is here yet",
      message: "Wait until the administrator lets you in",
      notification: "Your request has been sent. We will let you know when the administrator has accepted your request.",
      checkMessage: "Join automatically at the host arrival",
    },
    {
      username: "Sarah",
      signal: "danger",
      greetingsMessage: "4 participants are already in the meeting room",
      message: "Your request has been accepted!",
      notification: "Enter the meeting password",
      checkMessage: "Join automatically at the host arrival",
    },
    {
      username: "Sarah",
      signal: "good",
      greetingsMessage: "6 participants are already in the meeting room",
      message: "",
      notification: "You are about to be redirected to the room",
      checkMessage: "Join automatically at the host arrival",
    },
];