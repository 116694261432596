import React from 'react';
import WheelSvg from '../assets/icons/wheel-params.svg';
import SquareBtn from '../components/SquareBtn';

import MicroSvg from '../assets/icons/micro.svg';
import CameraSvg from '../assets/icons/regular-camera.svg';
import ThreeStarsSvg from '../assets/icons/three-stars.svg';
import BtnIcon from './BtnIcon';

const PrejoinActionFrame = () => {


    const handlePreviewSettingsModal = () => {
		let meetCreatedModal = document.querySelector('.preview-settings-modal');
        console.log(meetCreatedModal);
		if (meetCreatedModal) {
			meetCreatedModal.classList.add('show');
		}
	}


    return (
        <div className='prejoin__right-panel__action-frame'>
            <div className='btn-frame'>
                <BtnIcon
                    content='Audio and video'
                    icon={WheelSvg}
                    event={handlePreviewSettingsModal}
                />
            </div>
            <div className='action-frame'>
                <SquareBtn icon={MicroSvg} size='large'/>
                <SquareBtn icon={CameraSvg} size='large'/>
                <SquareBtn icon={ThreeStarsSvg} size='large'/>
            </div>
            <div className='empty-frame'></div>
        </div>
    );
};

export default PrejoinActionFrame;