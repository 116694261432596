import React, { useEffect } from 'react';
import OrnamentLeft from './OrnamentLeft';
import OrnamentRight from './OrnamentRight';

const BgPanel = () => {


    useEffect(() => {

        // const testRotate = new StaggeredAnimation(".bg-panel__right",0,'posX',10,'ease',0);
        // testRotate.rotate('rotatingCircles',24,'alternate');


    },[])


    return (
        <div className='bg-panel'>
            <div className='bg-panel__left'>
                <OrnamentLeft/>
            </div>
            <div className='bg-panel__right'>
                <OrnamentRight/>
            </div>
        </div>   
    );
};

export default BgPanel;