import React, { useEffect, useState } from 'react';
import Logotype from './Logotype';
import profilSvg from '../assets/icons/profil.svg';
import useAuthContext from '../hooks/useAuth';
import * as Auth from '../helpers/auth';
import useMediaQuery from '../hooks/useMediaQuery';
import RoLauncher from './RoLauncher';
import UserModal from './UserModal';


const Navbar = () => {
	
	const [isAuth, setIsAuth] = useState(false);
	const [showUserMOdal, setShowUserModal] = useState(false);
	const [userAvatarLogo, setUserAvatarLogo] = useState(profilSvg);
	const { userAvatarPath } = useAuthContext();
	
	const isSmallScreen = useMediaQuery('(max-width: 800px)');
	const handleLogin = () => {
		Auth.oauth_auth();
	}
	const handleLogout = () => {
		Auth.logOut();
	}

	const handleShowUserModal = () => {
		setShowUserModal(true);
	};

	useEffect(() => {
		const isAuthenticated =
			localStorage.getItem("id_token") &&
			localStorage.getItem("access_token") &&
			localStorage.getItem("refresh_token");

		setIsAuth(isAuthenticated);

		if (userAvatarPath != null) {
			fetch(userAvatarPath, {
			  method: 'get',
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + localStorage.getItem("id_token") 
			  },
			})
			  .then(response => {
				if (!response.ok) {
				  throw new Error('Erreur lors de la récupération de l\'image');
				}
				return response.blob();
			  })
			  .then(blob => {
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
				  const base64data = reader.result;
				  setUserAvatarLogo(base64data);
				  caches.open('avatar-cache').then(cache => {
					cache.put(userAvatarPath, new Response(blob));
				  });
				};
			  })
			  .catch(error => console.log(error));
		  }
	}, [userAvatarPath]);

	const renderOptionsContent = () => {
		if (isAuth) {
			return (
				<div className="options">
					<div className="round-pill avatar" onClick={handleShowUserModal}>
						<img src={userAvatarLogo} alt="portrait" />
					</div>
					{!isSmallScreen && <span id="text_sign_out">Sign out</span>}
				</div>
			);
		} else {
			return (
				<div className="options">
					<div className="round-pill profil" onClick={handleLogin}>
						<img src={profilSvg} alt="profilSvg" />
					</div>
					{!isSmallScreen && <span id="text_sign_in">Sign in</span>}
				</div>
			);
		}
	};

	return (
		<div className="navbar" id="main-navbar">
			<nav className="navbar__body">
				<Logotype />
				{renderOptionsContent()}
				{showUserMOdal && <UserModal userAvatarLogo={userAvatarLogo} closeModal={() => setShowUserModal(false)} disconnect={handleLogout} />}
			</nav>
			<RoLauncher/>
		</div>
	);

};


export default Navbar;