import React,{useEffect, useRef, useState} from "react";

import CheckGreenSvg from '../../assets/icons/check-green.svg';
import CrossRedSvg from '../../assets/icons/cross-red.svg';
import PrejoinWithUserNameInput from "./withUserNameInput";

import useAuthContext from "../../hooks/useAuth";

const PrejoinWithPassword = ({ password ,setHasCorrectPassword, hasCorrectPassword}) => {
    

    const { userName } = useAuthContext();

    const inputRef = useRef([]);
    const [passwordInputArr, setPasswordInputArr] = useState([...Array(4).fill('')])
    const [hasInput, setHasInput] = useState(false);
    
    const [message, setMessage] = useState('');
    const [notification, setNotification] = useState('');

    useEffect(() => {

        if (!userName) {
            setMessage('Name and password required');
            setNotification("To join the meeting, enter your name or sign in.");
            
        } else {
            setMessage('Password required');
            setNotification('Enter the password to join the meeting.');
        }    
    }, [])
    
    const handlerChange = idx => e => {

        if (e.target.value < 0) return;
        if (e.target.value > 9) return;
        const newList = [...passwordInputArr];
        newList[idx] = e.target.value;
        setPasswordInputArr(newList);
    
        if (e.target.value) {
            const next = inputRef.current[idx + 1];
            if (next) {
                next.focus();
            }
        } else {
            const prev = inputRef.current[idx - 1];
            if (prev) {
                prev.focus();
            }
        }
    }

    useEffect(() => {
        const passwordInputStr = passwordInputArr.join('');

        setHasInput(passwordInputStr !== '' && passwordInputStr.length === 4);
        setHasCorrectPassword(passwordInputStr === password);
        
    }, [passwordInputArr])
    
    return (

        <div className='notif-box'>
            <div className='notif-box__header'>
                <p>{message}</p>
                <p className='legend'>{notification}</p>
            </div>

            <div className='notif-box__body'>
                <div className='form-frame'>
                    <form className='form'>
                        
                        {!userName &&
                            <div className='form__body'>
                                <PrejoinWithUserNameInput /> 
                            </div>
                        }
                        <div className='form__footer'>
                            <div className='typecode-frame'>
                                <div className='typecode-frame__body'>
                                    <div className='box'>
                                        {[...Array(4).keys()].map(x => (
                                            <div>
                                                <input key={x} type='number' min="0" max="9"
                                                    maxlength="1"
                                                    className="otp_box"
                                                    onChange={handlerChange(x)}
                                                    // onKeyDown={handleKeyDown(x)}
                                                    ref={el => inputRef.current[x] = el} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='typecode-frame__footer'>
                                    {hasInput && (
                                        hasCorrectPassword ?
                                            <div className='notif-frame success'>
                                                <img src={CheckGreenSvg} alt="check success" />
                                                <p>Correct password</p>
                                            </div>
                                            :
                                            <div className='notif-frame danger'>
                                                <img src={CrossRedSvg} alt="cross danger" />
                                                <p>Incorrect password</p>
                                            </div>
                                    )
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        
    )
}
export default PrejoinWithPassword;