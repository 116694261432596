import React, { useEffect } from "react";

import BtnRegular from '../../components/BtnRegular';

const PrejoinWithOnlyJoinButton = ({onJoinMeet}) => {
    

    return (
        <div className='notif-box'>
            <div className='notif-box__header'>
                <p className='legend'>Ready to join the meeting?</p>
            </div>

            <div className='notif-box__body'>
                <div className='form-frame'>
                    <form className='form'>
                        <div className='form__footer'>
                            <BtnRegular className="btn-regular" content='Join Now' event={onJoinMeet} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default PrejoinWithOnlyJoinButton;