import React, { useEffect, useState } from 'react';
import useMediaQuery from '../hooks/useMediaQuery';
import LogotypeSvg from '../assets/icons/logotype-ringover-circle.svg';
import AudioSvg from '../assets/icons/audio-on.svg';
import FolderSwitchSvg from '../assets/icons/folder-switch.svg';
import MeetingInfoBox from './MeetingInfoBox';
import MeetingInfoModale from './MeetingInfoModale';

const TopBarMobile = () => {

    const isSmallScreen = useMediaQuery('(max-width: 800px)');
    const [meetingModale,setMeetingModale] = useState(false);

    const [meetingInfoBox,setMeetingInfoBox] = useState(true);

    const meetingTitle = 'meeting title test';

    const toggleMeetInfoPanel = () => {
        setMeetingModale(!meetingModale);
    }

    useEffect(() => {
        // setTimeout(() => {
        //     setMeetingInfoBox(false);
        // }, 3000);
    },[])

    return (
        <>
            {isSmallScreen && (
                <div className='top-bar-mobile'>
                    <div className='top-bar-mobile__body'>
                        <div className='top-bar-mobile__left'>
                            <img src={LogotypeSvg} alt='logotype ringover'/>
                            <hr/>
                            <span>{meetingTitle}</span>
                            {meetingInfoBox && (
                                <MeetingInfoBox handleRoomInfoModale={toggleMeetInfoPanel}/>
                            )}
                            {meetingModale && (
                                <MeetingInfoModale meetingModale={meetingModale} toggleMeetingModale={toggleMeetInfoPanel}/>
                            )}
                        </div>
                        <div className='top-bar-mobile__right' data-interactive={true} data-size={'small'}>
                            <img src={AudioSvg} alt='audio on'/>
                            <img src={FolderSwitchSvg} alt='folder switch'/>
                        </div>
                        {/* <hr/> */}
                    </div>
                </div>
            )}
        </>
    );
};

export default TopBarMobile;