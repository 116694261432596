import React, { useEffect, useRef, useState } from 'react';
import BasicBackground from '../assets/pictures/basic-background.jpg';

import ForbiddenSvg from '../assets/icons/forbidden.svg';
import SlightBlurSvg from '../assets/icons/slight-blur.svg';
import BlurSvg from '../assets/icons/blur.svg';

import PlusIconSvg from '../assets/icons/add-icon.svg';
import CosyJpg from '../assets/pictures/cosy-small.jpg' 
import LobbyJpg from '../assets/pictures/lobby-small.jpg' 
import CityJpg from '../assets/pictures/city-small.jpg' 
import BeachJpg from '../assets/pictures/beach-small.jpg' 
import MountainJpg from '../assets/pictures/mountain-small.jpg' 
import LivingJpg from '../assets/pictures/living-small.jpg' 
import SeaviewJpg from '../assets/pictures/seaview-small.jpg' 

const thumbnails = [
    { src: LobbyJpg, legend: 'Lobby' }, // Tableau d'objets image avec le chemin et le texte de légende
    { src: CosyJpg, legend: 'Cosy' },
    { src: CityJpg, legend: 'City' },
    { src: BeachJpg, legend: 'Beach' },
    { src: MountainJpg, legend: 'Mountain' },
    { src: LivingJpg, legend: 'Living' },
    { src: SeaviewJpg, legend: 'Sea view' },
];

const VisualEffectPanel = () => {

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const scrollRef = useRef(null);


    const handleMouseDown = (event) => {
        setIsDragging(true);
        setStartX(event.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };
    
    const handleMouseMove = (event) => {
        if (!isDragging) return;
        const x = event.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 3; // ajustez ce nombre pour changer la vitesse de défilement
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };
    

    const handleTouchStart = (event) => {
        setIsDragging(true);
        setStartX(event.touches[0].pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };
    

    const handleTouchMove = (event) => {
        if (!isDragging) return;
        event.preventDefault();
        const x = event.touches[0].pageX - scrollRef.current.offsetLeft;
        const walk = (startX - x) * 3; // Inversion du sens de défilement (startX - x)
        scrollRef.current.scrollLeft = scrollLeft + walk;
      };
    
    const handleTouchEnd = () => {
        setIsDragging(false);
    };


    const handleWheel = (e) => {
        const carouselContainer = document.querySelector('.pill-carousel__frame');
        const delta = Math.max(-1, Math.min(1, (e.deltaY || -e.detail)));
        carouselContainer.scrollLeft -= (delta * 150);
    };

    const handleEffect = (e) => {
        let tag = e.target.tagName.toLowerCase();
        let screenImg = document.querySelector('.custom-screen-frame img');
        if(tag == 'img'){
            e.target = e.target.parentElement;
        }
        console.log(e.target.id);
        switch(e.target.id){
            case 'preview-settings-forbidden-btn' : 
                screenImg.style.filter = 'unset';
                break;        
            case 'preview-settings-slightblur-btn' : 
                screenImg.style.filter = 'blur(1px)';
                break;        
            case 'preview-settings-blur-btn' : 
                screenImg.style.filter = 'blur(4px)';
                break;        
            default :
                console.log('default case')
        }
    }


    const handleNext = () => {
        const carouselContainer = document.querySelector('.pill-carousel__frame');
        const slideWidth = carouselContainer.offsetWidth / 4;
        let currentPosition = carouselContainer.scrollLeft;
        let targetPosition = currentPosition + (carouselContainer.offsetWidth * 0.4);
      
        if (targetPosition > (carouselContainer.scrollWidth - carouselContainer.offsetWidth)) {
          targetPosition = carouselContainer.scrollWidth - carouselContainer.offsetWidth;
        }
      
        carouselContainer.scrollTo({
          left: targetPosition,
          behavior: 'smooth' // Ajout de la propriété behavior avec la valeur 'smooth'
        });
      };
      
      const handlePrev = () => {
        const carouselContainer = document.querySelector('.pill-carousel__frame');
        const slideWidth = carouselContainer.offsetWidth / 4;
        let currentPosition = carouselContainer.scrollLeft;
        let targetPosition = currentPosition - (carouselContainer.offsetWidth * 0.4);
      
        if (targetPosition < 0) {
          targetPosition = 0;
        }
      
        carouselContainer.scrollTo({
          left: targetPosition,
          behavior: 'smooth' // Ajout de la propriété behavior avec la valeur 'smooth'
        });
      };

    const imageElements = thumbnails.map((image, index) => (
        <div key={index} className='pill-carousel__item'>
          <button className='pill-carousel__item__content --image' style={{ backgroundImage: `url(${image.src})` }}>
            {/* <input type='file' className='pill-carousel__item__input'/> */}
          </button>
          <div className='pill-carousel__item__legend'><span>{image.legend}</span></div>
        </div>
    ));

    const handlePictos = () => {
        let screenImg = document.querySelector('.custom-screen-frame img');
        let allItems = document.querySelectorAll('.pill-carousel__item__content.--image');
        if (allItems) {
          allItems.forEach((e) => {
            console.log(e.style.backgroundImage)
            e.addEventListener('click', () => {
              const backgroundImage = window.getComputedStyle(e).getPropertyValue('background-image');
              const imageUrl = backgroundImage.match(/url\(["']?([^"']*)["']?\)/)[1];
              screenImg.setAttribute('src', imageUrl);
            })
          })
        }
    }

    useEffect(() => {
        handlePictos();
    },[])


    return (
        <div className='visual-effect-box'>
        <div className='visual-effect-box__body'>
            <div className='custom-screen-frame'>
                <img src={BasicBackground} alt='custom screen'/>
            </div>
        </div>
        <div className='visual-effect-box__footer'>
            <div className='btn-frame'>
                <button id='upload-image-preview-settings'>
                    <img src={PlusIconSvg} alt='add'/>
                    <span>Upload an image</span>
                </button>
                <div className="pill-carousel__controls">
                    <button className="prev-button" onClick={handlePrev}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M17 15.7445C17 16.698 15.9 17.3025 15.0008 16.8431L6.69873 12.5983C5.76709 12.1222 5.76709 10.8778 6.69873 10.4017L15 6.15689C15.8993 5.6975 16.9993 6.30199 16.9993 7.25553L16.9993 15.7445L17 15.7445Z" fill="white"/>
                        </svg>
                    </button>
                    <button className="next-button" onClick={handleNext}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7 8.25553C7 7.30199 8.09998 6.6975 8.99925 7.15689L17.3013 11.4017C18.2329 11.8778 18.2329 13.1222 17.3013 13.5983L8.99997 17.8431C8.1007 18.3025 7.00072 17.698 7.00072 16.7445V8.25553H7Z" fill="white"/>
                        </svg>
                    </button>
                </div>
            </div>

            <div className='pill-carousel'>
                <div 
                    className='pill-carousel__frame' 
                    onWheel={handleWheel}                                     
                    ref={scrollRef}
                    style={{ overflow: 'hidden', display: 'flex' }}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    onTouchCancel={handleTouchEnd}
                >
                    <div className='pill-carousel__item'>
                        <button className='pill-carousel__item__content' data-size="large" id="preview-settings-forbidden-btn" onClick={handleEffect}>
                            <img src={ForbiddenSvg} alt='forbidden icon'/>
                        </button>
                        <div className='pill-carousel__item__legend'><span>None</span></div>
                    </div>
                    <div className='pill-carousel__item'>
                        <button className='pill-carousel__item__content' data-size="large" id="preview-settings-slightblur-btn" onClick={handleEffect}>
                            <img src={SlightBlurSvg} alt='slight blur icon'/>
                        </button>
                        <div className='pill-carousel__item__legend'><span>Slight blur</span></div>
                    </div>
                    <div className='pill-carousel__item'>
                        <button className='pill-carousel__item__content' data-size="large" id="preview-settings-blur-btn" onClick={handleEffect}>
                            <img src={BlurSvg} alt='blur icon'/>
                        </button>
                        <div className='pill-carousel__item__legend'><span>Blur</span></div>
                    </div>
                    {imageElements}
                </div>

            </div>
        </div>
    </div>
    );
};

export default VisualEffectPanel;