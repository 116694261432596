import React from 'react';

const Logotype = () => {

    const reloadPage = () => {
        window.location.reload(false);
    }

    return (
        <div className='logotype'>
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1375_86217)">
                <path fillRule="evenodd" clipRule="evenodd" d="M25 50.192C12.321 50.192 2.36661 42.0034 0.117845 30.1334C0.0758672 29.9118 0 29.4648 0 29.4648L12.5634 29.4651C12.5634 29.4651 12.646 29.7328 12.69 29.8644C14.5527 35.4363 19.612 38.3175 25 38.3175C30.397 38.3175 35.4641 35.4869 37.3192 29.8736C37.3637 29.739 37.4472 29.4649 37.4472 29.4649L50 29.4648C50 29.4648 49.937 29.8385 49.9026 30.024C47.6944 41.9531 37.7179 50.192 25 50.192Z" fill="url(#paint0_linear_1375_86217)"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M25 0C38.1387 0 48.3242 8.79472 49.9097 21.4966C49.9438 21.7696 50 22.3211 50 22.3211L37.7357 22.321C37.7357 22.321 37.6827 22.008 37.6529 21.854C36.3532 15.1486 30.8738 11.7645 25 11.7645C19.1934 11.7645 13.7722 15.0716 12.3934 21.6253C12.3454 21.8533 12.2643 22.321 12.2643 22.321L0 22.3211C0 22.3211 0.0812797 21.5469 0.133708 21.1652C1.85369 8.642 11.9759 0 25 0Z" fill="url(#paint1_linear_1375_86217)"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_1375_86217" x1="39.8504" y1="29.4648" x2="20.9096" y2="54.8577" gradientUnits="userSpaceOnUse">
                <stop stopColor="#A64FFF"/>
                <stop offset="1" stopColor="#6370E7"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1375_86217" x1="39.8504" y1="1.84972e-06" x2="19.071" y2="25.8686" gradientUnits="userSpaceOnUse">
                <stop stopColor="#A64FFF"/>
                <stop offset="1" stopColor="#6370E7"/>
                </linearGradient>
                <clipPath id="clip0_1375_86217">
                <rect width="50" height="50" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            <h2 id="text_meet_by_ringover">Meet by ringover</h2>
        </div>
    );
};

export default Logotype;