import React from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';
import BgPanel from '../components/BgPanel';
import Navbar from '../components/Navbar';
import BtnIcon from '../components/BtnIcon';
import ArrowBackSvg from '../assets/icons/arrow-back.svg';
import useAuthContext from '../hooks/useAuth';
import translate from '../data/translate';
import * as utils from '../utils';
import { RouteErrorMessage } from '../routes/PageLoader';

const ErrorPage = () => {
	const error = useRouteError();
	const navigate = useNavigate();
	const { userLang } = useAuthContext();

	const errorMessage =
		translate['Route Error Message']?.[error.data]?.[userLang];
	const backButtonText =
		error.data === RouteErrorMessage.RoomNotFound
			? 'Back to Meet Homepage'
			: 'Try Again';

	const handleNavigate = () => {
		if (error.data === RouteErrorMessage.BadConnect) {
			console.log('Bad Connection, reessaye');
			window.location.reload();
		} else if (error.data === RouteErrorMessage.RoomNotFound) {
			console.log('room not found, back to home');
			navigate('/');
		}
	};
	return (
		<div className='room-not-found'>
			<div className='room-not-found__header'>
				<BgPanel />
			</div>
			<div className='room-not-found__body'>
				<Navbar />
				<div className='room-not-found__body__container'>
					<div className='room-not-found__body__header'>
						<h2 id='text_the_room_is_unfindable'>{errorMessage}</h2>
					</div>
					<div className='room-not-found__body__footer'>
						<div className='btn-frame'>
							<BtnIcon
								content={backButtonText}
								icon={ArrowBackSvg}
								event={handleNavigate}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ErrorPage;
