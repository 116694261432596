import React, { useState } from 'react';
import InputSelect from './InputSelect';
import PlusIconSvg from '../assets/icons/add-icon.svg';

const ProfileSettings = () => {

    const [showSelect, setShowSelect] = useState(false);
    const [palette,setPalette] = useState(false);
    const [randomBg,setRandomBg] = useState(null);

    const handleSelect4Click = () => {
        setShowSelect(!showSelect);
	}


    let colorArray = [
        {
            id:"pal1",
            color:'#FFCF4F'
        },
        {
            id:"pal2",
            color:'#40E0CF'
        },
        {
            id:"pal3",
            color:'#72BBF1'
        },
        {
            id:"pal4",
            color:'#F05F5F'
        },
        {
            id:"pal5",
            color:'#E885F0'
        },
        {
            id:"pal6",
            color:'#702963'
        },
        {
            id:"pal7",
            color:'#aeb6bf'
        },
        {
            id:"pal8",
            color:'#6370e7'
        },
    ]

    const generateRandomColor = () => {
        let colorArray = ['#FFCF4F','#40E0CF','#72BBF1','#FF8888','#E885F0','#702963'];
        let macaroon = document.querySelector('.random-color__pill');
        if(macaroon){
            let randomIndex = Math.floor(Math.random() * colorArray.length);
            let randomColor = colorArray[randomIndex];
            macaroon.style.backgroundColor = randomColor;
        } 
    }

    const displayColors = () => {
        setPalette(!palette);
        
    }

    const uploadProfilePicture = () => {
        console.log('upload picture');
    }

    const handlePick = (e) => {
        console.log(e.color);
        setRandomBg(e.color);
    }

    return (
        <div className='more-options-box'>
            <div className='input-frame'>
                <div className='input-frame__single'>
                    <label htmlFor='language'>Language</label>
                    <InputSelect
                        options={['English','Français','Español']}
                        defaultOption='English'
                        showOptions={showSelect}
                        handleValClick={handleSelect4Click}
                    />
                </div>
            </div>
            <div className='input-frame__single'>
                <label htmlFor='display-name'>Choose a display name</label>
                <input className='input-frame__input' type="text" placeholder='Type your display name'/>
            </div>
            <div className='input-frame__single'>
                <label htmlFor='email-gravatar'>Define your email gravatar</label>
                <input className='input-frame__input' type="email" placeholder='Define your email gravatar'/>
            </div>
            <div className='input-frame__single'>
                <label htmlFor='email-gravatar'>Choose your avatar's color</label>
                <div className='random-color__pill' style={{ backgroundColor: randomBg ? randomBg : '#2f2a38' }}>
                    <span onClick={displayColors}>Palette</span>
                    <div className={`palette ${palette ? 'active' : ''}`}>
                        {colorArray.map((e) => (
                            <div id={e.id} style={{ backgroundColor: e.color }} onClick={() => handlePick(e)} key={e.id}></div>
                        ))}
                    </div>
                </div>

            </div>
            <div className='input-frame__single'>
                <label htmlFor='upload-profile-picture'>Upload a profile picture</label>
                <div className='upload-profile__box'> 
                    <div className='upload-profile__pill' onClick={uploadProfilePicture} data-size="large">
                        <img src={PlusIconSvg} alt='add picture'/>
                        <input type='file' name="upload-profile-picture"/>
                    </div>
                    <div className='upload-profile__pill --preview' data-size="large">
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileSettings;