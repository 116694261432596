import React from 'react';

const Input = ({
	icon,
	placeholder,
	id,
	className,
	defaultValue,
	alt,
	...rest
}) => (
	<div className={className}>
		{icon && (
			<img
				src={icon}
				width={24}
				alt={alt}
			/>
		)}
		<input
			id={id}
			className='input-frame__input'
			placeholder={placeholder}
			value={defaultValue}
			{...rest}
		/>
	</div>
);


export default Input;