import React from 'react';

const OrnamentLeft = () => {
    return (
        <div className='ornament-frame'>
            <svg width="862" height="397" viewBox="0 0 862 397" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M430.87 0.373405C657.297 0.373405 832.83 156.532 860.155 382.066C860.742 386.914 861.71 396.705 861.71 396.705L650.352 396.704C650.352 396.704 649.439 391.146 648.925 388.413C626.527 269.351 532.096 209.263 430.87 209.263C330.802 209.263 237.374 267.985 213.612 384.352C212.786 388.4 211.388 396.704 211.388 396.704L0.0302734 396.705C0.0302734 396.705 1.43102 382.96 2.33454 376.182C31.976 153.82 206.418 0.373405 430.87 0.373405Z" fill="url(#paint0_linear_4_4373)" fillOpacity="0.5"/>
                <defs>
                <linearGradient id="paint0_linear_4_4373" x1="686.797" y1="0.373438" x2="315.613" y2="448.871" gradientUnits="userSpaceOnUse">
                <stop stopColor="#5E65A2" stopOpacity="0.07"/>
                <stop offset="1" stopColor="#67578B" stopOpacity="0.28"/>
                </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default OrnamentLeft;