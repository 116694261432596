import React, { useEffect, useRef, useState } from 'react';
import RingoverLogo from '../assets/icons/logotype-ringover.svg';
import SquareBtn from './SquareBtn';

import MicroSvg from '../assets/icons/micro.svg';
import MicroPurpleSvg from '../assets/icons/micro-purple.svg';
import CameraSvg from '../assets/icons/regular-camera.svg';
import CameraSlashedSvg from '../assets/icons/camera-slashed.svg';
import RaiseHandWhiteSvg from '../assets/icons/raise-hand-white.svg';
import RaiseHandPurpleSvg from '../assets/icons/raise-hand-purple.svg';
import RaiseHandSvg from '../assets/icons/raise-hand.svg';
import UploadFolderSvg from '../assets/icons/upload-folder.svg';
import ShareScreenActiveSvg from '../assets/icons/share-screen-active.svg';
import SettingsSvg from '../assets/icons/settings-icon.svg';
import HangUpSvg from '../assets/icons/hang-up-icon.svg';
import RecordSvg from '../assets/icons/record-icon.svg';
import RecordSvgActive from '../assets/icons/record-icon-active.svg';

import ChatSvg from '../assets/icons/chat-icon.svg';
import ChatActiveSvg from '../assets/icons/chat-icon-active.svg';
import VoiceSettingSvg from '../assets/icons/voice-setting-icon.svg';
import VoiceSettingActiveSvg from '../assets/icons/voice-setting-icon-active.svg';
import PuzzleSvg from '../assets/icons/puzzle-icon.svg';
import UserGroupSvg from '../assets/icons/user-group-icon.svg';
import UserGroupActiveSvg from '../assets/icons/user-group-icon-active.svg';
import ThreeDotsSvg from '../assets/icons/three-dots.svg';
import ThreeStarsSvg from '../assets/icons/three-stars.svg';
import MosaicSvg from '../assets/icons/mosaic.svg';
import CodingSvg from '../assets/icons/coding-icon.svg';

import ThumbsUpSvg from '../assets/icons/thumbs-up.svg';
import ThumbsDownSvg from '../assets/icons/thumbs-down.svg';
import ClappingHandsSvg from '../assets/icons/clapping-hands.svg';
import ILoveItSvg from '../assets/icons/i-love-it.svg';
import SoFunnySvg from '../assets/icons/so-funny.svg';
import PauseSvg from '../assets/icons/pause-icon.svg';
import StopSvg from '../assets/icons/stop-icon.svg';

import useMediaQuery from '../hooks/useMediaQuery';
import CrossSvg from '../assets/icons/cross.svg'


import {useSelector,useDispatch} from 'react-redux';
import { selectAllParticipants, participantUpdatedMany } from '../redux/participantSlice';
import MeetingInfoBox from './MeetingInfoBox';
import formatTime from '../utils/formatTime';
import BtnRegular from './BtnRegular';
import DraggableModal from './DraggableModal';

import MeetingInfoModale from './MeetingInfoModale';
import AllSettingsModal from './AllSettingsModal';
import { closeModalOnClickOutside } from '../utils/closeModalOnClickOutside';
import Tooltip from './Tooltip';

import useAuthContext from '../hooks/useAuth';

const RoomBottomPanel = (props) => {

    const dispatch = useDispatch();
    const participantRedux = useSelector(selectAllParticipants);

    const [duration, setDuration] = useState(0);
    const [moreLaptopOptions, setMoreLaptopOptions] = useState(false);
    const [mobileOptions, setMobileOptions] = useState(false);
    const [boutonActif, setBoutonActif] = useState(null);
    const [userRaisingHand,setUserRaisingHand] = useState(false);
    const [meetingInfoBox,setMeetingInfoBox] = useState(true);
    const [isRecording,setIsRecording] = useState(false);
    const [elapsedTime,setElapsedTime] = useState(0);

    const [recording,setRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [recordModalStart,setRecordModalStart] = useState(false);
    const [recordModalEnd,setRecordModalEnd] = useState(false);

    const [meetingModale,setMeetingModale] = useState(false);
    const [previewSendAnim,setPreviewSendAnim] = useState(false);
    const [raiseHandActive,setRaiseHandActive] = useState(false);
    const [btnRaiseHandActive,setBtnRaiseHandActive] = useState(false);
    const [muteActive,setMuteActive] = useState(false);
    const [cameraOn,setCameraOn] = useState(false);
    const [shareScreenActive,setShareScreenActive] = useState(false);
    const [chatBtnActive,setChatBtnActive] = useState(false);

    const [participantBtnActive,setParticipantBtnActive] = useState(false);
    const [statBtnActive,setStatBtnActive] = useState(false);

    const [soundBtnActive,setSoundBtnActive] = useState(false);
    const { isDevMode, setIsDevMode } = useAuthContext();

    const [allSettingsModale,setAllSettingsModale] = useState(false);


    const timer = useRef(null);

    const { removeActive } = props;

    
    const updateMobileOptionsState = (newState) => {
        setMobileOptions(newState);
    };

    const [panelHasActions,setPanelHasActions] = useState(false);

    const handlePanelState = () => {
        removeActive();
        let panel = document.querySelector('.devroom__body__side-panel');
        panel.style.display = "none";
    }

    const openPanel = (panelState) => {
        handlePanelState();
        let panel = document.querySelector('.devroom__body__side-panel');
        panel.style.display = "flex";
        props.setSidePanel(panelState);
    }

    
    const animShortCut = (e,s,containerRef) => {
            if (e.altKey && e.which == s) {
                setPreviewSendAnim(true);
                animateHeart(e, containerRef)
            }
    }

    const hideTimeout = (forceClear = false) => {

        if(timer.current) clearTimeout(timer.current);
        if(forceClear) return;
        console.trace('ici');
        timer.current = setTimeout(() => {

            setPreviewSendAnim(false);
            setRaiseHandActive(false);
            setBtnRaiseHandActive(false);

        }, 10000);
    }

    const handleShortcuts = () => {

        let isSpaceBarDown = false;
        let isCameraOn = false;
        let isMuteOn = false;
    
        document.onkeydown = (e) => {
            if (e.ctrlKey &&  e.keyCode == 32) {
                isSpaceBarDown = true;
                showTooltips(); // Afficher les tooltips immédiatement
            }
        };

        document.onkeyup = function(e) {
            // anim thumbs up 
            animShortCut(e, 84, thumbsUpContainerRef);
            animShortCut(e, 66, thumbsDownContainerRef);
            animShortCut(e, 67, clappingHandsContainerRef);
            animShortCut(e, 72, heartContainerRef);
            animShortCut(e, 76, laughContainerRef);
    
            // fermer panneau droit 
            if (e.altKey && e.keyCode == 88) {
                handlePanelState();
            }
            // ouvrir panneau participant 
            if (e.altKey && e.which == 80) {
                handlePanelState();
                openPanel('participants');
            }
            // ouvrir panneau chat 
            if (e.altKey && e.which == 85) {
                handlePanelState(); 
                openPanel('chat');
            }
            // ouvrir panneau stats 
            if (e.altKey && e.which == 83) {
                handlePanelState();
                openPanel('statistics');
            }
            if (e.ctrlKey && e.keyCode === 32) {
                isSpaceBarDown = false;
                hideTooltips(); // Masquer les tooltips lorsque la touche est relâchée
            }
            if (e.keyCode === 75) {
                if (e.altKey && e.keyCode === 75) {
                    isCameraOn = !isCameraOn; // Inverser l'état de isCameraOn
                    console.log("isCameraOn:", isCameraOn); // Affichage à des fins de débogage
                    setCameraOn((prevCameraState) => !prevCameraState); // Inverser l'état de isCameraOn
                }
            }
            if (e.altKey && e.keyCode === 77) {

                muteUnmute();
                setMuteActive((prevMuteState) => !prevMuteState); // Inverser l'état de muteActive
            }

        };

        function showTooltips() {
            // Exécute showTooltips à nouveau tant que la touche est maintenue enfoncée
            if (isSpaceBarDown) {
                requestAnimationFrame(showTooltips);
                setShowTooltips(true);
            }
        }
    
        function hideTooltips() {
            // Logique pour masquer les tooltips
            // console.log('Hide tooltips');
            setShowTooltips(false);
        }
    
    
    };


    const [showTooltips,setShowTooltips] = useState(false);



    let panel = document.querySelector('.devroom__body__side-panel');

    let draggableModal = new DraggableModal('.devroom__option-panel', '.action-frame__mobile__separator', 44,true,updateMobileOptionsState);

    const isSmallScreen = useMediaQuery('(max-width: 800px)');
    const isTabletOrLaptop = useMediaQuery('(max-width: 1540px)');



    useEffect(() => {
      const interval = setInterval(() => {
        setDuration((duration) => duration + 1);
      }, 1000);
      return () => clearInterval(interval);
    }, []);

    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);
  
    const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;


    const togglePanel = (event, sidePanel) => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
        });
    
        if (isSmallScreen) {
        handleMobileOptions();
        }
    
        removeActive();
        addActive(event);
    
        if (sidePanel === 'chat') {
        if (!chatBtnActive) {
            setChatBtnActive(!chatBtnActive);
        }
        if (participantBtnActive) {
            setParticipantBtnActive(!participantBtnActive);
        }
        if (statBtnActive) {
            setStatBtnActive(!statBtnActive);
        }
        } else if (sidePanel === 'participants') {
        if (chatBtnActive) {
            setChatBtnActive(!chatBtnActive);
        }
        if (!participantBtnActive) {
            setParticipantBtnActive(!participantBtnActive);
        }
        if (statBtnActive) {
            setStatBtnActive(!statBtnActive);
        }
        } else if (sidePanel === 'statistics') {
        if (participantBtnActive) {
            setParticipantBtnActive(!participantBtnActive);
        }
        if (chatBtnActive) {
            setChatBtnActive(!chatBtnActive);
        }
        if (!statBtnActive) {
            setStatBtnActive(!statBtnActive);
        }
        }
    
        panel.style.display = "flex";
        props.setSidePanel(sidePanel);
    }
    
    const toggleChatPanel = (event) => {
        togglePanel(event, 'chat');
    }
    
    const toggleParticipantPanel = (event) => {
        togglePanel(event, 'participants');
    }
    
    const toggleStatisticPanel = (event) => {
        togglePanel(event, 'statistics');
    }

    const toggleScreenLayoutPanel = (event) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if(isSmallScreen){
            handleMobileOptions();
        }
        removeActive();
        panel.style.display = "flex";
        props.setSidePanel('screen-layout');
    }

    const toggleVisualEffectPanel = (event) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if(isSmallScreen){
            handleMobileOptions();
        }
        removeActive();
        panel.style.display = "flex";
        props.setSidePanel('visual-effects');
    }

    const addActive = (e) => {
        let parent = e.currentTarget.parentElement;
        let tag = e.currentTarget.tagName.toLowerCase();
        if (tag === 'img' || tag === 'span') {
          parent.classList.add('active');
        } else {
          e.currentTarget.classList.add('active');
        }
      };

    const toggleActive = (e) => {
        let parent = e.target.parentElement;
        let tag = e.target.tagName.toLowerCase();
        if(tag === 'img' || tag === 'span'){
            parent.classList.toggle('active');
        } else {
            e.target.classList.toggle('active');
        }

        // handleActiveIcons();
    }

    const toggleHandActive = (e) => {
        let parent = e.target.parentElement;
        let tag = e.target.tagName.toLowerCase();
        if(tag === 'img' || tag === 'span'){
            parent.classList.toggle('active');
        } else {
            e.target.classList.toggle('active');
        }
    }

    
    useEffect(() => {

        // gestion du clic outside
        window.addEventListener('mousedown', (event) => {
            if (event.target.closest('.preview-send-anim-modale') !== null) {
            return;
            } else {
        
              const modal = document.querySelector('.preview-send-anim-modale__body');
              
              if (modal) {
                setPreviewSendAnim(false);
                setBtnRaiseHandActive(false);
                hideTimeout(true);
              }
            
            }
        });

        handleShortcuts();

    },[])



    const handleLaptopOptions = () => {
        setMoreLaptopOptions(!moreLaptopOptions);
    }

    const handleMobileOptions = () => {
        setMobileOptions(!mobileOptions);
        
    }

    const setNotif = (e) => {
        
        hideTimeout(previewSendAnim);
        setPreviewSendAnim(!previewSendAnim);
        setBtnRaiseHandActive(true);

    };

    const raiseMyHand = () => {
        let updatedParticipants = props.allParticipants.map(p => {
            if (!p.isExternal) {
              return {
                ...p,
                isRaisingHand: !p.isRaisingHand
              };
            }
            return p;
          });
          const p = [...participantRedux].filter(p=>!p.isExternal).map(p=>{return {...p,"isRaisingHand" :!p.isRaisingHand}}) ?? [];
          console.log("listsss",p);        
          setRaiseHandActive(!raiseHandActive);
          dispatch(participantUpdatedMany(p));
          // props.updateParticipants(updatedParticipants);
          setPanelHasActions(!panelHasActions);
          setUserRaisingHand(!userRaisingHand);
          setRaiseHandActive(!raiseHandActive);
    }


    const muteUnmute = (event) => {
        // alert('unmute');
        setSoundBtnActive(!soundBtnActive);
        setMuteActive(!muteActive);
        
        let updatedParticipants = props.allParticipants.map(p => {
            if (!p.isExternal) {

                return {
                    ...p,
                    isMuted: !p.isMuted
                };
            }
            return p;
        });


        const p = [...participantRedux].filter(p=>!p.isExternal).map(p=>{return {...p,"isMuted" :!p.isMuted}}) ?? [];
        console.log("list muted",p);
        dispatch(participantUpdatedMany(p));

    }



    const intervalRef = useRef(null);

    const recordVideo = () => {
        intervalRef.current = setInterval(() => {
            setElapsedTime((duration) => duration + 1);
        }, 1000);
    }

    const handleRecording = () => {
        setRecordModalStart(!recordModalStart);
    };
    
    const startRecording = () => {
        clearInterval(intervalRef.current);
        setElapsedTime(0);
        setIsRecording(true);
        recordVideo();
        setRecordModalStart(false);
    }


    const handleStopBtn = () => {

        if(recordModalEnd){
            setRecordModalEnd(false);
        }

        // j'affiche la boite record end 
        if(!isPaused){
            // au premier clic 
            setRecordModalEnd(true);
            setIsPaused(true);
        }

        if (isPaused) {
            setIsPaused(false);
            recordVideo();
        } else {
            clearInterval(intervalRef.current);
            setRecordModalEnd(true);
            setIsPaused(true);
        }
    };

    const stopRecording = () => {
        clearInterval(intervalRef.current);
        setIsRecording(false);
        setElapsedTime(0); // Réinitialisation du compteur
        setRecordModalEnd(false);
    };

    const closeModaleStart = () => {
        setIsRecording(false);
        setRecordModalStart(false);
    }
    
    const toggleMeetInfoPanel = () => {
        setMeetingModale(!meetingModale);
    }


    // animations 

    // const pop = (e) => {
    //     let amount = 10;
    //     if (e.clientX === 0 && e.clientY === 0) {
    //       const bbox = e.target.getBoundingClientRect();
    //       const x = bbox.left + bbox.width / 2;
    //       const y = bbox.top + bbox.height / 2;
    //       for (let i = 0; i < 30; i++) {
    //         createParticle(x, y, e.target.dataset.type);
    //       }
    //     } else {
    //       for (let i = 0; i < amount; i++) {
    //         createParticle(e.clientX, e.clientY + window.scrollY);
    //       }
    //     }
    // }

    // const createParticle = (x, y) => {
    // const particle = document.createElement('particle');
    // let container = document.querySelector('#test-mario');
    // container.appendChild(particle);
    // let width = Math.floor(Math.random() * 30 + 8);
    // let height = width;
    // let destinationX = (Math.random() - 0.5) * 300;
    // let destinationY = (Math.random() - 2) * 300;
    // let rotation = Math.random() * 520;
    // let delay = Math.random() * 200;
    
    // particle.style.backgroundImage = `url(${SoFunnySvg})`;
    
    // particle.style.width = `${width}px`;
    // particle.style.height = `${height}px`;
    // const animation = particle.animate([
    //     {
    //     transform: `translate(-50%, -50%) translate(${x}px, ${y}px) rotate(0deg)`,
    //     opacity: 1
    //     },
    //     {
    //     transform: `translate(-50%, -50%) translate(${x + destinationX}px, ${y + destinationY}px) rotate(${rotation}deg)`,
    //     opacity: 0
    //     }
    // ], {
    //     duration: Math.random() * 1000 + 5000,
    //     easing: 'cubic-bezier(0, .7, .72, 1)',
    //     delay: delay
    // });
    // animation.onfinish = removeParticle;
    // }

    // const removeParticle = (e) => {
    //     e.srcElement.effect.target.remove();
    // }
      


    const heartContainerRef = useRef();
    const thumbsUpContainerRef = useRef();
    const thumbsDownContainerRef = useRef();
    const clappingHandsContainerRef = useRef();
    const laughContainerRef = useRef();

    // heart emoji 

    const animateHeart = (event, ref) => {
        let item = event.currentTarget;
        // if(event.currentTarget){
        // Vérifier s'il existe déjà un clone
        let existingClone = document.querySelector('.animated-svg__item img');
    
        if(ref.current){
            if (existingClone) {
            // Réinitialiser le clone existant
            setTimeout(() => {
                existingClone.remove();
            }, 500);
                createNewClone(item, ref);
            } else {
                createNewClone(item, ref);
            }
        } else {
            // alert('no item');
            console.warn('no ref');
        }
        hideTimeout();
    };
      
    const createNewClone = (item,ref) => {

        if(ref.current){
            let clone = document.createElement('img');
            if(clone){
                switch (ref) {
                    case thumbsUpContainerRef:
                        clone.src = ThumbsUpSvg;
                        break;
                    case thumbsDownContainerRef:
                        clone.src = ThumbsDownSvg;
                        break;
                    case clappingHandsContainerRef:
                        clone.src = ClappingHandsSvg;
                        break;
                    case laughContainerRef:
                        clone.src = SoFunnySvg;
                        break;
                    default:
                        clone.src = ILoveItSvg;
                        break;                        
                }
                clone.style.position = 'absolute';
                clone.style.top = '0px';
                // clone.style.zIndex = '-100';
                clone.style.pointerEvents = 'none';
                clone.classList.add('clone');  
                const translateX = Math.random() * 200 - 100;
                const translateY = Math.random() * -300 - 300;
                const scale = Math.random() * 0.32 + 1.2;
                clone.classList.add('animate');
                ref.current.appendChild(clone);
                //Ajouter la classe avec un délai de 10 ms
                setTimeout(() => {
                    switch (ref) {
                    case thumbsUpContainerRef:
                        clone.classList.add('thumbs-up');
                        break;
                    case thumbsDownContainerRef:
                        clone.classList.add('thumbs-down');
                        break;
                    case heartContainerRef:
                        clone.style.transform = `translate(${translateX}px, ${translateY}px) scale(${scale})`;
                        break;
                    case laughContainerRef:
                        clone.style.transform = `translate(${translateX}px, ${translateY}px) scale(${scale})`;
                        break;
                    case clappingHandsContainerRef:
                        clone.classList.add('clapping-hands');
                        break;
                    // Ajoutez d'autres cas ici si nécessaire
                    default:
                        break;
                    }
                }, 10);
                switch (ref) {
                    case thumbsUpContainerRef:
                      setTimeout(() => {
                        removeClone(thumbsUpContainerRef);
                    }, 1500);
                    break;
                    case thumbsDownContainerRef:
                    setTimeout(() => {
                        removeClone(thumbsDownContainerRef);
                    }, 1500);
                    break;
                    case clappingHandsContainerRef:
                    setTimeout(() => {
                        removeClone(clappingHandsContainerRef);
                    }, 1500);
                    break;
                    case laughContainerRef:
                    setTimeout(() => {
                        removeClone(laughContainerRef);
                    }, 1500);
                    break;
                    case heartContainerRef:
                    setTimeout(() => {
                        removeClone(heartContainerRef);
                    }, 1500);
                    break;
                    default:
                    break;
                }
            } else {
                console.error('no clone');
            }
        } else {
            console.error('no ref');
        }

      
    };


    const removeClone = (ref) => {
        if(ref.current){
            const remainingImages = ref.current.querySelectorAll('img');
          
            let delay = 400; // Délai initial de 500 millisecondes
    
            if(remainingImages){
                remainingImages.forEach((img) => {
                  setTimeout(() => {
                    img.remove();
                  }, delay);
                  delay += 400; // Augmenter le délai de 500 millisecondes après chaque suppression
                });
            } else {
                 console.warn('error remaining images');
            }
        } else {
            console.warn('no ref');
        }
      
    }

    // thumbs up 

    // supprimer les element au mouseout 

    const handleMouseOut = (ref) => {
        const remainingImages = ref.current.querySelectorAll('img');
      
        let delay = 400; // Délai initial de 500 millisecondes
      
        remainingImages.forEach((img) => {
          setTimeout(() => {
            img.remove();
          }, delay);
          delay += 400; // Augmenter le délai de 500 millisecondes après chaque suppression
        });
    };
     
    const handleThumbsUp = (e) => {
        let item = e.currentTarget;
        createNewClone(item);
    }



    const handleCamera = () => {
        setCameraOn(!cameraOn);
    }

    // callback meeting info modale 

    const handleSettingsModale = () => {
        setAllSettingsModale(!allSettingsModale);
    }

    const isWindows = /Win/.test(navigator.userAgent);
    const panelClassName = mobileOptions ? 'devroom__option-panel --expanded' : 'devroom__option-panel';
    const finalClassName = isWindows ? `${panelClassName} --ms-panel` : panelClassName;
    

    const handleShareScreen = () => {
        setShareScreenActive(!shareScreenActive);
        let updatedParticipants = props.allParticipants.map(p => {
            if (!p.isExternal) {
              return {
                ...p,
                isSharing: !p.isSharing
              };
            }
            return p;
          });
          const p = [...participantRedux].filter(p=>!p.isExternal).map(p=>{return {...p,"isSharing" :!p.isSharing}}) ?? [];
          console.log("listsss",p);        
          dispatch(participantUpdatedMany(p));
          setPanelHasActions(!panelHasActions);
    }

    const handleDevMode = () => {
        // alert('ok');
        setIsDevMode(!isDevMode);
    }
    

    return (
        <>

        <div className='btn-frame' id="test">
            <button className="btn-regular" onClick={props.addEvent} style={!isDevMode ? { visibility: 'hidden' } : {}}>Add participant</button>
            <button className='btn-regular' onClick={props.removeEvent} style={!isDevMode ? { visibility: 'hidden' } : {}}>Remove participant</button>
        </div>
        <div className={finalClassName}>
            <div className='devroom__option-panel__left'>
                {!isTabletOrLaptop && !isSmallScreen && (
                    <>
                        <img src={RingoverLogo} alt='logotype ringover'/>
                        <hr/>
                    </>
                )}
                <MeetingInfoBox showTips={showTooltips} handleRoomInfoModale={toggleMeetInfoPanel}/>
                {meetingModale && (
                    <MeetingInfoModale meetingModale={meetingModale} toggleMeetingModale={toggleMeetInfoPanel}/>
                )}
            </div>

            {mobileOptions && (
                isSmallScreen && (
                    <div className='action-frame__mobile'>
                        {/* <div className=''>panneau extended</div> */}
                        <div className='action-frame__mobile__separator'><hr/></div>
                        <ul className='action-frame__mobile__options'>
                            <li onClick={toggleParticipantPanel}>
                                <img src={UserGroupSvg} alt='panneau participant'/>
                                <span>{props.participants} participants</span>
                            </li>
                            <li onClick={toggleChatPanel}>
                                <img src={ChatSvg} alt='panneau chat'/>
                                <span>Messages</span>
                            </li>
                            <li onClick={toggleStatisticPanel}>
                                <img src={VoiceSettingSvg} alt='panneau stats'/>
                                <span>Statistics</span>
                            </li>
                            <li>
                                <img src={UploadFolderSvg} alt='panneau screen share'/>
                                <span>Screen share</span>
                            </li>
                            <li onClick={toggleVisualEffectPanel}>
                                <img src={ThreeStarsSvg} alt='effets visuels'/>
                                <span>Visual effects</span>
                            </li>
                            <li onClick={toggleScreenLayoutPanel}>
                                <img src={MosaicSvg} alt='screen layout'/>
                                <span>Screen layout</span>
                            </li>
                            <li onClick={handleDevMode}>
                                <img src={CodingSvg} alt='devmode'/>
                                <span>{isDevMode ? 'Disable dev mode' : 'Enable dev mode'}</span>
                                
                            </li>
                            <li>
                                <span>{formattedDuration}</span>
                            </li>
                        </ul>
                    </div>
                )
            )}

            {!mobileOptions && (
                <div className='devroom__option-panel__center'>
                    <div className='action-frame'>
                        <button className={soundBtnActive ?  'btn-square active' : 'btn-square'} data-size="large" onClick={muteUnmute}>
                            {muteActive ? (
                                <img src={MicroPurpleSvg} alt="micro"/>
                            ) : (
                                <img src={MicroSvg} alt="micro"/>
                            )}
                            {showTooltips && (<Tooltip content="microphone" topOffset='-32px'/>)}
                        </button>
                        <button id="camera-icon" className={cameraOn ? 'btn-square camera-active' : 'btn-square'}  data-size="large" onClick={handleCamera}>
                            {cameraOn ? (
                                <img src={CameraSlashedSvg} alt="camera slashed"/>
                            ) : (
                                <img src={CameraSvg} alt="camera"/>        
                            )}
                            {showTooltips && (<Tooltip content="camera" topOffset='-32px'/>)}
                        </button>

                        {!isSmallScreen && (
                            <div className='record-container'>
                                {isRecording ? (
                                    <div className='btn-record' onClick={handleStopBtn}>
                                        <div className='btn-record__content'>
                                        {!isPaused && <span id="record-notif"></span>}
                                        <span>{formatTime(elapsedTime)}</span>
                                        {!isPaused ? (
                                            <span id="stop-btn">
                                                <img src={StopSvg} alt='stop'/>
                                            </span>
                                        ) : (
                                            <span id="pause-btn">
                                                <img src={PauseSvg} alt='pause'/>
                                            </span>
                                        )}
                                        </div>
                                    </div>
                                    ) : (
                                    <button className={!recordModalStart ? 'btn-square' : 'btn-square active'} data-size="large" onClick={handleRecording}>
                                        {!recordModalStart ? (
                                            <img src={RecordSvg} alt="record"/>
                                        ) : (
                                            <img src={RecordSvgActive} alt="record"/>         
                                        )}
                                        {showTooltips && (<Tooltip content="record" topOffset='-32px'/>)}
                                    </button>
                                )}
                                    {recordModalStart && (
                                        <div className='record-modal --start'>
                                            <div className='record-modal__header'  data-size="large">
                                                <div className='heading'>
                                                    <img id="record-icon" src={RecordSvg} alt="record icon"/>
                                                    <h2>record your meeting</h2>
                                                </div>
                                                <div data-interactive={true}>
                                                    <img src={CrossSvg} alt="fermer" onClick={closeModaleStart}/>
                                                </div>
                                            </div>
                                            <div className='record-modal__body'>
                                                <h5>Do you want to start recording ?</h5>
                                                <p>Select “Start” to record your meeting. Your recordings are saved in the Meet logs. You can download them once you stop recording.</p>
                                            </div>
                                            <div className='record-modal__footer'>
                                                <div className='btn-duo-frame'>
                                                    <BtnRegular content="Cancel" id="record-modal-cancel" event={closeModaleStart}/>
                                                    <BtnRegular content="Start" id="record-modal-start" event={startRecording}/>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {recordModalEnd && (
                                        <div className='record-modal --end'>
                                            <div className='record-modal__header' data-size="large">
                                                <div className='heading'>
                                                    <img id="record-icon" src={RecordSvg} alt="record icon"/>
                                                    <h2>stop recording</h2>
                                                </div>  
                                                <img src={CrossSvg} alt="fermer" onClick={handleStopBtn}/>
                                            </div>
                                            <div className='record-modal__body'>
                                                <h5>Are you sure you want to stop recording?</h5>
                                                <p>Your recording will be saved in the Ringover Meet logs in the dashboard. You'll receive an email when it's ready.</p>
                                            </div>
                                            <div className='record-modal__footer'>
                                                <div className='btn-duo-frame'>
                                                    <BtnRegular content="Resume" id="record-modal-cancel" event={handleStopBtn}/>
                                                    <BtnRegular content="Stop" id="record-modal-start" event={stopRecording}/>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}



                        <button className={userRaisingHand ? 'btn-square --info' : (btnRaiseHandActive ? 'btn-square active' : 'btn-square')} data-size="large" onClick={setNotif}>
                            {raiseHandActive ? (
                                <img src={RaiseHandSvg} alt="raise hand" />
                            ) : (
                                previewSendAnim ? (
                                    <img src={RaiseHandPurpleSvg} alt="raise hand" />
                                    ) : (
                                    <img src={RaiseHandWhiteSvg} alt="raise hand" />
                                )
                            )}
                            
                        {showTooltips && <Tooltip content="greetings" topOffset="-32px" />}
                        </button>

                        {previewSendAnim && (
                            <div className='preview-send-anim-modale' onMouseEnter={() => hideTimeout(true)} onMouseLeave={() => hideTimeout()}>
                                <div className='preview-send-anim-modale__body'>
                                    <div className='preview-send-anim-modale__frame'>
                                        <div className='animated-svg'>
                                            <div className='animated-svg__item' id="thumbs-up" onClick={(e) => animateHeart(e, thumbsUpContainerRef)} ref={thumbsUpContainerRef}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.23438 4.14848C9.23438 1.97348 10.8281 1.46098 11.7188 1.4766C13.5281 1.4766 14.0305 2.98335 14.1797 3.78517C14.3652 4.78219 14.1837 7.16697 14.1003 8.26179L14.098 8.29302C14.217 8.26515 14.3456 8.25002 14.4844 8.25002H17.3775C18.5475 8.25002 19.5 9.20252 19.5 10.3725C19.5 10.9771 19.306 11.5235 18.9259 11.9106C19.7092 12.2561 20.25 12.9814 20.25 13.8178C20.25 14.585 19.903 15.2587 19.2268 15.6316C19.8443 16.0148 20.25 16.6559 20.25 17.3803C20.25 18.2111 19.843 18.9323 19.0518 19.2806C19.3325 19.57 19.5 20.0008 19.5 20.6262C19.5 21.6604 18.7734 22.5024 17.3775 22.5024H14.4844C14.4503 22.5024 14.4168 22.5016 14.3837 22.5H12.0352C4.20703 22.6289 3.75 17.5547 3.75 15.4922C3.75 11.6251 5.47345 10.4756 7.00547 9.45379C7.72977 8.97068 8.41128 8.51612 8.84766 7.81642C9.21378 7.22936 9.23438 6.69142 9.23438 4.14848Z" fill="#FFC83D"/>
                                                    <path d="M14.25 8.25C13.4844 8.27344 11.25 8.69531 11.25 10.4766C11.25 11.4141 11.6016 11.9414 12.0234 12.2344C11.6016 12.4219 11.2266 13.118 11.2266 13.9805C11.2266 14.9414 12.0234 15.6445 12.0234 15.6445C12.0234 15.6445 11.2266 16.418 11.2266 17.3438C11.2266 18.0427 11.533 18.5585 12.0234 18.91C11.7578 19.0598 11.2266 19.5797 11.2266 20.4609C11.2266 21.5118 12.2077 22.392 14.1294 22.4708C13.1931 22.2975 12.7383 21.4397 12.7383 20.6262C12.7383 20.3573 12.8389 19.9256 13.0886 19.5441H17.25C17.4571 19.5441 17.625 19.3762 17.625 19.1691C17.625 18.962 17.4571 18.7941 17.25 18.7941H13.1917C12.8878 18.3965 12.7383 17.881 12.7383 17.3803C12.7383 17.0688 12.8555 16.5641 13.1478 16.125H17.25C17.4571 16.125 17.625 15.9571 17.625 15.75C17.625 15.5429 17.4571 15.375 17.25 15.375H13.313C12.9276 14.9632 12.7383 14.3811 12.7383 13.8178C12.7383 13.5499 12.825 13.1391 13.0352 12.75H17.25C17.4571 12.75 17.625 12.5821 17.625 12.375C17.625 12.1679 17.4571 12 17.25 12H13.3701C12.9476 11.5526 12.7383 10.8873 12.7383 10.3725C12.7383 9.76498 13.07 8.42269 14.2502 8.26518L14.25 8.25Z" fill="#D67D00"/>
                                                </svg>
                                            </div>
                                            <div className='animated-svg__item' id="thumbs-down" onClick={(e) => animateHeart(e, thumbsDownContainerRef)} ref={thumbsDownContainerRef}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.7656 19.8302C14.7656 22.0052 13.1719 22.5177 12.2812 22.5021C10.4719 22.5021 9.96949 20.9953 9.82031 20.1935C9.63482 19.1965 9.81634 16.8117 9.89967 15.7169L9.90205 15.6857C9.78302 15.7135 9.65443 15.7287 9.51562 15.7287H6.6225C5.4525 15.7287 4.5 14.7762 4.5 13.6062C4.5 13.0016 4.69398 12.4551 5.07406 12.0681C4.2908 11.7226 3.75 10.9973 3.75 10.1609C3.75 9.39366 4.09705 8.71999 4.77323 8.34706C4.15569 7.96387 3.75 7.32281 3.75 6.59836C3.75 5.76756 4.15698 5.04642 4.9482 4.69812C4.66746 4.40868 4.5 3.97788 4.5 3.35247C4.5 2.31824 5.22656 1.47627 6.6225 1.47627L9.51562 1.47627C9.54967 1.47627 9.58322 1.47707 9.61625 1.47867H11.9648C19.793 1.34976 20.25 6.42398 20.25 8.48648C20.25 12.3535 18.5266 13.5031 16.9945 14.5249C16.2702 15.008 15.5887 15.4626 15.1523 16.1623C14.7862 16.7493 14.7656 17.2873 14.7656 19.8302Z" fill="#FFC83D"/>
                                                    <path d="M10.7957 5.18458H6.75C6.54289 5.18458 6.375 5.01668 6.375 4.80958C6.375 4.60247 6.54289 4.43458 6.75 4.43458H10.9114C11.1611 4.05303 11.2617 3.62134 11.2617 3.35247C11.2617 2.539 10.8069 1.68119 9.87063 1.50784C11.7923 1.58664 12.7734 2.46687 12.7734 3.51773C12.7734 4.39898 12.2422 4.91888 11.9766 5.06867C12.467 5.4202 12.7734 5.93595 12.7734 6.63492C12.7734 7.5607 11.9766 8.33414 11.9766 8.33414C11.9766 8.33414 12.7734 9.03726 12.7734 9.9982C12.7734 10.8607 12.3984 11.5568 11.9766 11.7443C12.3984 12.0373 12.75 12.5646 12.75 13.5021C12.75 15.2834 10.5156 15.7052 9.74999 15.7287L9.74982 15.7135C10.93 15.556 11.2617 14.2137 11.2617 13.6062C11.2617 13.0914 11.0524 12.426 10.6299 11.9787H6.75C6.54289 11.9787 6.375 11.8108 6.375 11.6037C6.375 11.3966 6.54289 11.2287 6.75 11.2287H10.9648C11.175 10.8396 11.2617 10.4288 11.2617 10.1609C11.2617 9.59755 11.0724 9.01546 10.687 8.60366H6.75C6.54289 8.60366 6.375 8.43577 6.375 8.22866C6.375 8.02155 6.54289 7.85366 6.75 7.85366H10.8522C11.1445 7.41456 11.2617 6.90987 11.2617 6.59835C11.2617 6.24855 11.157 5.65515 10.7957 5.18458Z" fill="#D67D00"/>
                                                </svg>
                                            </div>

                                            <div className='animated-svg__item' id="clapping-hands" onClick={(e) => animateHeart(e, clappingHandsContainerRef)} ref={clappingHandsContainerRef}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="left-hand">
                                                        <path id="left-hand" d="M18.1966 16.8356L18.0806 9.7931C18.0063 9.12481 17.6397 8.22681 16.3867 8.22681C14.9248 8.29642 14.8668 9.7931 14.8668 9.7931L12.6392 5.81357C12.0822 4.96661 11.1689 4.90766 10.5972 5.25665C10.0254 5.60565 9.90104 6.31246 9.90104 6.31246L9.43695 5.70914C9.08796 5.13739 8.04358 5.0708 7.51099 5.39589C6.62701 5.93546 6.49856 7.05264 7.07011 7.66991C7.03262 7.62942 6.59581 7.47815 5.86348 7.92516C5.29173 8.27415 5.11512 9.31746 5.43419 9.90913C5.75325 10.5008 5.79966 10.5936 5.79966 10.5936C5.79966 10.5936 5.30657 10.3616 4.52922 10.8837C3.97315 11.2571 3.77415 12.3655 4.12314 12.9373L5.99527 16.0938L8.57188 20.304C10.0867 22.451 13.3164 23.1924 15.6631 21.7668C17.2633 20.7948 18.349 18.7406 18.1966 16.8356Z" fill="#F59F00"/>
                                                        <path d="M9.97015 6.42286L9.90139 6.3122C9.90374 6.29915 9.98324 5.86869 10.2905 5.5174L10.6071 6.02692C10.8579 6.43041 11.1977 6.97668 11.5536 7.54782C12.2665 8.69201 13.0406 9.9306 13.2962 10.3256C13.4087 10.4995 13.359 10.7317 13.1851 10.8442C13.0112 10.9567 12.7791 10.9069 12.6665 10.7331C12.4065 10.3312 11.6279 9.0854 10.917 7.94444C10.561 7.373 10.221 6.82649 9.97015 6.42286Z" fill="#D67D00"/>
                                                        <path d="M7.03334 7.64912C6.97325 7.63275 6.91669 7.62183 6.86393 7.61496C6.94175 7.6206 6.9987 7.63528 7.03334 7.64912Z" fill="#D67D00"/>
                                                        <path d="M7.05768 7.65599C7.26762 7.71737 7.51907 7.84536 7.80184 8.09671C7.8233 8.11579 7.87343 8.17255 7.95369 8.2854C8.0293 8.3917 8.11836 8.5286 8.21801 8.69007C8.41714 9.01271 8.64911 9.41798 8.88613 9.84086C9.01064 10.063 9.13724 10.2913 9.261 10.5146C9.60104 11.1279 9.91971 11.7027 10.115 12.0045C10.2276 12.1784 10.4597 12.2282 10.6336 12.1157C10.8075 12.0031 10.8572 11.771 10.7447 11.5971C10.5644 11.3184 10.2673 10.7825 9.93371 10.181C9.80643 9.95142 9.67381 9.71223 9.54037 9.47416C9.30252 9.0498 9.06413 8.63301 8.85624 8.29616C8.75238 8.12788 8.65376 7.97568 8.56487 7.8507C8.48062 7.73226 8.38943 7.61555 8.30011 7.53615C7.70328 7.00564 7.15322 6.85863 6.7276 6.85521C6.74816 7.15191 6.85901 7.43478 7.05768 7.65599Z" fill="#D67D00"/>
                                                        <path d="M5.76784 10.5814C5.68377 10.5631 5.60401 10.5506 5.53216 10.5429C5.64627 10.5468 5.72653 10.5677 5.76784 10.5814Z" fill="#D67D00"/>
                                                        <path d="M5.79712 10.588C5.82066 10.5935 5.8445 10.5994 5.86857 10.6058C6.11278 10.6709 6.33782 10.7745 6.48934 10.9092C6.56533 10.9767 6.68033 11.1158 6.82848 11.3258C6.97183 11.529 7.13071 11.776 7.29504 12.0408C7.42783 12.2547 7.56637 12.484 7.70147 12.7077C7.89263 13.0241 8.07695 13.3291 8.22832 13.5631C8.34084 13.737 8.573 13.7867 8.74688 13.6742C8.92076 13.5617 8.97051 13.3295 8.858 13.1557C8.70993 12.9268 8.53889 12.6435 8.35518 12.3392C8.21975 12.1149 8.07734 11.879 7.93226 11.6453C7.76526 11.3762 7.59734 11.1146 7.44134 10.8935C7.29013 10.6791 7.1332 10.478 6.98761 10.3486C6.71726 10.1083 6.37003 9.96322 6.06151 9.88108C5.821 9.81705 5.57848 9.78457 5.37646 9.78311C5.39355 9.82696 5.41289 9.86909 5.43449 9.90915C5.71211 10.424 5.78331 10.5611 5.79712 10.588Z" fill="#D67D00"/>
                                                    </g>
                                                    <g id="right-hand">
                                                        <path d="M22.4647 16.7787V9.66544C22.3897 8.99044 21.9022 8.0834 20.6366 8.0834C19.1601 8.15372 19.1015 9.66544 19.1015 9.66544L16.8515 5.64592C16.289 4.79044 15.3665 4.73091 14.789 5.08341C14.2115 5.43591 14.0858 6.14982 14.0858 6.14982L13.6171 5.54044C13.2646 4.96294 12.2097 4.89569 11.6718 5.22404C10.7789 5.76903 10.6492 6.89744 11.2265 7.52091C11.1886 7.48002 10.7474 7.32723 10.0077 7.77873C9.43022 8.13123 9.25183 9.18502 9.5741 9.78264C9.89638 10.3802 9.94325 10.474 9.94325 10.474C9.94325 10.474 9.44521 10.2396 8.66005 10.767C8.09839 11.1442 7.89739 12.2637 8.24989 12.8412L10.1408 16.0295L12.7433 20.282C14.2733 22.4506 17.5355 23.1994 19.9058 21.7595C21.5221 20.7777 22.6187 18.7029 22.4647 16.7787Z" fill="#FFC83D"/>
                                                        <path d="M14.0885 6.13755L14.0983 6.15332C14.3492 6.55695 14.6892 7.10346 15.0452 7.6749C15.7561 8.81586 16.5347 10.0616 16.7947 10.4635C16.9072 10.6374 17.1394 10.6871 17.3133 10.5746C17.4872 10.4621 17.5369 10.23 17.4244 10.0561C17.1688 9.66106 16.3947 8.42247 15.6818 7.27828C15.3259 6.70714 14.9861 6.16087 14.7353 5.75738L14.4798 5.34613C14.1951 5.67124 14.1037 6.06374 14.0885 6.13755Z" fill="#D67D00"/>
                                                        <path d="M11.1668 7.49185C11.0505 7.46987 10.9489 7.4635 10.8688 7.46599C11.0069 7.45585 11.1075 7.47287 11.1668 7.49185Z" fill="#D67D00"/>
                                                        <path d="M12.1319 7.98456C11.8052 7.6941 11.4726 7.55701 11.208 7.50016C11.0149 7.28214 10.9055 7.00591 10.8818 6.71563C11.3302 6.71093 12.0041 6.86753 12.6302 7.42401C12.7845 7.56116 12.9801 7.83589 13.1739 8.12984C13.3781 8.43969 13.6115 8.82053 13.8445 9.2095C14.0184 9.49977 14.191 9.79275 14.3524 10.0667C14.6296 10.537 14.8736 10.9511 15.0338 11.1986C15.1463 11.3725 15.0965 11.6047 14.9227 11.7172C14.7488 11.8297 14.5166 11.7799 14.4041 11.6061C14.236 11.3463 13.9768 10.9064 13.692 10.4232C13.5326 10.1528 13.365 9.86856 13.2011 9.5949C12.9695 9.20821 12.7428 8.83857 12.5477 8.54262C12.3421 8.23077 12.1992 8.04437 12.1319 7.98456Z" fill="#D67D00"/>
                                                        <path d="M10.0309 10.4897C9.9969 10.4759 9.96394 10.4633 9.93217 10.4518C9.90491 10.3994 9.81893 10.236 9.57443 9.78263C9.54475 9.72759 9.51931 9.66869 9.49805 9.60682C9.74796 9.60349 10.0482 9.68715 10.3133 9.79487C10.6178 9.91863 10.9438 10.1019 11.1947 10.3249C11.3465 10.4598 11.5059 10.6792 11.6557 10.9081C11.8117 11.1463 11.9795 11.4304 12.146 11.7208C12.2471 11.8971 12.3472 12.0747 12.4446 12.2475C12.6745 12.6553 12.889 13.036 13.0651 13.308C13.1776 13.4819 13.1278 13.714 12.9539 13.8266C12.7801 13.9391 12.5479 13.8893 12.4354 13.7154C12.2462 13.4231 12.0127 13.0088 11.7771 12.5909C11.6826 12.4232 11.5878 12.255 11.4954 12.0939C11.3306 11.8065 11.1716 11.538 11.0282 11.3188C10.8786 11.0903 10.7662 10.9475 10.6964 10.8855C10.5254 10.7335 10.2791 10.5906 10.0309 10.4897Z" fill="#D67D00"/>
                                                    </g>
                                                    <path d="M19.8028 10.905C19.6736 10.6822 19.4133 10.2177 19.1022 9.65816C19.106 9.59411 19.1433 9.11941 19.4322 8.70735C19.8801 9.51559 20.2826 10.2374 20.4515 10.5287C20.5555 10.7078 20.4945 10.9373 20.3153 11.0412C20.1361 11.1451 19.9067 11.0841 19.8028 10.905Z" fill="#D67D00"/>
                                                    <path d="M6.03889 3.6128L5.12389 2.37414C4.98139 2.1806 5.07139 1.90188 5.29639 1.83221L6.31639 1.51481C6.54139 1.44513 6.76639 1.63093 6.75139 1.87092L6.64639 3.42701C6.61639 3.74442 6.22639 3.86828 6.03889 3.6128Z" fill="#46A4FB"/>
                                                    <path d="M4.26889 4.82932L2.87389 4.24433C2.65639 4.15433 2.59639 3.87682 2.76139 3.70432L3.48889 2.93183C3.64639 2.75933 3.93139 2.80433 4.03639 3.01433L4.70389 4.37182C4.83139 4.64932 4.55389 4.94932 4.26889 4.82932Z" fill="#46A4FB"/>
                                                    <path d="M1.88984 7.01923L3.44346 6.81689C3.76195 6.77798 3.86293 6.36551 3.59882 6.17873L2.30155 5.3071C2.09958 5.1748 1.81993 5.28374 1.76555 5.51722L1.5092 6.5912C1.45483 6.83245 1.64903 7.05036 1.88984 7.01923Z" fill="#46A4FB"/>
                                                </svg>
                                            </div>

                                            <div className='' id="test-mario" data-type="square" onClick={(e) => animateHeart(e, laughContainerRef)} ref={laughContainerRef}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.9992 22.4983C19 22.4983 22.4983 17.7977 22.4983 11.9992C22.4983 6.20064 19 1.5 11.9992 1.5C4.99833 1.5 1.5 6.20064 1.5 11.9992C1.5 17.7977 4.99833 22.4983 11.9992 22.4983Z" fill="#FFB02E"/>
                                                    <path d="M6.73635 8.39315C6.77241 8.20769 6.85496 7.98038 7.0119 7.80939C7.1464 7.66286 7.38542 7.5 7.87514 7.5C8.35643 7.5 8.60155 7.66624 8.74306 7.82159C8.90667 8.0012 8.98549 8.23026 9.0112 8.37832C9.08207 8.78643 9.47035 9.05981 9.87846 8.98894C10.2866 8.91807 10.56 8.52979 10.4891 8.12168C10.4298 7.78048 10.2601 7.25955 9.85196 6.81147C9.4217 6.33913 8.7718 6 7.87514 6C6.98691 6 6.3397 6.32347 5.90682 6.7951C5.49638 7.24228 5.33041 7.76496 5.26393 8.10685C5.18487 8.51345 5.45039 8.90715 5.85699 8.98621C6.26359 9.06527 6.65729 8.79975 6.73635 8.39315Z" fill="#402A32"/>
                                                    <path d="M14.9865 8.39315C15.0226 8.20769 15.1051 7.98038 15.2621 7.80939C15.3966 7.66286 15.6356 7.5 16.1253 7.5C16.6066 7.5 16.8517 7.66624 16.9932 7.82159C17.1569 8.0012 17.2357 8.23026 17.2614 8.37832C17.3323 8.78643 17.7205 9.05981 18.1286 8.98894C18.5367 8.91807 18.8101 8.52979 18.7393 8.12168C18.68 7.78048 18.5103 7.25955 18.1021 6.81147C17.6719 6.33913 17.022 6 16.1253 6C15.2371 6 14.5899 6.32347 14.157 6.7951C13.7466 7.24228 13.5806 7.76496 13.5141 8.10685C13.4351 8.51345 13.7006 8.90715 14.1072 8.98621C14.5138 9.06527 14.9075 8.79975 14.9865 8.39315Z" fill="#402A32"/>
                                                    <path d="M12 18.75C5.25 18.75 5.25 12 5.25 12H18.75C18.75 12 18.75 18.75 12 18.75Z" fill="#BB1D80"/>
                                                    <path d="M6 12.375V12H18V12.375C18 12.7892 17.6642 13.125 17.25 13.125H6.75C6.33579 13.125 6 12.7892 6 12.375Z" fill="white"/>
                                                    <path d="M3.47283 15.2773L6.00018 12.75C6.6215 12.1287 6.6215 11.1213 6.00018 10.5C5.37886 9.87867 4.3715 9.87867 3.75018 10.5L1.22283 13.0273C0.637733 13.6124 0.598804 14.5484 1.13329 15.18C1.73453 15.8906 2.81466 15.9355 3.47283 15.2773Z" fill="#3F5FFF"/>
                                                    <path d="M20.7151 15.2773L18.1877 12.75C17.5664 12.1287 17.5664 11.1213 18.1877 10.5C18.8091 9.87867 19.8164 9.87867 20.4377 10.5L22.9651 13.0273C23.5502 13.6124 23.5891 14.5484 23.0546 15.18C22.4534 15.8906 21.3733 15.9355 20.7151 15.2773Z" fill="#3F5FFF"/>
                                                </svg>
                                            </div>

                                            <div className='animated-svg__item' id="heart" onClick={(e) => animateHeart(e, heartContainerRef)} onMouseOut={()=>handleMouseOut(heartContainerRef)} ref={heartContainerRef}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.49978 4.5C7.99836 2.75072 10.8748 4.875 11.9998 6.375C13.1248 4.875 16.0012 2.75073 19.4998 4.5C23.9998 6.74999 22.8748 12.375 19.4998 15.75C17.853 17.3968 14.9527 20.2971 12.8314 21.9103C12.3413 22.283 11.6709 22.2663 11.1918 21.8796C9.2473 20.31 6.12356 17.3738 4.49978 15.75C1.12477 12.375 -0.000217321 6.75 4.49978 4.5Z" fill="#F8312F"/>
                                                    <path d="M11.9998 6.37499V8.66186C12.952 6.64876 15.3187 3.96038 19.2432 4.37809C15.861 2.85467 13.0971 4.91181 11.9998 6.37499Z" fill="#CA0B4A"/>
                                                    <path d="M8.95905 4.15268C7.6959 3.71254 6.16862 3.66559 4.49978 4.5C-0.000217078 6.75 1.12477 12.375 4.49978 15.75C6.12356 17.3738 9.2473 20.31 11.1918 21.8796C11.6709 22.2663 12.3413 22.283 12.8314 21.9103C13.022 21.7653 13.219 21.6099 13.4207 21.446C11.2797 19.8393 8.27504 17.3071 6.64411 15.8494C2.92651 12.5265 1.68733 6.98841 6.64411 4.77319C7.44947 4.41327 8.2249 4.2199 8.95905 4.15268Z" fill="#CA0B4A"/>
                                                    <ellipse cx="17.6079" cy="9.4453" rx="2.12666" ry="3.58594" transform="rotate(30 17.6079 9.4453)" fill="#F37366"/>
                                                </svg>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='preview-send-anim-modale_button'>
                                        <button className="btn-icon btn-regular" onClick={raiseMyHand}>
                                            <img src={RaiseHandSvg} alt="raise hand"/>
                                            <span>Raise my hand </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!isSmallScreen && (
                            <button className={shareScreenActive ? 'btn-square active --info' : 'btn-square'} id="share screen" data-size="large" onClick={handleShareScreen}>
                                {shareScreenActive ? (
                                    <img src={ShareScreenActiveSvg} alt="share screen"/>
                                ) : (
                                    <img src={UploadFolderSvg} alt="share screen"/>
                                )}
                                {showTooltips && (<Tooltip content="share screen" topOffset='-32px'/>)}
                            </button>
                        )}
                        
                        
                        {!isSmallScreen && (
                            <>
                                <button className='btn-square' data-size="large" onClick={handleSettingsModale}>
                                    <img src={SettingsSvg} alt="settings"/>
                                    {showTooltips && (<Tooltip content="settings" topOffset='-32px'/>)}
                                </button>
                                <hr/>
                            </>
                        )}

                        <button className='btn-square' data-size="large">
                            <img src={HangUpSvg} alt="hang up"/>
                            {showTooltips && (<Tooltip content="hang up" topOffset='-32px'/>)}
                        </button>
                        {isSmallScreen && (
                            <SquareBtn icon={ThreeDotsSvg} size='large' event={handleMobileOptions}/>
                        )}

                    </div>
                </div>
            )}

            <div className='devroom__option-panel__right'>
                
                <div className='time-elapsed'>
                    <span>{formattedDuration}</span>
                </div>
                <hr/>
                <div className='action-frame'>

                <button
                    className='btn-icon__usergroup'
                    data-size='medium'
                    data-interactive={false}
                    onClick={toggleParticipantPanel}>
                        {showTooltips && (<Tooltip content="participants" topOffset='-32px'/>)}
                        {participantBtnActive ? (
                            <img src={UserGroupActiveSvg} alt='panneau participant' id="participant-icon"/>
                        ) : (
                            <img src={UserGroupSvg} alt='panneau participant' id="participant-icon"/>
                        )}
                    <span id='participant-btn'>{props.participants}</span>

                    {/* si un participant effectue une action on passe panelHasActions a true  */}

                    {panelHasActions && (
                        <span id="participant-panel-notif" className='dot --danger'></span>
                    )}
                </button>

                    {!isSmallScreen && (
                        <>
                            <button className='btn-square' onClick={toggleChatPanel} data-size="large">
                                {chatBtnActive ? (
                                    <img src={ChatActiveSvg} alt="chat" id="chat-icon"/>
                                ) : (
                                    <img src={ChatSvg} alt="chat" id="chat-icon"/>
                                )}
                            {showTooltips && <Tooltip content="chat" topOffset='-32px' />}
                            </button>
                            <button className='btn-square' onClick={toggleStatisticPanel} data-size="large">
                                {statBtnActive ? (<img src={VoiceSettingActiveSvg} alt="stats" id="stat-icon"/>) : (<img src={VoiceSettingSvg} alt="stats active" id="stat-icon"/>) }                                
                                {showTooltips && (<Tooltip content="stats" topOffset='-32px'/>)} 
                            </button>
                            {/* <SquareBtn icon={PuzzleSvg} size='large' event={props.setTheMode}/> */}
                        </>
                    )}


                </div>
            </div>



        </div>
        {allSettingsModale && (
            <AllSettingsModal event={handleSettingsModale}/>
        )}



        </>
    );
};

export default RoomBottomPanel;