import React from "react";
import AddSvg from '../assets/icons/plus-icon.svg';
import RemoveSvg from '../assets/icons/minus-icon.svg';
import ProfileSvg from '../assets/pictures/portrait.jpg';

const textColor = "#FFFFFF";

const UserItem = ({ isToAdd, picture, givenName, familyName, onClick, type, initial, color }) => (
  <div className={`user-item ${isToAdd ? 'user-item__is-to-add' : 'user-item__is-added'}`} onClick={onClick}>
    <div className='user-item__profil'>
      {type === 'team' ? (
        <div className='user-item__profil'>
          {picture ? (
            <img className='user-item__profil__picture' src={picture} width='30' height='30' alt='' />
          ) : (
            <div className="options__avatar">
              <div style={{ backgroundColor: color, height: "36px", width: "36px", justifyContent: "center", alignItems: "center" }}  className="round-pill contact">
                <p style={{color: textColor }}>{initial ? initial : '?'}</p>
              </div>
            </div>
          )}
          <p className='user-item__profil__name'>
            {givenName} {familyName}
          </p>
        </div>
      ) : (
        <>
          <div className="options__avatar">
            <div  style={{ backgroundColor: color, height: "36px", width: "36px", justifyContent: "center", alignItems: "center"  }}  className="round-pill contact">
            <p style={{color: textColor }}>{initial ? initial : '?'}</p>
            </div>
          </div>
          <p className='user-item__profil__name'>
            {givenName} {familyName}
          </p>
        </>
      )}
    </div>
    <img alt='user-item__operate' className='user-item__operate' src={isToAdd ? AddSvg : RemoveSvg} width={22} style={{ cursor: 'pointer' }} />
  </div>
);

export default UserItem;
