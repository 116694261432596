import React from "react";
import PrejoinWithOnlyJoinButton from "./withOnlyJoinButton";
import PrejoinWithIdentification from "./withIdentification";
import PrejoinWithPassword from "./withPassword";
import PrejoinWithRequest from "./withRequest";
import PrejoinWithWaitHost from "./withWaitHost";
import { RequestStatus } from "../../pages/Preview";
import useAuthContext from "../../hooks/useAuth";

const PrejoinContainer = ({
    isHost,
    needPassword,
    password,
    hasCorrectPassword,
    setHasCorrectPassword,
    needRequest,
    uuid,
    hostEmail,
    hostUUID,
    onJoinMeet,
    needWaitHost,
    requestStatus,
    setRequestStatus,
    isJoined,
    isHostJoin,
    joinAutomated,
    setJoinAutomated,
    nextRequestInterval,
    setNextRequestInterval,
    sendRequestTimesReste,
    setSendRequestTimesReste
}) => {
    
    const { userName } = useAuthContext();
  

    if (isJoined) {
        return (<p>show meet</p>);
    }

    if (isHost) {
        console.log('case 2');
        return (<PrejoinWithOnlyJoinButton onJoinMeet={onJoinMeet} uuid={uuid} needWaitHost={needWaitHost} />);
    }

    if (needPassword && password !== '' && !hasCorrectPassword) {
        console.log('case 3');
        return (<PrejoinWithPassword
            password={password}
            hasCorrectPassword={hasCorrectPassword}
            setHasCorrectPassword={setHasCorrectPassword}
        />);
    }
    if (needRequest) {
        console.log('case 4');
        return (<PrejoinWithRequest
            uuid={uuid}
            hostEmail={hostEmail}
            hostUUID={hostUUID}
            onJoinMeet={onJoinMeet}
            isHostJoin={isHostJoin}
            needWaitHost={needWaitHost}
            joinAutomated={joinAutomated}
            setJoinAutomated={setJoinAutomated}
            requestStatus={requestStatus}
            setRequestStatus={setRequestStatus}
            nextRequestInterval={nextRequestInterval}
            setNextRequestInterval={setNextRequestInterval}
            sendRequestTimesReste={sendRequestTimesReste}
            setSendRequestTimesReste={setSendRequestTimesReste}
   
        />);
    }
    

    if (needWaitHost && !isHostJoin) {
        console.log('case 5');
        return (<PrejoinWithWaitHost
            uuid={uuid}
            onJoinMeet={onJoinMeet}
            joinAutomated={joinAutomated}
            setJoinAutomated={setJoinAutomated}
        />);
    }

    if (!userName ) {
        console.log('case 6');
        return (<PrejoinWithIdentification onJoinMeet={onJoinMeet} />);
    }

    if (userName && isHostJoin &&
        (
            (!needPassword && !needRequest && !needWaitHost) || //has no other options
            ((needPassword && password !== '' && hasCorrectPassword)) || //has correct password
            (needRequest && requestStatus === RequestStatus.accepted) // has request accept
        )
    ) {
        console.log('case 7');
        return (<PrejoinWithOnlyJoinButton onJoinMeet={onJoinMeet} uuid={uuid} needWaitHost={needWaitHost} />);
    }

}

export default PrejoinContainer;