const checkHasLowConnection = () => {

    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    console.log('checkHasLowConnection', connection);
    if (connection && connection.downlink) {
        return connection.downlink  < 1;
    }
    return false;// temp keep false for firefox
}

export default checkHasLowConnection;