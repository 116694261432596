import React, { useEffect } from "react";
import BtnRegular from '../../components/BtnRegular';
import PrejoinWithUserNameInput from './withUserNameInput';
import useAuthContext from "../../hooks/useAuth";

const PrejoinWithIdentification = ({ setIsReadyJoin,onJoinMeet}) => {
    

    const { userName } = useAuthContext();
    useEffect(() => {
        if (userName) {
            setIsReadyJoin(true);
        }
    }, [userName]);

    return (
        
        <div className='notif-box'>
            <div className='notif-box__header'>
                <p className='legend'>Enter your name or sign in to join the meeting.</p>
            </div>

            <div className='notif-box__body'>
                <div className='form-frame'>
                    <form className='form'>
                        <div className='form__body'>
                            <PrejoinWithUserNameInput />
                        </div>
                        <div className='form__footer'>
                            <BtnRegular className={`btn-regular ${userName ? '':'not-ready'}`} content='Join Now' event={onJoinMeet}/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default PrejoinWithIdentification;