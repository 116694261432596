import React from 'react';

const BtnRegular = (props) => {
    return (
        <button className={props.className ? props.className : 'btn-regular'} onClick={props.event}>
            <span id={props.id}>{props.content}</span>
        </button>
    );
};

export default BtnRegular;