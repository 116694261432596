import { createBrowserRouter } from 'react-router-dom';
import Meet from '../pages/Meet';
import Home from '../pages/Home';
import ErrorPage from "../pages/ErrorPage";
import pageLoader from "./PageLoader";
import PreJoin from "../pages/PreJoin";
import DevRoom from "../pages/DevRoom";
import Preview from '../pages/Preview';

const router = createBrowserRouter([
	{
		path: '/',
		element: <DevRoom />,
		loader: pageLoader,
		errorElement: <ErrorPage />,
	},

	{
		path: '/home',
		element: <Home />,
		errorElement: <ErrorPage />,
	},
	// {
	// 	path: '/login',
	// 	element: <Home />,
	// 	errorElement: <ErrorPage />,
	// },
	{
		path: "/prejoin",
		element: <PreJoin />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/devroom",
		element: <DevRoom />,
		loader: pageLoader,
		errorElement: <ErrorPage />,
	},

	{
		path: '/:meetUUID',
		element: <Preview />,
		loader: pageLoader,
		errorElement: <ErrorPage />,
	},
]);

export default router;
