
import React, { useEffect, useState } from 'react';
import Input from './Input';
import BtnIcon from './BtnIcon';
import UserItem from './UserItem';
import useMediaQuery from '../hooks/useMediaQuery';

import CrossSvg from '../assets/icons/cross.svg';
import SearchSvg from '../assets/icons/search.svg';
import { generateRandomLetterAndColor } from '../utils/function';
import { localeCompare } from 'string';

const ContactsModal = (props) => {

	const [userList, setUserList] = useState([]);
	const [userListFilter, setUserListFilter] = useState(userList)
	const [isContacts, setIsContacts] = useState('Contacts')
	const [getRegion, setGetRegion] = useState('eu')
	const [limitOffset, setLimitOffset] = useState(0);
	const [selectedLetter, setSelectedLetter] = useState('a');

	const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

	const handleLetterClick = (letter) => {
		setSelectedLetter(letter.toLowerCase());

		let alphabetScrollDiv = document.querySelectorAll('.contacts-modal__list__search-letter span');
		alphabetScrollDiv.forEach((el) => {
			if (el.innerText === letter.toUpperCase()) {
				el.classList.add('active');
			} else {
				el.classList.remove('active');
			}
		});

		setUserList([]);
		setUserListFilter([]);
		setLimitOffset(0);
		fetchContacts(getRegion, letter.toLowerCase(), 0); // utiliser la nouvelle valeur de letter
	};

	const handleTeamClick = (tab) => {
		setIsContacts(tab);
		setUserList([]);
		setUserListFilter([]);

		console.log(tab);
		if (tab === 'Team') {
			fetchTeams(getRegion);
		} else if (tab === "Contacts") {
			fetchContacts(getRegion, selectedLetter, limitOffset);
		}
	};


	const handleActiveLetter = () => {
		let alphabetScrollDiv = document.querySelectorAll('.contacts-modal__list__search-letter span')
		alphabetScrollDiv[0].style.fontFamily = "Open Sans Bold";
		alphabetScrollDiv[0].style.color = "#fffe";
	}
	const updateUser = (userId) => {
		const updateUserLists = userListFilter.map((item) => {
			if (item.id === +userId) {
				item.isSelected = !item.isSelected;
			}
			return item;
		});
		setUserListFilter(updateUserLists);
	};

	const fetchContacts = (getRegion, selectedLetter, limitOffset) => {
		fetch(`https://api-${getRegion}.ringover.com/v3/contacts?alphabetical_order=LastnameFirstnameCompanyASC&pagination=${selectedLetter.toLowerCase()}&limit_count=100&limit_offset=${limitOffset}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + localStorage.getItem('id_token')
			},
		}).then((response) => {
			response.json().then((responseJs) => {
				if (responseJs.contact_list && typeof responseJs.contact_list !== 'undefined') {
					const contacts = responseJs.contact_list.map((item) => {
						const container = {};
						container.id = item.contact_id || null;
						container.picture = item.profile_picture ?? null;
						container.givenName = item.lastname || '';
						container.familyName = item.firstname || '';
						container.initial = item.initial || '';
						container.color = generateRandomLetterAndColor();
						container.type = "contact";
						container.isSelected = false;
						return container;
					});

					if (limitOffset === 0) {
						setUserList(contacts);
					} else {
						setUserList((prevState) => {
							return [...prevState, ...contacts];
						});
					}
					setUserListFilter((prevState) => {
						return [...prevState, ...contacts];
					});
					// console.log(userList)
				}
			});
		}).catch((error) => console.error(error));
	}

	const fetchTeams = (getRegion) => {
		fetch(`https://api-${getRegion}.ringover.com/v2/team/team_members`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + localStorage.getItem('id_token')
			},
		}).then((response) => {
			response.json().then((responseJs) => {
				if (responseJs.team_members && typeof responseJs.team_members !== 'undefined') {
					const contacts = responseJs.team_members.map((item) => {
						const container = {};
						container.id = item.id ?? null;
						container.picture = item.profile_picture ?? null;
						container.givenName = item.name?.given_name ?? '';
						container.familyName = item.name?.family_name ?? '';
						const nameParts = item.name?.formatted?.split(' ') ?? [];
						container.initial = nameParts.length >= 2 ? nameParts[0].charAt(0) + nameParts[1].charAt(0) : '';
						container.color = generateRandomLetterAndColor();
						container.isSelected = false;
						container.type = "team";
						if (container.picture) {
							fetchAvatar(container.picture).then(blob => {
								container.picture = URL.createObjectURL(blob);
								setUserList((prevState) => {
									const index = prevState.findIndex((prevItem) => prevItem.id === container.id);
									if (index >= 0) {
										prevState[index].picture = container.picture;
									}
									return prevState;
								});
							});
						}
						return container;
					});

					contacts.sort((a, b) => a.givenName.localeCompare(b.givenName));


					if (limitOffset === 0) {
						setUserList(contacts);
					} else {
						setUserList((prevState) => {
							return [...prevState, ...contacts];
						});
					}
					setUserListFilter((prevState) => {
						return [...prevState, ...contacts];
					});
					// console.log(userList)
				}
			});
		}).catch((error) => console.error(error));
	}

	const imageCache = new Map();

	const fetchAvatar = async (avatarUrl) => {
		// if (imageCache.has(avatarUrl)) {
		// 	const cachedBlob = imageCache.get(avatarUrl);
		// 	return Promise.resolve(URL.createObjectURL(cachedBlob));
		// }
		return fetch(avatarUrl, {
			method: 'get',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + localStorage.getItem("id_token")
			},
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Erreur lors de la récupération de l\'image');
				}
				return response.blob();
			})
			.then(blob => {
				imageCache.set(avatarUrl, blob);
				return blob;
			})
			.catch(error => console.log(error));
	};

	const handleScroll = (e) => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			setLimitOffset((prevLimitOffset) => prevLimitOffset + 30);
			console.log('End of list reached!');
			fetchContacts(getRegion, selectedLetter, limitOffset + 30);
		}
	};

	useEffect(() => {
		handleActiveLetter();
		setGetRegion(localStorage.getItem('region'));
		fetchContacts(getRegion, selectedLetter, limitOffset);
	}, [])


	
	const handleAddToParticipantsList = () => {
		const filteredItems = userListFilter.filter(item => item.isSelected === true);
		props.addParticipants(filteredItems);
		props.closeModal();
	};

	const searchUser = (e) => {
		const string = e.target.value.toLowerCase();
		const filterUserList = userList.filter(
			(item) => {
				const hasFamilly = item.familyName.toLowerCase().includes(string)
				const hasGiven = item.givenName.toLowerCase().includes(string)
				return hasFamilly || hasGiven
			}
		);

		setUserListFilter([...filterUserList]);
	}

	const isSmallScreen = useMediaQuery('(max-width: 800px)');

	return (
		<div className='contacts-modal large-modal'>
			<div className='contacts-modal__container large-modal__container'>
				<div className='contacts-modal__heading'>
					<h3 id='text_ringover_contacts'>Ringover Contacts</h3>
					<div className='exit-frame' data-size="medium" data-interactive={true}>
						<img
							src={CrossSvg}
							alt='close'
							onClick={props.closeModal}
						/>
					</div>
				</div>


				<div className='contacts-modal__button-frame-top'>
					<BtnIcon
						customClassName={isContacts === 'Contacts' ? 'is-selected' : ''}
						alt='contact'
						content='Contact'
						id='text_contact'
						event={() => handleTeamClick('Contacts')}
					/>
					<BtnIcon
						customClassName={isContacts === 'Team' ? 'is-selected' : ''}
						alt='team'
						content='Team'
						id='text_team'
						event={() => handleTeamClick('Team')}
					/>
				</div>
				<Input
					className='input-frame input-searchbar'
					icon={SearchSvg}
					id='text_search_a_name'
					placeholder={'Search a name'}
					onValueChanged={searchUser}
				/>
				<div className='contacts-modal__list'>
					<div className='contacts-modal__list__search-letter scroll-bar'>
						{alphabet.map((item, i) => {
							return (
								<span className={selectedLetter === item ? 'active' : ''}
									key={i} style={{ cursor: 'pointer' }} onClick={() => handleLetterClick(item)}>
									{item}
								</span>
							);
						})}
					</div>
					<div className='contacts-modal__list__sources'  >
						<p className='contacts-modal__list__sources__header' id='text_contact'>Contact</p>

						<div className='contacts-modal__list__sources__list scroll-bar' onScroll={handleScroll}>
							{isSmallScreen &&
								userListFilter.map((item, i) => (
									<UserItem
										key={i}
										id={item.id}
										picture={item.picture}
										familyName={item.familyName}
										givenName={item.givenName}
										isToAdd={!item.isSelected}
										type={item.type}
										color={item.color}
										initial={item.initial}
										onClick={() => updateUser(item.id)}
									/>
								))
							}
							{!isSmallScreen &&
								userListFilter.map((item, i) => (
									<UserItem
										key={i}
										id={item.id}
										picture={item.picture}
										familyName={item.familyName}
										givenName={item.givenName}
										isToAdd={true}
										type={item.type}
										color={item.color}
										initial={item.initial}
										onClick={() => updateUser(item.id)}
									/>
								))}
						</div>
					</div>
					<div className='contacts-modal__list__destination'>
						<p
							className='contacts-modal__list__destination__header'
							id='text_added'>
							Added
						</p>
						<div className='contacts-modal__list__destination__list scroll-bar'>
							{!isSmallScreen && userListFilter.filter((item) => item.isSelected)
								.map((item, i) => (
									<UserItem
										key={i}
										id={item.id}
										picture={item.picture}
										familyName={item.familyName}
										givenName={item.givenName}
										isToAdd={false}
										type={item.type}
										color={item.color}
										initial={item.initial}
										onClick={() => updateUser(item.id)}
									/>
								))}
						</div>
					</div>
				</div>
				<div className='contacts-modal__button-frame-bottom'>
					{/* <div onClick={props.closeModal}> */}
					<button className='btn-icon background_btn' id="text_cancel" onClick={props.closeModal}>Cancel</button>
					<BtnIcon
						alt=''
						customClassName='background_btn'
						content='Add to the participant list'
						id='text_add_participant_list'
						event={() => handleAddToParticipantsList()}
					/>
					{/* <BtnIcon
						alt=''
						customClassName='background_btn'
						content='Send email invitations'
						id='text_send_email_invitations'
						event={() => console.log('button Send email invitations pressed')}
					/> */}
					{/* {userListFilter.filter((item) => item.isSelected).length === 0 && (
						<BtnIcon
							alt=''
							customClassName='background_btn'
							content='Add to the participant list'
							id='text_add_participant_list'
							event={() =>
								alert('canceled')
								// console.log('button Add to the participant list pressed');
							}
						/>
					)}
					{userListFilter.filter((item) => item.isSelected).length !== 0 && (
						<BtnIcon
							alt=''
							customClassName='background_btn'
							content='Send email invitations'
							id='text_send_email_invitations'
							event={() => console.log('button Send email invitations pressed')}
						/>
					)} */}
				</div>
			</div>
		</div>
	);
}

export default ContactsModal