import React from 'react';

const ProgressBar = ({value,max}) => {
    return (
        <div className='progress-upload-bar'>
            <progress className='progress-upload-bar__bar' value={value} max={max}/>
            <span className="progress-upload-bar__value">{value} %</span>
        </div>
    );
};

export default ProgressBar;