import React, { useEffect, useState,useRef } from "react";
import BtnRegular from '../../components/BtnRegular';
import InputCheckbox from '../../components/InputCheckbox';
import PrejoinWithUserNameInput from './withUserNameInput';
import useAuthContext from "../../hooks/useAuth";
import LogotypeSvg from '../../assets/icons/logotype-prejoin.svg';
import { RequestStatus } from "../../pages/Preview";

const PrejoinWithRequest = (props) => {
    
    const { uuid, hostEmail, hostUUID, needWaitHost, requestStatus, setRequestStatus, onJoinMeet, setJoinAutomated, joinAutomated, isHostJoin, nextRequestInterval, sendRequestTimesReste} = props;
    
    const { socket, userName } = useAuthContext();

    const [message, setMessage] = useState('');
    const [notification, setNotification] = useState('');

    const interval = useRef(nextRequestInterval);
    const timer = useRef(null);

    const [time, setTime] = useState('');

    const handleSendRequest = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (socket) {
            socket.open();
            socket.emit('send request', { meetUUID: uuid, hostEmail, hostUUID, userName });
        }
        setRequestStatus(RequestStatus.waiting);
    }
    
    const handleChange = () => {
        setJoinAutomated(!joinAutomated);
    }
  
    useEffect(() => {
        if (socket) {
            socket.open();
            socket.emit('prejoin', { uuid });
        }
    }, [socket]);

    
    useEffect(() => {
        switch (requestStatus) {
            case RequestStatus.init:
                if (userName) {
                    setNotification('You must knock to enter and wait until the admin lets you in.');
                } else {
                    setNotification('Enter your name or sign in to your Ringover account to knock to enter.');
                }
                break;
            case RequestStatus.waiting:
                setMessage('Wait until the administrator lets you in');
                setNotification('Your request has been sent. We will let you know when the administrator has accepted your request.');
                break;
            case RequestStatus.accepted:
                setMessage('Your request has been accepted!');
                setNotification('Click the button below to join the meeting.');
                break;
            case RequestStatus.reject:
                setMessage('Your request to join the meeting has been denied.');
                if (interval.current === 0 && sendRequestTimesReste > 0) {
                    setNotification(`You have ${sendRequestTimesReste === 2 ? "two" : "one" } requests remaining.`)
                } else {
                    setNotification(`You can send another request in ${time}.`);
                }
                
        }
    },[requestStatus, userName, time])

    useEffect(() => {
        console.log('nextRequestInterval', nextRequestInterval)
        interval.current = nextRequestInterval;
        dealData();
    }, [nextRequestInterval]);

    useEffect(() => {
        console.log("sendRequestTimesReste",sendRequestTimesReste)
    },[sendRequestTimesReste])
    
    
    const dealData = () => {

        if (interval.current <= 0) {
            setTime('');
            return timer.current && clearTimeout(timer.current);
        }
        // const d = parseInt(interval.current / (24 * 60 * 60) + '');
        // const h = parseInt(((interval.current / (60 * 60)) % 24) + '');
        const m = parseInt(((interval.current / 60) % 60) + '');
        const s = parseInt((interval.current % 60) + '');

        if (m === 0) {
            setTime(`${s} seconds`);
        } else {
            setTime(`${m} minute${m>1?'s':''} ${s} seconds`);
        }
        
        interval.current--;
        timer.current = setTimeout(() => {
            dealData();
        }, 1000);
    };

    useEffect(() => {
        dealData();
        return () => {
            timer.current && clearTimeout(timer.current);
        }
    }, []);
    
    return (
        <>
            {
                (requestStatus === RequestStatus.waiting) &&
                    <div className='logo-box'>
                        <img src={LogotypeSvg} alt="logotype prejoin"/>
                </div>
            }
            <div className='notif-box'>
                <div className='notif-box__header'>
                    <p>{message}</p>
                    <p className='legend'>{notification}</p>
                </div>

                <div className='notif-box__body'>
                    <div className='form-frame'>
                        <form className='form'>
                            
                            {!userName &&
                                <div className='form__body'>
                                    <PrejoinWithUserNameInput />
                                </div>}
                            {!isHostJoin && requestStatus === RequestStatus.accepted && needWaitHost &&
                                <div className='form__header'>
                                    <InputCheckbox content={'Join automatically once the host arrives.'} htmlFor='join-auto' name="join-auto" id="join-auto" handleChange={handleChange}/>
                                </div>}
                            <div className='form__footer'>
                                {requestStatus === RequestStatus.init && <BtnRegular className={`btn-regular ${userName ? '' : 'not-ready'}`} content='Knock to enter' event={handleSendRequest} />}
                                {requestStatus === RequestStatus.reject && <BtnRegular className={`btn-regular ${interval.current === 0 ? '' : 'not-ready'}`} content='Send another request' event={handleSendRequest} />}
                                {requestStatus === RequestStatus.accepted && <BtnRegular className={`btn-regular ${(!needWaitHost || (needWaitHost && isHostJoin)) ? '' : 'not-ready'}`} content='Join now' event={onJoinMeet} />}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
export default PrejoinWithRequest;