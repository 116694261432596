import React from 'react';


const BtnIcon = (props) => {
	const newClassName = props.customClassName ? `btn-icon__${props.customClassName}` : ''
	return (
		<button
			className={`btn-icon ${newClassName}`}
			data-size={props.size}
			data-interactive={props.interactive}
			onClick={props.event}>
			{props.icon && (
				<img
					src={props.icon}
					alt={props.alt}
				/>
			)}
			<span id={props.id}>{props.content}</span>
		</button>
	);
};

export default BtnIcon;