import React from 'react';

const Emoji = ({ symbol, label, onClick }) => (
    <span
      className="emoji"
      role="img"
      aria-label={label ? label : ""}
      aria-hidden={label ? "false" : "true"}
      onClick={onClick}
    >
      {symbol}
    </span>
);

export default Emoji;