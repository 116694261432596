import React from 'react';

const Tooltip = (props) => {
    return (
        <span className='tooltip' style={{top: props.topOffset, left: props.leftOffset}}>
            {props.content}
        </span>
    );
};

export default Tooltip;

