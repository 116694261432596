import CrossSvg from '../assets/icons/cross.svg';
import CheckSvg from '../assets/icons/check.svg';
import React from 'react';
import BtnRegular from './BtnRegular';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	getMeetUUID,
	getMeetName,
	setMeetName,
	setMeetUUID,
} from '../redux/meetSlice';
import * as utils from '../utils';

const MeetCreatedModal = () => {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const meetUUID = useSelector(getMeetUUID);

	const handleClick = (event) => {
		// event.preventDefault();
		event.stopPropagation();
		let modal = document.querySelector('.meet-created-modal');
		modal.classList.remove('show');
		// alert('evenement meet created ')
	};

	const handleStartMeet = (event) => {
		event.preventDefault();
		event.stopPropagation();

		navigate(`/${meetUUID}`);
	};
	return (
		<div className='large-modal meet-created-modal'>
			<div className='meet-created-modal__container large-modal__container'>
				<div className='meet-created-modal__heading'>
					<div className='heading-frame'>
						<img
							src={CheckSvg}
							alt='check'
						/>
						<h3 id='text_your_meet_has_been_created'>
							Your meet has been created
						</h3>
					</div>
					<div
						className='exit-frame'
						data-size='medium'
						data-interactive={true}>
						<img
							src={CrossSvg}
							alt='close'
							onClick={handleClick}
						/>
					</div>
				</div>
				<div className='meet-created-modal__content'>
					<p id='text_you_can_find_and_modify'>
						You can find and modify it anytime by searching through the calendar
						or the search bar.
					</p>
				</div>
				<div className='meet-created-modal__footer'>
					<div className='btn-frame btn-duo-frame'>
						<BtnRegular
							content='Close'
							id='text_close'
							event={handleClick}
						/>
						<BtnRegular
							content='Access to the meet now'
							event={handleStartMeet}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MeetCreatedModal;
