import { configureStore } from '@reduxjs/toolkit'
import { userReducer } from './userSlice';
import { meetReducer } from './meetSlice';
import { participantReducer } from './participantSlice';

const store = configureStore({
	reducer: {
		user: userReducer,
		meet: meetReducer,
		participant: participantReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	devTools: true,
});
export default store