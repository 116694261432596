import React,{useState, useEffect} from "react";
import LogotypeSvg from '../../assets/icons/logotype-prejoin.svg';
import PrejoinWithUserNameInput from "./withUserNameInput";
import InputCheckbox from '../InputCheckbox';
import BtnRegular from '../../components/BtnRegular';
import useAuthContext from "../../hooks/useAuth";

const PrejoinWithWaitHost = ({ uuid, isHostJoin, joinAutomated, setJoinAutomated, onJoinMeet}) => {
     

    const { socket, userName } = useAuthContext();
    const [notification, setNotification] = useState('');
    useEffect(() => {
        if (userName) {
            setNotification('The meeting will start once the host joins the meeting.');
        } else {
            setNotification('Enter your name or sign in. The meeting will start once the administrator joins the meeting.');
        }
        
    }, [userName])
    
    const handleChange = () => {
        setJoinAutomated(!joinAutomated);
    }

  
    useEffect(() => {
        if (socket) {
            socket.open();
            socket.emit('prejoin', { uuid });
        }
    }, []);

    return (
        
        <div className='notif-box'>
            <div className='notif-box__header'>
                <div className='logo-box'>
                    <img src={LogotypeSvg} alt="logotype prejoin"/>
                </div>
            
                <p className='legend'>{notification}</p>
            </div>
            
            
            <div className='notif-box__body'>
                <div className='form-frame'> 
                    <form className='form'>
                        
                        <div className='form__header'>
                            <InputCheckbox content={'Join automatically once the host arrives.'} htmlFor='join-auto' name="join-auto" id="join-auto"
                            handleChange={handleChange}/>
                        </div>
                        {!userName &&
                            <div className='form__body'>
                                <PrejoinWithUserNameInput />
                            </div>
                        }
                        <div className='form__footer'>
                             <BtnRegular className={`btn-regular ${isHostJoin ? '' : 'not-ready'}`} content='Join Now' event={onJoinMeet} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default PrejoinWithWaitHost;