
import React,{useState} from "react";

import Input from '../../components/Input';
import KeyboardSvg from '../../assets/icons/keyboard.svg';
import useAuthContext from "../../hooks/useAuth";

const PrejoinWithUserNameInput = () => {
    
    const { setUserName } = useAuthContext();
    const [name, setName] = useState('');
    const handleValueChange = (e) => {
        setName(e.target.value);
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
		    e.stopPropagation();
            setUserName(name);
        }
    }

    return (
        <Input
            className='input-frame'
            icon={KeyboardSvg}
            id='text_user_name'
            placeholder='Type a name'
            onKeyDown={handleKeyDown}
            onChange={handleValueChange}
        />
    );
}
export default PrejoinWithUserNameInput;