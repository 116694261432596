import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import AuthContext from './AuthContext';
import useLocalStorage from '../hooks/useLocalStorage';
import useSessionStorage from '../hooks/useSessionStorage';
import * as utils from '../utils';

const endpoints = 'https://auth.ringover.com/oauth2/authorize';
const access_token_url = 'https://auth.ringover.com/oauth2/access_token';
const introspect_url = 'https://auth.ringover.com/oauth2/introspect';
const logout_url = 'https://auth.ringover.com/oauth2/logout';
let tokenTimeout = null;
var apiV2Url = '';
let accessTokenPromise = Promise.resolve();
let user_region = '';

const AuthContextProvider = ({ children }) => {
	const [idToken, setIdToken] = useState('');
	const [accessToken, setAccessToken] = useState('');
	const [refreshToken, setRefreshToken] = useState('');
	const [userAvatarPath, setUserAvatarPath] = useState('');
	const [userLang, setUserLang] = useState('');
	const [hasAllTokens, setHasAllTokens] = useState(false);
	const [meetNameAuth, setMeetNameAuth] = useLocalStorage('meet_name_auth', '');
	const [userEmail, setUserEmail] = useState('');
	const [userName, setUserName] = useState('');
	const [jobTitle, setJobTitle] = useState('');
	const [session, setSession] = useSessionStorage('session', '');
	const [userUUID, setUserUUID] = useLocalStorage('user_uuid', '');
	const [isDevMode,setIsDevMode] = useState(true);


	var socket = io.connect('http://localhost:4001/', {
		autoConnect: false,
		transports: ['websocket'],
		query: { session, userUUID, userEmail, userName },
	});

	useEffect(() => {
		if (!session) {
			setSession(`session-id-${utils.generateUserUUID()}`);
		}
		if (!userUUID) {
			setUserUUID(`user-id-${utils.generateUserUUID()}`);
		}
	}, []);

	function get_tokens() {
		try {
			return {
				access_token: localStorage.getItem('access_token'),
				refresh_token: localStorage.getItem('refresh_token'),
				id_token: localStorage.getItem('id_token'),
			};
		} catch (error) {
			console.log("Couldn't load data", error);
			return false;
		}
	}

	function save_tokens(res) {
		let formData = new FormData();
		formData.append('token', res.id_token);

		fetch(introspect_url, {
			method: 'post',
			body: formData,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				user_region = responseJson.region.toLowerCase();
				//introspect
				apiV2Url = 'https://api-' + user_region + '.ringover.com/v2/get/user';
				window.localStorage.setItem('access_token', res.access_token);
				window.localStorage.setItem('refresh_token', res.refresh_token);
				window.localStorage.setItem('id_token', res.id_token);
				tokenTimeout = setTimeout(
					() => refresh_oauth_tokens(res),
					res.expires_in * 1000 - 5000
				);

				window.localStorage.setItem('region', user_region);
				window.localStorage.setItem('user_id', responseJson.user_id);

				setIdToken(res.id_token);
				setAccessToken(res.access_token);
				setRefreshToken(res.refresh_token);

				let idToken = localStorage.getItem('id_token');
				fetch(apiV2Url, {
					method: 'get',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + idToken,
					},
				})
					.then((response) => response.json())
					.then((responseJson) => {
						const lang = responseJson.settings.lang;
						if (lang != null) {
							setUserLang(lang);
						}
						setJobTitle(responseJson.jobtitle);
						setUserName(`${responseJson.firstname} ${responseJson.lastname}`);
						if (responseJson.email != null) {
							setUserEmail(responseJson.email);
						}
						if (responseJson.photopath != null) {
							setUserAvatarPath(responseJson.photopath);
						} else {
						}
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => console.log(error));
	}

	function get_oauth2_tokens(code) {
		const verifier_code = window.localStorage.getItem('PCKE');

		const params = {
			grant_type: 'authorization_code',
			redirect_uri: window.location.origin + '/login',
			code: code,
			code_verifier: verifier_code,
		};

		return call_oauth2_API(params)
			.then((res) => {
				if (res.error) {
					// return oauth_auth();
				} else {
					try {
						localStorage.removeItem('PCKE');
						save_tokens(res);
					} catch (error) {
						console.log(error);
					}
				}
			})
			.catch((error) => console.log(error));
	}

	function oauth_auth() {
		const generate_code_challenge = () => {
			const sequence_verifier = new Uint8Array(32);
			for (let i = 0; i < 32; i++) {
				sequence_verifier[i] = Math.floor(Math.random() * 255);
			}
			let binary_verifier = '';
			for (let unit8 of sequence_verifier) {
				binary_verifier += String.fromCharCode(unit8);
			}
			const code_verifier = btoa(binary_verifier)
				.replace(/=/g, '')
				.replace(/\+/g, '-')
				.replace(/\//g, '_');
			window.localStorage.setItem('PCKE', code_verifier);
			return window.crypto.subtle
				.digest('SHA-256', new TextEncoder().encode(code_verifier))
				.then((buffer) => {
					const sequence_challenge = new Uint8Array(buffer);
					let binary_challenge = '';
					for (let unit8 of sequence_challenge) {
						binary_challenge += String.fromCharCode(unit8);
					}
					let code_challenge = btoa(binary_challenge)
						.replace(/=/g, '')
						.replace(/\+/g, '-')
						.replace(/\//g, '_');
					return code_challenge;
				});
		};

		return generate_code_challenge()
			.then((code_challenge) => {
				const params = {
					client_id: 'meet_v3_app',
					scope: 'API_V2_ALL',
					response_type: 'code',
					redirect_uri: window.location.origin + '/login',
					code_challenge_method: 'S256',
					code_challenge: code_challenge,
				};

				let url = endpoints + '?';

				for (let param in params) {
					url += param + '=' + params[param] + '&';
				}

				window.location.href = encodeURI(url);
			})
			.catch((e) => console.error(e));
	}

	function refresh_oauth_tokens(tokens) {
		return accessTokenPromise.then(() => {
			let tokens = get_tokens();

			let params = {
				grant_type: 'refresh_token',
				redirect_uri: window.location.origin + '/login',
				access_token: tokens.access_token,
				refresh_token: tokens.refresh_token,
				id_token: tokens.id_token,
			};

			let logout_oauth2 = (_) => {
				remove_token();
				return oauth_auth();
			};

			accessTokenPromise = call_oauth2_API(params)
				.then((res) => {
					if (res.error) {
						logout_oauth2();
					} else {
						save_tokens(res);
						return res.access_token;
					}
				})
				.catch(() => {
					logout_oauth2();
				});
			return accessTokenPromise;
		});
	}

	function remove_token() {
		if (tokenTimeout) clearTimeout(tokenTimeout);
		window.localStorage.removeItem('user_token');
		window.localStorage.removeItem('access_token');
		window.localStorage.removeItem('refresh_token');
		window.localStorage.removeItem('id_token');
		window.localStorage.removeItem('region');
		window.localStorage.removeItem('meet_name_auth');
	}

	function call_oauth2_API(params) {
		let defaultParams = {
			client_id: 'meet_v3_app',
			scope: 'API_V2_ALL',
		};

		params = Object.assign(defaultParams, params);

		let formData = new FormData();
		for (let key in params) {
			formData.append(key, params[key]);
		}
		return fetch(access_token_url, {
			method: 'post',
			body: formData,
		})
			.then((res) => {
				if (res.ok == true) {
					return res.json();
				} else {
					return Promise.reject();
				}
			})
			.catch((error) => console.log(error));
	}

	useEffect(() => {
		let checkaction = localStorage.getItem('refresh');
		if (checkaction) {
			if (checkaction == 2) {
				get_oauth2_tokens(localStorage.getItem('code_auth'))
					.then((res) => {
						localStorage.removeItem('code_auth');
						localStorage.removeItem('PCKE');
						localStorage.removeItem('refresh');
					})
					.catch((error) => console.log(error));
			}
		} else {
			const tokens = get_tokens();
			if (tokens && tokens.refresh_token && tokens.id_token) {
				refresh_oauth_tokens().then((_) => {
					console.log('refresh is ok');
				});
			}
		}
	}, []);

	useEffect(() => {
		var language =
			window.navigator.userLanguage ||
			window.navigator.language ||
			window.navigator.browserLanguage;
		console.log(
			language,
			'userLanguage',
			window.navigator.userLanguage,
			'language',
			window.navigator.language,
			'browserLanguage',
			window.navigator.browserLanguage,
			'userAgent',
			window.navigator.userAgent
		);
		const isUserAuth = idToken && accessToken && refreshToken;
		if (!isUserAuth && !userLang) {
			setUserLang(language.substring(0, 2));
		}
	}, []);

	useEffect(() => {
		setHasAllTokens(!!idToken && !!accessToken && !!refreshToken);
		if (hasAllTokens) {
			setMeetNameAuth(localStorage.getItem('meet_name_auth'));
		}
	}, [idToken, accessToken, refreshToken]);

	useEffect(() => {
		console.log('hasAllTokens context', hasAllTokens);
	}, [hasAllTokens]);

	return (
		<AuthContext.Provider
			value={{
				idToken,
				accessToken,
				refreshToken,
				userLang,
				userAvatarPath,
				hasAllTokens,
				meetNameAuth,
				setMeetNameAuth,
				userEmail,
				setUserEmail,
				userName,
				setUserName,
				socket,
				userUUID,
				jobTitle,
				setJobTitle,
				isDevMode,
				setIsDevMode
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContextProvider;
